<script>
  export let socket_query;
  export let embed_dtc_worker;
  export let dtc_db;
  import { onMount } from "svelte";
  import { map_ready, units_list, nicks_list } from "./stores.js";
  import { dtc_map } from "./dtc_map.js";

  let key,
    nicks_,
    nicks = {};
  let parametros = new URLSearchParams(window.location.search);
  key = parametros.get("key");
  nicks_ = parametros.get("nicks");

  if (key != null) {
    embed_dtc_worker.postMessage({
      type: "save",
      key: "embedded_key",
      message: key,
    });
    /*
        socket_query.emit('embedded_key',key,(data)=> {
            if(data=="error"){
                alert("Login incorrecto");
            }
        });
        */
  }
  if (nicks_ != null) {
    nicks = JSON.parse(nicks_);
  }

  let units_list_p;
  $: {
    units_list_p = $units_list;
    nicks;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    let nicks_b = typeof nicks[b] == "undefined" ? b : nicks[b];
    let nicks_a = typeof nicks[a] == "undefined" ? a : nicks[a];
    if (nicks_b < nicks_a) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }

  let dtc = [];
  let selected = -1;
  async function maping(unit_s) {
    selected = unit_s;
    let dtc_;
    try {
      dtc_ = JSON.parse(await dtc_db.getItem(unit_s));
    } catch (e) {
      console.log("Error leyendo dtc", e);
    }
    dtc = [];
    if (dtc_ != null) {
      let code,
        bus,
        fmi,
        fmi_,
        data,
        oc,
        first,
        last,
        first_,
        last_,
        sa = "-";
      for (let x in dtc_.j1708.sid) {
        code =
          "MID-" +
          dtc_.j1708.sid[x].source_addr +
          " SID-" +
          dtc_.j1708.sid[x].sid;
        bus = "j1708";
        fmi =
          dtc_.j1708.sid[x].fmi +
          " - " +
          dtc_map.j1708.fmi[dtc_.j1708.sid[x].fmi];
        fmi_ = dtc_.j1708.sid[x].fmi;
        oc = dtc_.j1708.sid[x].oc;
        first = moment(new Date(dtc_.j1708.sid[x].first * 60 * 1000)).format(
          "DD/MM/YY HH:mm"
        );
        last = moment(new Date(dtc_.j1708.sid[x].last * 60 * 1000)).format(
          "DD/MM/YY HH:mm"
        );
        first_ = dtc_.j1708.sid[x].first * 60;
        last_ = dtc_.j1708.sid[x].last * 60;
        data = "-";
        sa =
          typeof dtc_map.j1708.mid[dtc_.j1708.sid[x].source_addr] != "undefined"
            ? dtc_map.j1708.mid[dtc_.j1708.sid[x].source_addr]
            : "";
        if (dtc_.j1708.sid[x].sid <= 150) {
          if (
            typeof dtc_map.j1708["mid-spn"][dtc_.j1708.sid[x].source_addr] !=
            "undefined"
          ) {
            data =
              dtc_map.j1708[
                dtc_map.j1708["mid-spn"][dtc_.j1708.sid[x].source_addr]
              ][dtc_.j1708.sid[x].sid];
          }
        } else {
          data = dtc_map.j1708["sid0"][dtc_.j1708.sid[x].sid];
        }
        dtc.push({
          code: code,
          bus: bus,
          fmi: fmi,
          data: data,
          oc: oc,
          first: first,
          last: last,
          first_: first_,
          last_: last_,
          fmi_: fmi_,
          sa: sa,
        });
      }
      for (let x in dtc_.j1708.pid) {
        if (dtc_.j1708.pid[x].code_type == 0)
          code = "PID-" + parseInt(dtc_.j1708.pid[x].pid + 255);
        else code = "PID-" + dtc_.j1708.pid[x].pid;
        bus = "j1708";
        fmi =
          dtc_.j1708.pid[x].fmi +
          " - " +
          dtc_map.j1708.fmi[dtc_.j1708.pid[x].fmi];
        fmi_ = dtc_.j1708.pid[x].fmi;
        oc = dtc_.j1708.pid[x].oc;
        first = moment(new Date(dtc_.j1708.pid[x].first * 60 * 1000)).format(
          "DD/MM/YY HH:mm"
        );
        last = moment(new Date(dtc_.j1708.pid[x].last * 60 * 1000)).format(
          "DD/MM/YY HH:mm"
        );
        first_ = dtc_.j1708.pid[x].first * 60;
        last_ = dtc_.j1708.pid[x].last * 60;
        sa =
          typeof dtc_map.j1708.mid[dtc_.j1708.pid[x].source_addr] != "undefined"
            ? dtc_map.j1708.mid[dtc_.j1708.pid[x].source_addr]
            : "";
        data =
          typeof dtc_map.j1708["pid" + dtc_.j1708.pid[x].code_type][
            dtc_.j1708.pid[x].pid
          ] != "undefined"
            ? dtc_map.j1708["pid" + dtc_.j1708.pid[x].code_type][
                dtc_.j1708.pid[x].pid
              ]
            : "-";
        dtc.push({
          code: code,
          bus: bus,
          fmi: fmi,
          data: data,
          oc: oc,
          first: first,
          last: last,
          first_: first_,
          last_: last_,
          fmi_: fmi_,
          sa: sa,
        });
      }
      for (let x in dtc_.j1939) {
        code = "SPN-" + dtc_.j1939[x].spn;
        bus = "j1939";
        fmi = dtc_.j1939[x].fmi + " - " + dtc_map.j1939.fmi[dtc_.j1939[x].fmi];
        fmi_ = dtc_.j1939[x].fmi;
        oc = dtc_.j1939[x].oc;
        first = moment(new Date(dtc_.j1939[x].first * 60 * 1000)).format(
          "DD/MM/YY HH:mm"
        );
        last = moment(new Date(dtc_.j1939[x].last * 60 * 1000)).format(
          "DD/MM/YY HH:mm"
        );
        first_ = dtc_.j1939[x].first * 60;
        last_ = dtc_.j1939[x].last * 60;
        sa =
          typeof dtc_map.j1939.source[
            parseInt(dtc_.j1939[x].source_addr, 16)
          ] != "undefined"
            ? dtc_map.j1939.source[parseInt(dtc_.j1939[x].source_addr, 16)]
            : "";
        data =
          typeof dtc_map.j1939.spn[dtc_.j1939[x].spn] != "undefined"
            ? dtc_map.j1939.spn[dtc_.j1939[x].spn]
            : "-";
        dtc.push({
          code: code,
          bus: bus,
          fmi: fmi,
          data: data,
          oc: oc,
          first: first,
          last: last,
          first_: first_,
          last_: last_,
          fmi_: fmi_,
          sa: sa,
        });
      }
    }
    dtc.sort(dtc_order);
  }
  function dtc_order(a, b) {
    if (a.last_ < b.last_) {
      return 1;
    } else if (a.last_ > b.last_) {
      return -1;
    }

    let val1 =
      a.fmi_ == 0 || a.fmi_ == 1
        ? 3
        : a.fmi_ == 16 || a.fmi_ == 18
        ? 2
        : a.fmi_ == 15 || a.fmi_ == 17
        ? 1
        : 0;
    let val2 =
      b.fmi_ == 0 || b.fmi_ == 1
        ? 3
        : b.fmi_ == 16 || b.fmi_ == 18
        ? 2
        : b.fmi_ == 15 || b.fmi_ == 17
        ? 1
        : 0;

    if (val1 < val2) {
      return 1;
    } else if (val1 > val2) {
      return -1;
    }

    if (a.data != "-" && b.data == "-") {
      return -1;
    } else if (a.data == "-" && b.data != "-") {
      return 1;
    }
    return 0;
  }
  let f_j1939 = 1,
    f_j1708 = 1,
    f_recientes = 1,
    f_anteriores = 0,
    f_identificados = 1,
    f_propietarios = 0;
  function filtro(fi) {
    if (fi == 0) {
      f_j1939 = f_j1939 == 1 ? 0 : 1;
    }
    if (fi == 1) {
      f_j1708 = f_j1708 == 1 ? 0 : 1;
    }
    if (fi == 2) {
      f_recientes = f_recientes == 1 ? 0 : 1;
    }
    if (fi == 3) {
      f_anteriores = f_anteriores == 1 ? 0 : 1;
    }
    if (fi == 4) {
      f_identificados = f_identificados == 1 ? 0 : 1;
    }
    if (fi == 5) {
      f_propietarios = f_propietarios == 1 ? 0 : 1;
    }
  }
</script>

<main role="main" class="container-fluid h-100 w-100 pl-0 pr-0">
  <div
    style="position: fixed; bottom: 5px; right: 0; color: #95A5A6;z-index:20000;"
  >
    powered by VectorGPS®
  </div>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div
            class="card-header b-0"
            style="background-color:#e9e9e9;color:#000"
          >
            <i class="material-icons md-85">commute</i> Unidades
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each units_list_p as item, i}
                <a
                  id={i}
                  href="/{item}"
                  class:list-group-item-warning={selected == item}
                  class:active_={selected == item}
                  on:click|preventDefault={() => {
                    maping(item);
                  }}
                  class="list-group-item list-group-item-action"
                  >{typeof nicks[item] != "undefined" ? nicks[item] : item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="d-flex flex-column h-100">
        <div class="d-flex flex-row-reverse align-items-center my-1 ">
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => filtro(0)}
              type="button"
              class="btn {f_j1939 == 1 ? 'btn-info' : 'btn-outline-info'}"
              >J1939</button
            >
            <button
              on:click|preventDefault={() => filtro(1)}
              type="button"
              class="btn {f_j1708 == 1 ? 'btn-info' : 'btn-outline-info'}"
              >J1708</button
            >
          </div>
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => filtro(2)}
              type="button"
              class="btn {f_recientes == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Recientes</button
            >
            <button
              on:click|preventDefault={() => filtro(3)}
              type="button"
              class="btn {f_anteriores == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Anteriores</button
            >
          </div>
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => filtro(4)}
              type="button"
              class="btn {f_identificados == 1
                ? 'btn-info'
                : 'btn-outline-info'}">Identificados</button
            >
            <button
              on:click|preventDefault={() => filtro(5)}
              type="button"
              class="btn {f_propietarios == 1
                ? 'btn-info'
                : 'btn-outline-info'}">Propietarios</button
            >
          </div>
        </div>
        <div
          class="table-responsive flex-grow-1 overflow-auto w-100"
          style="font-size:0.82rem;background-color: rgba(255, 255, 255);"
        >
          <table class="table table-bordered table-hover table-sm w-100">
            <thead>
              <tr class="table-secondary">
                <th scope="col" class="text-center align-middle" />
                <th scope="col" class="text-center align-middle">Código</th>
                <th scope="col" class="text-center align-middle">Origen</th>
                <th scope="col" class="text-center align-middle">Bus</th>
                <th scope="col" class="text-center align-middle">FMI</th>
                <th scope="col" class="text-center align-middle">Descripción</th
                >
                <th scope="col" class="text-center align-middle">Ocurrencia</th>
                <th scope="col" class="text-center align-middle"
                  >Primera detección</th
                >
                <th scope="col" class="text-center align-middle"
                  >Ultima detección</th
                >
              </tr>
            </thead>
            <tbody>
              {#each dtc as item, i}
                {#if ((f_j1939 == 0 && item.bus != "j1939") || f_j1939 == 1) && ((f_j1708 == 0 && item.bus != "j1708") || f_j1708 == 1) && ((f_recientes == 0 && Math.round(new Date().getTime() / 1000) - item.last_ >= 60 * 60 * 24 * 1) || f_recientes == 1) && ((f_anteriores == 0 && Math.round(new Date().getTime() / 1000) - item.last_ < 60 * 60 * 24 * 1) || f_anteriores == 1) && ((f_identificados == 0 && item.data == "-") || f_identificados == 1) && ((f_propietarios == 0 && item.data != "-") || f_propietarios == 1)}
                  <tr>
                    <th scope="row" class="text-center align-middle"
                      ><i
                        tooltip={item.fmi_ == 0 || item.fmi_ == 1
                          ? "Alto"
                          : item.fmi_ == 16 || item.fmi_ == 18
                          ? "Medio"
                          : item.fmi_ == 15 || item.fmi_ == 17
                          ? "Bajo"
                          : "Bajo"}
                        class="material-icons md-85 {item.fmi_ == 0 ||
                        item.fmi_ == 1
                          ? 'red'
                          : item.fmi_ == 16 || item.fmi_ == 18
                          ? 'yei'
                          : item.fmi_ == 15 || item.fmi_ == 17
                          ? 'gray'
                          : 'bblue'}">lens</i
                      ></th
                    >
                    <td class="table-info">{item.code}</td>
                    <td>{item.sa}</td>
                    <td>{item.bus}</td>
                    <td>{item.fmi}</td>
                    <td>{item.data}</td>
                    <td>{item.oc}</td>
                    <td>{item.first}</td>
                    <td
                      class={Math.round(new Date().getTime() / 1000) -
                        item.last_ <
                      60 * 60 * 24 * 1
                        ? "table-success"
                        : "table-secondary"}>{item.last}</td
                    >
                  </tr>
                {/if}
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-b {
    border: 0px;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .badge.travel {
    font-size: 0.75rem;
    margin-bottom: 0.4em;
  }
  .btn {
    font-size: 0.75rem;
    padding: 0.1rem, 0.1rem;
    line-height: 0.5;
  }
  .form-control.mini {
    font-size: 0.75rem;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  .card-header:first-child {
    border-radius: 0;
  }
  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
