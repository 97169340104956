<script>
  import { table_pos, nicks_list } from "./stores.js";
  import { onMount, onDestroy } from "svelte";

  var chart1 = {
    type: "line",
    data: {
      labels: [],
      datasets: [
        {
          label: "km/h",
          data: [],
          backgroundColor: "rgba(255, 159, 64, 0.2)",
          borderColor: "rgb(255, 159, 64)",
        },
      ],
    },
    options: {
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Estatus de Velocidad",
      },
      maintainAspectRatio: false,
      tooltips: { mode: "" },
    },
  };

  var chart2 = {
    type: "bar",
    data: {
      labels: ["Parking", "Idle", "Moving"],
      datasets: [
        {
          label: "Estado",
          data: [0, 0, 0],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 205, 86, 0.2)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
          ],
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              offsetGridLines: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: { beginAtZero: true },
          },
        ],
      },
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Estatus de Movimiento",
      },
      maintainAspectRatio: false,
    },
  };

  var chart3 = {
    type: "pie",
    data: {
      datasets: [
        {
          data: [0, 100],
          backgroundColor: ["rgb(255, 205, 86, 0.5)", "rgb(54, 162, 235, 0.5)"],
        },
      ],
      labels: ["Encendidas", "Apagadas"],
    },
    options: {
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Apagadas vs Encendidas",
      },
      maintainAspectRatio: false,
    },
  };
  var chart_1,
    chart_2,
    chart_3,
    map = 0;
  onMount(() => {
    chart_1 = new Chart(
      document.getElementById("chart1").getContext("2d"),
      chart1
    );
    chart_2 = new Chart(
      document.getElementById("chart2").getContext("2d"),
      chart2
    );
    chart_3 = new Chart(
      document.getElementById("chart3").getContext("2d"),
      chart3
    );

    function initMap() {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 24.458489, lng: -102.217231 },
        zoom: 5,
      });
    }
    initMap();
    twttr.widgets.load();
    table_ = setInterval(table, 2000);
    table();
    lista_ = setInterval(lista, 2000);
    lista();
  });
  let table_;
  let lista_;
  onDestroy(() => {
    clearInterval(table_);
    clearInterval(lista_);
  });

  var on = 0,
    off = 0;
  var s0 = 0,
    s1 = 0,
    s2 = 0;
  var markers = [];
  var markerCluster;
  var marker_store = [];
  var table_pos_ = [];

  //lista_ = setInterval(lista,1000);lista();
  function lista() {
    table_pos_ = [...$table_pos];
  }
  function table() {
    var on_ = 0,
      off_ = 0;
    var s0_ = 0,
      s1_ = 0,
      s2_ = 0;
    let data = [];
    for (var c in $table_pos) {
      if ($table_pos[c].ingnition == 1) {
        on_++;
      } else {
        off_++;
      }

      if ($table_pos[c].status == 0) {
        s0_++;
      } else if ($table_pos[c].status == 1) {
        s1_++;
      } else {
        s2_++;
      }

      if (
        typeof $table_pos[c].last_comm !== "undefined" &&
        $table_pos[c].vel > 0
      ) {
        data.push($table_pos[c]);
      }

      if (map != 0) {
        let m_found = 0;
        for (var x in markers) {
          if (markers[x]["modid"] == $table_pos[c].modid) {
            m_found = 1;
            markers[x]["marker"].setPosition({
              lat: parseFloat($table_pos[c].lat),
              lng: parseFloat($table_pos[c].lon),
            });
            markers[x]["marker"].setTitle(
              $table_pos[c].modid + "\n" + "Vel:" + $table_pos[c].vel
            );
          }
        }
        if (!m_found) {
          var marker = new google.maps.Marker({
            position: {
              lat: parseFloat($table_pos[c].lat),
              lng: parseFloat($table_pos[c].lon),
            },
            map: map,
            title: $table_pos[c].modid + "\n" + "Vel:" + $table_pos[c].vel,
          });
          markers.push({ modid: $table_pos[c].modid, marker: marker });
          marker_store.push(marker);
          if (typeof markerCluster !== "undefined") {
            markerCluster.setMap(null);
          }
          markerCluster = new MarkerClusterer(map, marker_store, {
            imagePath: "img/m",
          });
        }
      }
    }
    if (typeof chart_1 != "undefined") {
      chart_1.data.datasets[0].data = [];
      chart_1.data.labels = [];
      let i = 0;
      data.sort(compare_vel);
      for (var c in data) {
        chart_1.data.labels.push(" "); //data[c].modid
        chart_1.data.datasets[0].data[i] = data[c].vel;
        i++;
      }
      chart_1.update();
    }
    if (typeof chart_2 != "undefined" && (s0_ != s0 || s1_ != s1)) {
      s0 = s0_;
      s1 = s1_;
      s2 = s2_;
      chart_2.data.datasets[0].data[0] = s0;
      chart_2.data.datasets[0].data[1] = s1;
      chart_2.data.datasets[0].data[2] = s2;
      chart_2.update();
    }
    if (typeof chart_3 != "undefined" && on_ != on) {
      on = on_;
      off = off_;
      chart_3.data.datasets[0].data[0] = on;
      chart_3.data.datasets[0].data[1] = off;
      chart_3.update();
    }
  }
  function compare_vel(a, b) {
    return b.vel - a.vel;
  }
  function compare_serial(a, b) {
    return b.modid - a.modid;
  }
</script>

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-50 no-gutters">
    <div class="col-md-3 h-100">
      <div class="card h-100">
        <div class="card-body h-100">
          <canvas id="chart2" />
        </div>
      </div>
    </div>
    <div class="col-md-3 h-100">
      <div class="card h-100">
        <div class="card-body h-100">
          <canvas id="chart3" />
        </div>
      </div>
    </div>
    <div class="col-md-6 h-100">
      <div class="card h-100 ">
        <div class="card-body h-100 mb-4">
          <div class="tableFixHead h-100">
            <table class="table table-striped table-bordered table-sm ">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Economico</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Vel</th>
                  <th scope="col">Ign</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">GPS</th>
                  <th scope="col">Cell</th>
                  <th scope="col">Latitud</th>
                  <th scope="col">Longitud</th>
                </tr>
              </thead>
              <tbody>
                {#each table_pos_ as item (item.modid)}
                  {#if typeof item.last_comm !== "undefined"}
                    <tr>
                      <th scope="row"
                        >{typeof $nicks_list[item.modid] != "undefined"
                          ? $nicks_list[item.modid]
                          : item.modid}</th
                      >
                      <td
                        >{item.status == 0
                          ? "Parking"
                          : item.status == 1
                          ? "Idle"
                          : "Moving"}</td
                      >
                      <td
                        class:table-success={item.vel <= 80 && item.vel != 0}
                        class:table-secondary={item.vel == 0}
                        class:bg-warning={item.vel > 80 && item.vel < 100}
                        class:bg-danger={item.vel >= 100}>{item.vel}</td
                      >
                      <td
                        class:table-success={item.ingnition == 1}
                        class:table-secondary={item.ingnition == 0}
                        >{item.ingnition == "1" ? "ON" : "OFF"}</td
                      >
                      <td
                        >{moment(new Date(item.last_comm * 1000)).format(
                          "DD/MM/YY HH:mm:ss"
                        )}</td
                      >
                      <td
                        class:bg-danger={item.hdop > 1.5}
                        class:table-success={item.hdop <= 1.5}>{item.hdop}</td
                      >
                      <td
                        class:bg-danger={item.rssi >= 100}
                        class:table-success={item.rssi <= 90}
                        class:bg-warning={item.rssi > 90 && item.rssi < 100}
                        >{item.rssi}</td
                      >
                      <td>{item.lat}</td>
                      <td>{item.lon}</td>
                    </tr>
                  {/if}
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100" />
    <div class="col-md-2 h-100">
      <a
        class="twitter-timeline"
        data-width="100%"
        data-height="100%"
        href="https://twitter.com/CAPUFE">Tweets by CAPUFE</a
      >
    </div>
    <div class="col-md-4 h-100">
      <div class="card h-100">
        <div class="card-body h-100">
          <canvas id="chart1" />
        </div>
      </div>
    </div>
    <div class="col-md-6 h-100">
      <div class="card h-100">
        <div class="card-body h-100">
          <div class=" h-100" id="map" />
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .tableFixHead {
    overflow-y: auto;
  }
  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }
  .card-body {
    padding: 0rem;
  }
</style>
