<script>
  export let socket_query;
  export let user_level;
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
    geos_class_list,
    route_list,
    route_list_d,
    travel_crtl,
    geos_joined,
    geos_class_join,
    internal_com,
    map_ready,
    monitor_extra_map,
    recognition,
    unit_groups,
    unit_config,
    unit_types,
    reports_cron,
  } from "./stores.js";
  import { onDestroy, onMount } from "svelte";

  let map, m_inicio;
  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center:
        last_post_big != null
          ? last_post_big
          : { lat: 24.458489, lng: -102.217231 },
      zoom: 16,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });

    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (last_post_big != null) {
      m_inicio = new google.maps.Marker({
        position: last_post_big,
        map: map,
        animation: google.maps.Animation.DROP,
        zIndex: 0,
      });
    } else {
      map.fitBounds(bounds);
    }
  }

  let units_list_p;
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  let selected = -1;
  let configuraciones = [
    "Grupos",
    "Unidades",
    "Reportes programados",
    "Formatos de reporte",
    "Alertas Complejas",
    "Usuarios",
    "Generales",
  ];
  function maping(i) {
    selected = i;
  }
  function reporte_csv() {
    let csv = "";
    var regex = /( |<([^>]+)>)/gi;
    regex = /<[^>]*>?/gm;
    regex = /(<([^>]+)>)/gi;

    var table = document.getElementById(selected == 7 ? "big_query_table" : "");
    var rowLength = table.rows.length;
    for (let i = 0; i < rowLength; i++) {
      var oCells = table.rows.item(i).cells;
      var cellLength = oCells.length;
      for (var j = 0; j < cellLength; j++) {
        var cellVal = oCells.item(j).innerHTML;
        cellVal = cellVal.replace(regex, "");
        cellVal = cellVal.replace(/,/g, "|");
        cellVal = cellVal.replace(/\n/g, "|");
        csv = csv + cellVal + ",";
      }
      csv = csv.slice(0, -1);
      csv = csv + "\n";
    }
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = selected == 7 ? "Reporte_bigq.csv" : "";
    hiddenElement.click();
  }
  let mostrar_todo_ = false;
  function mostrar_todo() {
    if (!mostrar_todo_) {
      p_size = 10000;
      mostrar_todo_ = true;
    } else {
      p_size = 200;
      mostrar_todo_ = false;
    }
  }
  function data_table_on() {}
  let selected_edit = -1;
  let selected_borrar = -1;
  let eco_selected = [];
  let eco_master = "";
  function menu_grupos(i) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Editar",
          onClick: () => {
            eco_selected = [...$unit_groups[i][1]];
            selected_edit = i;
          },
        },
        {
          label: "Borrar",
          onClick: () => {
            delete_group(i);
          },
        },
      ],
    });
  }
  function save_group(i, master) {
    console.log(eco_selected, i, master);
    socket_query.emit("edit_unit_group", master, i, eco_selected, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Grupo guardado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        extra_add = [];
        selected_edit = -1;
      }
    });
  }
  let new_group = [];
  let new_group_mode = false;
  function add_group() {
    if (new_group.length == 0) {
      eco_selected = [];
      new_group = [[[], [[]]]];
      new_group_mode = true;
    }
  }
  function add_secundario() {
    if (new_group.length != 0) {
      new_group[0][1].push([]);
      new_group = [...new_group];
    }
  }
  let saved_ok = false,
    saved_type,
    saved_text;
  function save_new_group() {
    socket_query.emit("new_unit_group", eco_master, eco_selected, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Grupo guardado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        new_group = [];
        new_group_mode = false;
      }
    });
  }
  let extra_add = [];
  function delete_group(i) {
    socket_query.emit("delete_unit_group", i, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Grupo borrado";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }

  let new_unit = [];
  let new_unit_mode = false;
  let new_eco = "",
    new_serial = "",
    new_type,
    edit_type;
  function menu_unidades(i, unit) {
    new Contextual({
      isSticky: false,
      items: [
        {
          label: "Editar",
          onClick: () => {
            select_actual(i);
            selected_edit = i;
          },
        },
        {
          label: "Borrar",
          onClick: () => {
            delete_unidad(i, unit);
          },
        },
      ],
    });
  }
  function delete_unidad(i, unit) {
    socket_query.emit("delete_unit", unit, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Unidad borrada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  function add_unidad() {
    if (new_unit.length == 0) {
      new_type = $unit_types[0];
      new_unit = [[]];
      new_unit_mode = true;
    }
  }
  function save_new_unit(i) {
    console.log(new_eco, new_serial);
    socket_query.emit(
      "new_unit",
      new_eco.replace(/\s/g, ""),
      new_serial,
      new_type,
      (data) => {
        if (data == "ok") {
          saved_type = "alert-success";
          saved_text = "Unidad guardada";
          saved_ok = true;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
          new_unit = [];
          new_unit_mode = false;
        }
      }
    );
  }
  function save_unit(unit) {
    socket_query.emit("edit_unit", unit, new_serial, edit_type, (data) => {
      if (data == "ok") {
        saved_type = "alert-success";
        saved_text = "Unidad guardada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
        selected_edit = -1;
      }
    });
  }
  function select_actual(i) {
    let item = $units_list[i];
    new_serial = item;
    edit_type =
      typeof $unit_config[item] != "undefined" ? $unit_config[item].type : "FS";
  }

  let new_report_cron = [];
  let new_report_cron_mode = false;
  function add_report_cron() {
    if (new_report_cron.length == 0) {
      new_report_cron = [[]];
      new_report_cron_mode = true;
    }
  }
  function save_new_report_cron(i) {}
  function day_es(i) {
    switch (i) {
      case 0:
        return "Dom ";
        break;
      case 1:
        return "Lun ";
        break;
      case 2:
        return "Mar ";
        break;
      case 3:
        return "Mie ";
        break;
      case 4:
        return "Jue ";
        break;
      case 5:
        return "Vie ";
        break;
      case 6:
        return "Sab ";
        break;
    }
  }

  let eco_f_,
    eco_f = "";
  $: {
    if (user_level > 4) {
      configuraciones.push("Diagnóstico (Big Query)");
      configuraciones.push("Sesiones");
      configuraciones = [...configuraciones];
    }
    if (user_level > 4) {
      socket_query.emit("units_big_query");
      socket_query.emit("sessions");
    }
  }
  let units_big_query = [];
  let big_seaching = false;
  socket_query.on("units_big_query", async function (data) {
    units_big_query = data;
  });
  $: {
    p_size;
    if (eco_f.length == 10) {
      if (units_big_query.includes(eco_f)) {
        big_seaching = true;
        paginas_l = [];
        secciones_l = [];
        cas_info = [];
        puls_info = {
          iccid: "",
          lastIdtime: "",
          scriptVersion: "",
          configVersion: "",
          firmware: "",
          firmware2: "",
          imsi: "",
          partInfo: "",
        };
        socket_query.emit("alert_reload", eco_f, 3, (data) => {});
        socket_query.emit("read_big_query", eco_f, fecha_v, fecha_v2);
        socket_query.emit("puls", eco_f);
        //socket_query.emit('cas',eco_f);
        cas_info_runing = true;
        socket_query.emit("cas2", eco_f, (data) => {
          cas_info_runing = false;
          data = JSON.parse(data);
          if (data.length > 0) {
            let cas_info_p = data;
            cas_info = [];
            for (let x in cas_info_p) {
              for (let y in cas_info_p[x]) {
                if (parseInt(y) == 3) {
                  // cambia fecha
                  let dat1 = cas_info_p[x][y]
                    .split(",")[0]
                    .replace(/\//g, "")
                    .replace("Ene", "01")
                    .replace("Feb", "02")
                    .replace("Mar", "03")
                    .replace("Abr", "04")
                    .replace("May", "05")
                    .replace("Jun", "06")
                    .replace("Jul", "07")
                    .replace("Ago", "08")
                    .replace("Sep", "09")
                    .replace("Oct", "10")
                    .replace("Nov", "11")
                    .replace("Dic", "12")
                    .replace(/ /g, "");
                  cas_info_p[x][y] =
                    moment(
                      dat1.substring(4, 8) +
                        dat1.substring(2, 4) +
                        dat1.substring(0, 2)
                    )
                      .utc()
                      .startOf("day")
                      .valueOf() / 1000;
                }
              }
            }
            for (let w in cas_info_p) {
              for (let x = 0; x < cas_info_p.length - 1; x++) {
                if (cas_info_p[x][3] < cas_info_p[x + 1][3]) {
                  let par = cas_info_p[x];
                  cas_info_p[x] = [...cas_info_p[x + 1]];
                  cas_info_p[x + 1] = [...par];
                }
              }
            }
            cas_info = [...cas_info_p];
          } else {
            cas_info = [];
          }
        });
      }
    }
  }
  let unit_data = [];
  let total_data = [];
  let total_data_ex = [];
  let run_cron = false;
  let last_post_big;
  socket_query.on("read_big_query", async function (data) {
    total_data = data;
    if (data.length > 0) {
      total_data_ex = [data[0]];
    }
    let max_size = data.length;
    paginas_l = [];
    f_pag = 0;
    secciones_l = [];
    f_secc = 0;
    for (let x = 0; x < Math.round(max_size / p_size) + 1; x++) {
      paginas_l.push(x);
    }
    for (let x = 0; x < Math.floor(paginas_l.length / 15) + 1; x++) {
      secciones_l.push(x);
    }
    filtro_secc(0);
    big_seaching = false;
    //eco_f_="";
    unit_data = [];
    for (let x = 0; x < p_size && x < data.length; x++) {
      unit_data.push(data[x]);
    }
    let now = new Date(moment().utc().startOf("day").valueOf())
      .toISOString()
      .split("T")[0];
    let hasta = new Date(fecha_v2 * 1000).toISOString().split("T")[0];
    if (eco_f != "" && now == hasta && total_data_ex.length > 0) {
      run_cron = true;
    }
    //console.log("rub",now,hasta,total_data_ex.length>0,eco_f)

    //Buscar ultima posición
    last_post_big = null;
    for (let x in data) {
      if (data[x][1].substring(20, 22) == "02") {
        let lat_total =
          parseInt(
            (parseInt(data[x][1].substring(42, 50), 16) > 0x80000000
              ? ((0xffffffff - parseInt(data[x][1].substring(42, 50), 16) + 1) *
                  -1) /
                10000000
              : parseInt(data[x][1].substring(42, 50), 16) / 10000000) * 10000
          ) / 10000;
        let lng_total =
          parseInt(
            (parseInt(data[x][1].substring(50, 58), 16) > 0x80000000
              ? ((0xffffffff - parseInt(data[x][1].substring(50, 58), 16) + 1) *
                  -1) /
                10000000
              : parseInt(data[x][1].substring(50, 58), 16) / 10000000) * 10000
          ) / 10000;
        last_post_big = { lat: lat_total, lng: lng_total };
        break;
      }
    }
  });

  function big_cron_() {
    if (run_cron) {
      socket_query.emit(
        "read_big_query",
        eco_f,
        fecha_v,
        fecha_v2,
        "now",
        total_data_ex[0][0]
      );
    }
    if (eco_f != "") {
      socket_query.emit("alert_reload", eco_f, 3, (data) => {});
    }
  }

  let big_cron;
  big_cron = setInterval(big_cron_, 30000);
  onDestroy(() => {
    run_cron = false;
    clearInterval(big_cron);
    big_cron = null;
  });

  socket_query.on("read_big_query_ex", async function (data) {
    if (data.length > 0) {
      total_data_ex = data.concat(total_data_ex);
      if (f_pag == 0) {
        filtro(0);
      }
    }
  });

  let fechas = [];
  let fecha_v_ = moment().startOf("day").subtract(8, "days").format("DD-MM-YY");
  let fecha_v =
    moment().utc().startOf("day").subtract(8, "days").valueOf() / 1000;
  let fecha_v_2 = moment().startOf("day").format("DD-MM-YY");
  let fecha_v2 = moment().utc().startOf("day").valueOf() / 1000;
  for (let x = 0; x < 90; x++) {
    fechas.push([
      moment().utc().startOf("day").subtract(x, "days").valueOf() / 1000,
      moment().startOf("day").subtract(x, "days").format("DD-MM-YY"),
    ]);
  }
  function hex2bin(hex) {
    return ("00000000" + parseInt(hex, 16).toString(2)).substr(-8);
  }
  let paginas_l = [];
  let secciones_l = [];
  let f_pag = 0;
  let f_secc = 0;
  let p_size = 200;
  function filtro(pagina) {
    if (true) {
      //if(f_pag!=pagina)
      unit_data = [];
      let total_data_ex_par = [...total_data_ex];
      total_data_ex_par.splice(total_data_ex_par.length - 1, 1);
      if (pagina == 0) {
        unit_data = [...total_data_ex_par];
      }
      for (
        let x = pagina * p_size;
        x < (pagina + 1) * p_size && x < total_data.length;
        x++
      ) {
        unit_data.push(total_data[x]);
      }
    }
    f_pag = pagina;
  }
  let pag_min = 0;
  let pag_max = 0;
  function filtro_secc(secc) {
    f_secc = secc;
    pag_min = secc * 15;
    pag_max = pag_min + 15;
  }
  function copy_full(text) {
    var el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    saved_type = "alert-success";
    saved_text = "Mensaje original copiado";
    saved_ok = true;
    setTimeout(function () {
      saved_ok = false;
    }, 3000);
  }
  let sessions = [];
  socket_query.on("sessions", async function (data) {
    let s = JSON.parse(data);
    let sessions_p = [];
    let sessions_p2 = [];
    for (let x in s) {
      sessions_p.push(s[x]);
    }
    for (let x in sessions_p) {
      if (sessions_p[x].status == 1) {
        sessions_p2.push(sessions_p[x]);
      }
    }
    for (let x in sessions_p) {
      if (sessions_p[x].status != 1) {
        sessions_p2.push(sessions_p[x]);
      }
    }
    sessions = [...sessions_p2];
  });

  let big_filtro = 0;
  function change_filtro(filtro) {
    big_filtro = filtro;
  }

  function hex2a(hexx) {
    var hex = hexx.toString(); //force conversion
    var str = "";
    for (var i = 0; i < hex.length; i += 2) {
      let x = parseInt(hex.substr(i, 2), 16);
      if (x > 31 && x < 127) {
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
      }
    }
    return str;
  }
  let event_map = {
    "01": "PowerUp",
    "03": "OTADownload",
    "07": "WakeUPSMS",
    "08": "WakeUP",
    "0D": "BeginMove",
    "0E": "NotMoving",
    "0F": "Idling",
    "10": "Stop",
    "12": "GPSJamming",
    "13": "RadioJam",
    "14": "IgnOff",
    "15": "IgnOn",
    "1E": "NormalON",
    "1F": "NormalOFF",
    "20": "WakeUPSleep",
    "21": "GPSAcquired",
    "22": "GPSLost",
    "23": "COMAcquired",
    "24": "COMLost",
    "25": "COMOff",
    "28": "PowerDisconn",
    "29": "PowerConn",
    "2A": "BattLow",
    "2B": "BattOK",
    "2C": "DriverID",
    "2D": "TempAboveS1",
    "2E": "TempOKS1",
    "2F": "TempBelowS1",
    "30": "TempAboveS2",
    "31": "TempOKS2",
    "32": "TempBelowS2",
    "33": "TempAboveS3",
    "34": "TempOKS3",
    "35": "TempBelowS3",
    "36": "MDTdetected",
    "37": "MDTNOdetected",
    "38": "MDTtest",
    "3C": "In1LtoH",
    "3D": "In1HtoL",
    "3E": "In2LtoH",
    "3F": "In2HtoL",
    "40": "In3LtoH",
    "41": "In3HtoL",
    "42": "In4LtoH",
    "43": "In4HtoL",
    "44": "In5LtoH",
    "45": "In5HtoL",
    "46": "In6LtoH",
    "47": "In6HtoL",
    "48": "In7LtoH",
    "49": "In7HtoL",
    "50": "DriverLogIn",
    "51": "DriverLogOut",
    "5A": "SpeedAboveTh0",
    "5B": "SpeedBelowTh0",
    "6E": "HarshAcceleration",
    "6F": "HarshBrake",
    "70": "HarshTLeft",
    "71": "HarshTRight",
    "72": "SMSReceivedWrite",
    "73": "SMSReceivedPEGAction",
    "74": "RadioJammingEnd",
    "75": "GPSJammingEnd",
    "76": "WakeUPMov",
    "81": "HibernateMode",
    FF: "VectorQ",
  };

  function get_5(app_msg_jp2, app_len) {
    var options_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(0, 2);
    var report_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(2, 4);
    var state_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(4, 8);
    var group_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(8, 10);
    var list_jp2 = !app_msg_jp2 ? null : app_msg_jp2.substring(10);
    if (options_jp2 == "05" && group_jp2 == "00") {
      //Group 0 Option 5
      let jp2_c = 0,
        data_jp2 = [],
        jp2_par_c = 0,
        jp2_data_c = 0,
        jp2_param = 0,
        data_jp2_s = [];
      for (var x = 0; x < app_len * 2 - 10; x = x + 2) {
        if (jp2_c == 0) {
          jp2_c = parseInt(list_jp2.substring(x, x + 2), 16);
          jp2_param = jp2_par_c;
          data_jp2.push("");
          jp2_par_c++;
          jp2_data_c = 0;
        } else {
          if (jp2_data_c == 0) {
            data_jp2_s.push(list_jp2.substring(x, x + 2));
            jp2_data_c++;
          } else {
            if (jp2_param != 34 && jp2_param != 16) {
              data_jp2[jp2_param] =
                list_jp2.substring(x, x + 2) + data_jp2[jp2_param];
            } else {
              data_jp2[jp2_param] =
                data_jp2[jp2_param] + list_jp2.substring(x, x + 2);
            }
          }
          jp2_c--;
        }
      }

      let odometer_1708 = parseInt(parseInt(data_jp2[0], 16) * 0.161);
      data_jp2[0] = odometer_1708;
      let batt_voltage_1708 = parseInt(parseInt(data_jp2[1], 16) * 5);
      data_jp2[1] = batt_voltage_1708;
      let batt_voltage_switched_1708 = parseInt(parseInt(data_jp2[2], 16) * 5);
      data_jp2[2] = batt_voltage_switched_1708;
      let rpm_1708 = parseInt(parseInt(data_jp2[3], 16) * 0.25);
      data_jp2[3] = rpm_1708;
      let road_speed_1708 = parseInt(parseInt(data_jp2[4], 16) * 0.805);
      data_jp2[4] = road_speed_1708;
      let total_fuel_1708 = parseInt(parseInt(data_jp2[5], 16) * 0.473);
      data_jp2[5] = total_fuel_1708;
      let total_idle_fuel_1708 = parseInt(parseInt(data_jp2[6], 16) * 0.473);
      data_jp2[6] = total_idle_fuel_1708;
      let total_idle_hours_1708 = parseInt(parseInt(data_jp2[7], 16) * 0.05);
      data_jp2[7] = total_idle_hours_1708;
      let fuel_level_1708 = parseInt(parseInt(data_jp2[8], 16) * 0.5);
      data_jp2[8] = fuel_level_1708;
      let total_engine_hours_1708 = parseInt(parseInt(data_jp2[9], 16) * 0.05);
      data_jp2[9] = total_engine_hours_1708;
      let engine_coolant_temp_1708 = parseInt(
        parseInt(data_jp2[10], 16) * (5 / 9)
      );
      data_jp2[10] = engine_coolant_temp_1708;
      let eng_oil_temp_1708 = parseInt(
        (parseInt(data_jp2[11], 16) - 32) * 0.25 * (5 / 9)
      );
      data_jp2[11] = eng_oil_temp_1708;
      let eng_coolant_level_1708 = parseInt(parseInt(data_jp2[12], 16) * 0.5);
      data_jp2[12] = eng_coolant_level_1708;
      let eng_oil_level_1708 = parseInt(parseInt(data_jp2[13], 16) * 0.5);
      data_jp2[13] = eng_oil_level_1708;
      let eng_coolant_pressure_1708 = parseInt(
        parseInt(data_jp2[14], 16) * 0.862
      );
      data_jp2[14] = eng_coolant_pressure_1708;
      let eng_oil_pressure_1708 = parseInt(parseInt(data_jp2[15], 16) * 3.45);
      data_jp2[15] = eng_oil_pressure_1708;
      let vin_1708 = hex2asc(data_jp2[16]) != "" ? hex2asc(data_jp2[16]) : null;
      data_jp2[16] = vin_1708;

      let odometer_1939 = parseInt(parseInt(data_jp2[17], 16) * 0.125);
      data_jp2[17] = odometer_1939;
      let odometer_hi_1939 = parseInt(parseInt(data_jp2[18], 16) * (5 / 1000));
      data_jp2[18] = odometer_hi_1939;
      let batt_voltage_1939 = parseInt(parseInt(data_jp2[19], 16) * 5);
      data_jp2[19] = batt_voltage_1939;
      let batt_voltage_switched_1939 = parseInt(parseInt(data_jp2[20], 16) * 5);
      data_jp2[20] = batt_voltage_switched_1939;
      let rpm_1939 = parseInt(parseInt(data_jp2[21], 16) * 0.125);
      data_jp2[21] = rpm_1939;
      let road_speed_1939 = parseInt(parseInt(data_jp2[22], 16) / 256);
      data_jp2[22] = road_speed_1939;
      let total_fuel_1939 = parseInt(parseInt(data_jp2[23], 16) * 0.5);
      data_jp2[23] = total_fuel_1939;
      let total_idle_fuel_1939 = parseInt(parseInt(data_jp2[24], 16) * 0.5);
      data_jp2[24] = total_idle_fuel_1939;
      let total_idle_hours_1939 = parseInt(parseInt(data_jp2[25], 16) * 0.05);
      data_jp2[25] = total_idle_hours_1939;
      let fuel_level_1939 = parseInt(parseInt(data_jp2[26], 16) * 0.4);
      data_jp2[26] = fuel_level_1939;
      let total_engine_hours_1939 = parseInt(parseInt(data_jp2[27], 16) * 0.05);
      data_jp2[27] = total_engine_hours_1939;
      let engine_coolant_temp_1939 = parseInt(parseInt(data_jp2[28], 16) - 40);
      data_jp2[28] = engine_coolant_temp_1939;
      let eng_oil_temp_1939 = parseInt(parseInt(data_jp2[29], 16) / 32 - 273);
      data_jp2[29] = eng_oil_temp_1939;
      let eng_coolant_level_1939 = parseInt(parseInt(data_jp2[30], 16) * 0.4);
      data_jp2[30] = eng_coolant_level_1939;
      let eng_oil_level_1939 = parseInt(parseInt(data_jp2[31], 16) * 0.4);
      data_jp2[31] = eng_oil_level_1939;
      let eng_coolant_pressure_1939 = parseInt(parseInt(data_jp2[32], 16) * 2);
      data_jp2[32] = eng_coolant_pressure_1939;
      let eng_oil_pressure_1939 = parseInt(parseInt(data_jp2[33], 16) * 4);
      data_jp2[33] = eng_oil_pressure_1939;
      let vin_1939 = hex2asc(data_jp2[34]) != "" ? hex2asc(data_jp2[34]) : null;
      data_jp2[34] = vin_1939;

      return data_jp2;
    }
    return null;
  }
  function get_5_JP1(app_msg, type) {
    let odometer_1708 = "",
      odometer_1939 = "",
      odometer_hi_1939 = "",
      batt_voltage_1708 = "",
      batt_voltage_1939 = "",
      batt_voltage_switched_1708 = "",
      batt_voltage_switched_1939 = "",
      rpm_1708 = "",
      rpm_1939 = "",
      road_speed_1708 = "",
      road_speed_1939 = "",
      total_fuel_1708 = "",
      total_fuel_1939 = "",
      total_idle_fuel_1708 = "",
      total_idle_fuel_1939 = "",
      total_idle_hours_1708 = "",
      total_idle_hours_1939 = "",
      fuel_level_1708 = "",
      fuel_level_1939 = "",
      total_engine_hours_1708 = "",
      total_engine_hours_1939 = "",
      engine_coolant_temp_1708 = "",
      engine_coolant_temp_1939 = "",
      eng_oil_temp_1708 = "",
      eng_oil_temp_1939 = "",
      eng_coolant_level_1708 = "",
      eng_coolant_level_1939 = "",
      eng_oil_level_1708 = "",
      eng_oil_level_1939 = "",
      eng_coolant_pressure_1708 = "",
      eng_coolant_pressure_1939 = "",
      eng_oil_pressure_1708 = "",
      eng_oil_pressure_1939 = "",
      vin_1708 = "",
      vin_1939 = "";
    let map_id = app_msg.substring(0, 2);

    if (map_id == "2c") {
      odometer_1708 = parseInt(
        parseInt(
          app_msg.substring(8, 10) +
            app_msg.substring(6, 8) +
            app_msg.substring(4, 6) +
            app_msg.substring(2, 4),
          16
        ) * 0.161
      );
      odometer_1939 = parseInt(
        parseInt(
          app_msg.substring(16, 18) +
            app_msg.substring(14, 16) +
            app_msg.substring(12, 14) +
            app_msg.substring(10, 12),
          16
        ) * 0.125
      );
      odometer_hi_1939 = parseInt(
        parseInt(
          app_msg.substring(24, 26) +
            app_msg.substring(22, 24) +
            app_msg.substring(20, 22) +
            app_msg.substring(18, 20),
          16
        ) *
          (5 / 1000)
      );
      batt_voltage_1708 = parseInt(
        parseInt(app_msg.substring(28, 30) + app_msg.substring(26, 28), 16) * 5
      );
      batt_voltage_1939 = parseInt(
        parseInt(app_msg.substring(32, 34) + app_msg.substring(30, 32), 16) * 5
      );
      batt_voltage_switched_1708 = parseInt(
        parseInt(app_msg.substring(36, 38) + app_msg.substring(34, 36), 16) * 5
      );
      batt_voltage_switched_1939 = parseInt(
        parseInt(app_msg.substring(40, 42) + app_msg.substring(38, 40), 16) * 5
      );
      rpm_1708 = parseInt(
        parseInt(app_msg.substring(44, 46) + app_msg.substring(42, 44), 16) *
          0.25
      );
      rpm_1939 = parseInt(
        parseInt(app_msg.substring(48, 50) + app_msg.substring(46, 48), 16) *
          0.125
      );
      road_speed_1708 = parseInt(
        parseInt(app_msg.substring(50, 52), 16) * 0.805
      );
      road_speed_1939 = parseInt(
        parseInt(app_msg.substring(54, 56) + app_msg.substring(52, 54), 16) /
          256
      );
    }

    if (map_id == "2d") {
      total_fuel_1708 = parseInt(
        parseInt(
          app_msg.substring(8, 10) +
            app_msg.substring(6, 8) +
            app_msg.substring(4, 6) +
            app_msg.substring(2, 4),
          16
        ) * 0.473
      );
      total_fuel_1939 = parseInt(
        parseInt(
          app_msg.substring(16, 18) +
            app_msg.substring(14, 16) +
            app_msg.substring(12, 14) +
            app_msg.substring(10, 12),
          16
        ) * 0.5
      );
      total_idle_fuel_1708 = parseInt(
        parseInt(
          app_msg.substring(24, 26) +
            app_msg.substring(22, 24) +
            app_msg.substring(20, 22) +
            app_msg.substring(18, 20),
          16
        ) * 0.473
      );
      total_idle_fuel_1939 = parseInt(
        parseInt(
          app_msg.substring(32, 34) +
            app_msg.substring(30, 32) +
            app_msg.substring(28, 30) +
            app_msg.substring(26, 28),
          16
        ) * 0.5
      );
      total_idle_hours_1708 = parseInt(
        parseInt(
          app_msg.substring(40, 42) +
            app_msg.substring(38, 40) +
            app_msg.substring(36, 38) +
            app_msg.substring(34, 36),
          16
        ) * 0.05
      );
      total_idle_hours_1939 = parseInt(
        parseInt(
          app_msg.substring(48, 50) +
            app_msg.substring(46, 48) +
            app_msg.substring(44, 46) +
            app_msg.substring(42, 44),
          16
        ) * 0.05
      );
      fuel_level_1708 = parseInt(parseInt(app_msg.substring(50, 52), 16) * 0.5);
      fuel_level_1939 = parseInt(parseInt(app_msg.substring(52, 54), 16) * 0.4);
    }

    if (map_id == "2e") {
      total_engine_hours_1708 = parseInt(
        parseInt(
          app_msg.substring(8, 10) +
            app_msg.substring(6, 8) +
            app_msg.substring(4, 6) +
            app_msg.substring(2, 4),
          16
        ) * 0.05
      );
      total_engine_hours_1939 = parseInt(
        parseInt(
          app_msg.substring(16, 18) +
            app_msg.substring(14, 16) +
            app_msg.substring(12, 14) +
            app_msg.substring(10, 12),
          16
        ) * 0.05
      );
      engine_coolant_temp_1708 = parseInt(
        parseInt(app_msg.substring(18, 20), 16) * (5 / 9)
      );
      engine_coolant_temp_1939 = parseInt(
        parseInt(app_msg.substring(20, 22), 16) - 40
      );
      eng_oil_temp_1708 = parseInt(
        (parseInt(app_msg.substring(24, 26) + app_msg.substring(22, 24), 16) -
          32) *
          0.25 *
          (5 / 9)
      );
      eng_oil_temp_1939 = parseInt(
        parseInt(app_msg.substring(28, 30) + app_msg.substring(26, 28), 16) /
          32 -
          273
      );
      eng_coolant_level_1708 = parseInt(
        parseInt(app_msg.substring(30, 32), 16) * 0.5
      );
      eng_coolant_level_1939 = parseInt(
        parseInt(app_msg.substring(32, 34), 16) * 0.4
      );
      eng_oil_level_1708 = parseInt(
        parseInt(app_msg.substring(34, 36), 16) * 0.5
      );
      eng_oil_level_1939 = parseInt(
        parseInt(app_msg.substring(36, 38), 16) * 0.4
      );
      eng_coolant_pressure_1708 = parseInt(
        parseInt(app_msg.substring(38, 40), 16) * 0.862
      );
      eng_coolant_pressure_1939 = parseInt(
        parseInt(app_msg.substring(40, 42), 16) * 2
      );
      eng_oil_pressure_1708 = parseInt(
        parseInt(app_msg.substring(42, 44), 16) * 3.45
      );
      eng_oil_pressure_1939 = parseInt(
        parseInt(app_msg.substring(44, 46), 16) * 4
      );
    }

    if (map_id == "30") {
      vin_1708 = hex2asc(app_msg.substring(2), 16);
    }
    if (map_id == "31") {
      vin_1939 = hex2asc(app_msg.substring(2), 16);
    }

    if (type == "1708") {
      return [
        odometer_1708,
        rpm_1708,
        road_speed_1708,
        total_fuel_1708,
        total_idle_fuel_1708,
        total_idle_hours_1708,
        total_engine_hours_1708,
        vin_1708,
        batt_voltage_1708,
        batt_voltage_switched_1708,
        engine_coolant_temp_1708,
        eng_oil_temp_1708,
        eng_coolant_level_1708,
        eng_oil_level_1708,
        eng_coolant_pressure_1708,
        eng_oil_pressure_1708,
      ];
    } else if (type == "1939") {
      return [
        odometer_1939,
        odometer_hi_1939,
        rpm_1939,
        road_speed_1939,
        total_fuel_1939,
        total_idle_fuel_1939,
        total_idle_hours_1939,
        total_engine_hours_1939,
        vin_1939,
        batt_voltage_1939,
        batt_voltage_switched_1939,
        engine_coolant_temp_1939,
        eng_oil_temp_1939,
        eng_coolant_level_1939,
        eng_oil_level_1939,
        eng_coolant_pressure_1939,
        eng_oil_pressure_1939,
      ];
    }
  }
  let close_matrix_modal = false;
  let matrix_table = [[], [], [], [], [], []];
  let matrix_label = ["0-20", "21-40", "41-60", "61-80", "81-100", ">100"];

  function get_matrix_JP1(app_msg, type) {
    let map_id = app_msg.substring(0, 2);
    let matrix = [[], [], [], [], [], []];
    if (map_id == "5a") {
      let col = 0;
      let fila = 0;
      for (let x = 4; x < 144 + 4; x = x + 4) {
        if (col == 0) {
          matrix[fila].push([matrix_label[fila]]);
        }
        matrix[fila].push([
          parseInt(
            app_msg[x + 2] + app_msg[x + 3] + app_msg[x] + app_msg[x + 1],
            16
          ),
        ]);
        col++;
        if (col == 6) {
          fila++;
          col = 0;
        }
      }
    }
    matrix_table = [...matrix];
    close_matrix_modal = true;
  }
  function hex2asc(hex) {
    try {
      hex = hex.toString();
    } catch (e) {
      hex = "00";
      console.log(e);
    }
    var str = "";
    for (
      var i = 0;
      i < hex.length &&
      hex.substr(i, 2) !== "00" &&
      parseInt(hex.substr(i, 2), 16) > 47;
      i += 2
    )
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
  }
  let cas_info = [],
    puls_info = {
      iccid: "",
      lastIdtime: "",
      scriptVersion: "",
      configVersion: "",
      firmware: "",
      firmware2: "",
      imsi: "",
      partInfo: "",
    };
  socket_query.on("puls", async function (data) {
    puls_info = JSON.parse(data);
  });
  let cas_info_runing = false;
  let f_sesion = 1;
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

{#if close_matrix_modal}
  <div
    class="modal fade {close_matrix_modal ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {close_matrix_modal
      ? 'block'
      : 'none'}; z-index:1052;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 40%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons bblue md-2">directions</i> Matriz RPM<strong
            />
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="mdc-data-table" style="display:flex" use:data_table_on>
            <table class="mdc-data-table__table" id="travels_table">
              <thead>
                <tr class="mdc-data-table__header-row">
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">Road Speed Interval (KPH)</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">0-500</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">501-1000</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">1001-1500</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">1501-2000</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">2001-2500</th
                  >
                  <th
                    class="mdc-data-table__header-cell"
                    role="columnheader"
                    scope="col">>2500</th
                  >
                </tr>
              </thead>
              <tbody class="mdc-data-table__content">
                {#each matrix_table as fila, i}
                  <tr class="mdc-data-table__row">
                    {#each fila as item, j}
                      <td class="mdc-data-table__cell">
                        {item}
                      </td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-end">
            <div>
              <button
                on:click={() => {
                  close_matrix_modal = false;
                }}
                class="btn btn-success big"
                type="button"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1051" />
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div class="card-header bg-dark text-white b-0">
            <i tooltip="Configuracion" class="material-icons md-90">widgets</i> Configuraciones
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each configuraciones as item, i}
                <a
                  href="/"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    selected_edit = -1;
                    maping(i);
                  }}
                  class="list-group-item list-group-item-action">{item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          {#if selected != -1}
            <div class="card-header bg-dark text-white b-0">
              <div class="d-flex justify-content-between">
                <div>
                  <i tooltip="Reportes" class="material-icons md-90">notes</i>
                  {configuraciones[selected]}
                  {#if selected == 8}
                    <div class="btn-group mx-2" role="group">
                      <button
                        on:click|preventDefault={() => {
                          f_sesion = 1;
                        }}
                        type="button"
                        class="btn {f_sesion == 1
                          ? 'btn-info'
                          : 'btn-outline-info'}"
                        style={f_sesion == 1 ? "" : "background-color:white"}
                        >En linea</button
                      >
                      <button
                        on:click|preventDefault={() => {
                          f_sesion = 0;
                        }}
                        type="button"
                        class="btn {f_sesion == 0
                          ? 'btn-info'
                          : 'btn-outline-info'}"
                        style={f_sesion == 0 ? "" : "background-color:white"}
                        >Todos
                      </button>
                    </div>
                  {/if}
                </div>
                <div>
                  <button
                    on:click={mostrar_todo}
                    id="csv_2"
                    type="button"
                    class="btn btn-{!mostrar_todo_
                      ? 'secondary'
                      : 'success'} mini">Mostar todo</button
                  >
                  <button
                    on:click={reporte_csv}
                    id="csv"
                    type="button"
                    class="btn btn-success mini">Descargar CSV</button
                  >
                </div>
              </div>
            </div>
            <div class="card-body flex-grow-1 overflow-auto">
              {#if selected == 0}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Equipo maestro</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Equipos secundarios</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                          style="text-align: right;"
                        >
                          {#if selected_edit == -1}
                            <button
                              id="add_group"
                              on:click={add_group}
                              type="button"
                              class="btn btn-info mini"
                            >
                              <i class="material-icons md-90"
                                >add_circle_outline</i
                              >
                              Agregar
                            </button>
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each new_group as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            <input
                              list="lista_unidades"
                              placeholder="Unidad"
                              size="14"
                              on:change={(event) => {
                                eco_master = "";
                                for (let w in $nicks_list) {
                                  if ($nicks_list[w] == event.target.value) {
                                    eco_master = w;
                                  }
                                }
                              }}
                            />
                            <datalist id="lista_unidades">
                              {#each units_list_p as item, i}
                                <option
                                  value={typeof $nicks_list[item] != "undefined"
                                    ? $nicks_list[item]
                                    : item}
                                />{/each}
                            </datalist>
                          </td>
                          <td class="mdc-data-table__cell">
                            {#each item[1] as item_e, j}
                              <input
                                list="lista_unidades"
                                placeholder="Unidad"
                                size="14"
                                on:change={(event) => {
                                  eco_selected[j] = "";
                                  for (let w in $nicks_list) {
                                    if ($nicks_list[w] == event.target.value) {
                                      eco_selected[j] = w;
                                    }
                                  }
                                }}
                              />
                              <datalist id="lista_unidades">
                                {#each units_list_p as item, i}
                                  <option
                                    value={typeof $nicks_list[item] !=
                                    "undefined"
                                      ? $nicks_list[item]
                                      : item}
                                  />{/each}
                              </datalist>
                            {/each}
                            <button
                              id="add_{i}"
                              type="button"
                              class="btn btn-link"
                              on:click={add_secundario}
                              ><i class="material-icons md-90 green"
                                >add_circle_outline</i
                              ></button
                            >
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              id="save_new_group"
                              on:click={() => {
                                save_new_group(i);
                              }}
                              type="button"
                              class="btn btn-success mini">Guardar</button
                            >
                            <button
                              id="cancel_save_group"
                              on:click={() => {
                                new_group = [];
                                new_group_mode = false;
                              }}
                              type="button"
                              class="btn btn-danger mini">Cancelar</button
                            >
                          </td>
                        </tr>
                      {/each}
                      {#each $unit_groups as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            {typeof $nicks_list[item[0]] != "undefined"
                              ? $nicks_list[item[0]]
                              : item[0]}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#if selected_edit != i}
                              {#each item[1] as item_e, j}
                                {typeof $nicks_list[item_e] != "undefined"
                                  ? $nicks_list[item_e]
                                  : item_e}{j != item[1].length - 1 ? "," : ""}
                              {/each}
                            {:else}
                              {#each item[1] as item_e, j}
                                <input
                                  value={typeof $nicks_list[item_e] !=
                                  "undefined"
                                    ? $nicks_list[item_e]
                                    : item_e}
                                  list="lista_unidades"
                                  placeholder="Unidad"
                                  size="14"
                                  on:change={(event) => {
                                    eco_selected[j] = "";
                                    for (let w in $nicks_list) {
                                      if (
                                        $nicks_list[w] == event.target.value
                                      ) {
                                        eco_selected[j] = w;
                                      }
                                    }
                                  }}
                                />
                                <datalist id="lista_unidades">
                                  {#each units_list_p as item, i}
                                    <option
                                      value={typeof $nicks_list[item] !=
                                      "undefined"
                                        ? $nicks_list[item]
                                        : item}
                                    />{/each}
                                </datalist>
                              {/each}
                              {#each extra_add as item_e, t}
                                <input
                                  list="lista_unidades"
                                  placeholder="Unidad"
                                  size="14"
                                  on:change={(event) => {
                                    eco_selected[item[1].length + t] = "";
                                    for (let w in $nicks_list) {
                                      if (
                                        $nicks_list[w] == event.target.value
                                      ) {
                                        eco_selected[item[1].length + t] = w;
                                      }
                                    }
                                  }}
                                />
                                <datalist id="lista_unidades">
                                  {#each units_list_p as item, i}
                                    <option
                                      value={typeof $nicks_list[item] !=
                                      "undefined"
                                        ? $nicks_list[item]
                                        : item}
                                    />{/each}
                                </datalist>
                              {/each}
                              <button
                                id="add_{i}"
                                type="button"
                                class="btn btn-link"
                                on:click={() => {
                                  extra_add.push([]);
                                  extra_add = [...extra_add];
                                }}
                                ><i class="material-icons md-90 green"
                                  >add_circle_outline</i
                                ></button
                              >
                              <button
                                id="save_group"
                                on:click={() => {
                                  save_group(i, item[0]);
                                }}
                                type="button"
                                class="btn btn-success mini">Guardar</button
                              >
                              <button
                                id="cancel_save_group"
                                on:click={() => {
                                  extra_add = [];
                                  selected_edit = -1;
                                }}
                                type="button"
                                class="btn btn-danger mini">Cancelar</button
                              >
                            {/if}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            {#if !new_group_mode}
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() => menu_grupos(i)}
                                class="mdc-icon-button material-icons"
                                >more_vert</button
                              >
                            {/if}
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 1}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Nick name</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Serial</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Tipo</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                          style="text-align: right;"
                        >
                          {#if selected_edit == -1}
                            <button
                              id="add_unidad"
                              on:click={add_unidad}
                              type="button"
                              class="btn btn-info mini"
                            >
                              <i class="material-icons md-90"
                                >add_circle_outline</i
                              >
                              Agregar
                            </button>
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each new_unit as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Nick name"
                              size="14"
                              maxlength="14"
                              on:change={(event) => {
                                new_eco = event.target.value;
                              }}
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Serial"
                              size="10"
                              maxlength="10"
                              on:change={(event) => {
                                new_serial = event.target.value;
                              }}
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <select bind:value={new_type}>
                              {#each $unit_types as item, i}
                                <option value={item}>{item}</option>
                              {/each}
                            </select>
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              id="save_new_unit"
                              on:click={() => {
                                save_new_unit(i);
                              }}
                              type="button"
                              class="btn btn-success mini">Guardar</button
                            >
                            <button
                              id="cancel_save_group"
                              on:click={() => {
                                new_unit = [];
                                new_unit_mode = false;
                              }}
                              type="button"
                              class="btn btn-danger mini">Cancelar</button
                            >
                          </td>
                        </tr>
                      {/each}
                      {#each $units_list as item, i (item)}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            {typeof $nicks_list[item] != "undefined"
                              ? $nicks_list[item]
                              : item}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#if selected_edit != i}
                              {item}
                            {:else}
                              <input
                                type="text"
                                placeholder="Serial"
                                size="10"
                                maxlength="10"
                                on:change={(event) => {
                                  new_serial = event.target.value;
                                }}
                                value={item}
                              />
                            {/if}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#if selected_edit != i}
                              {typeof $unit_config[item] != "undefined"
                                ? $unit_config[item].type
                                : "FS"}
                            {:else}
                              <select bind:value={edit_type}>
                                {#each $unit_types as item, i}
                                  <option value={item}>{item}</option>
                                {/each}
                              </select>

                              <button
                                id="save_unit"
                                on:click={() => {
                                  save_unit(item);
                                }}
                                type="button"
                                class="btn btn-success mini">Guardar</button
                              >
                              <button
                                id="cancel_save_group"
                                on:click={() => {
                                  selected_edit = -1;
                                }}
                                type="button"
                                class="btn btn-danger mini">Cancelar</button
                              >
                            {/if}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            {#if !new_group_mode}
                              <button
                                style="outline: unset;"
                                on:click|preventDefault={() =>
                                  menu_unidades(i, item)}
                                class="mdc-icon-button material-icons"
                                >more_vert</button
                              >
                            {/if}
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 2}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Nombre del Reporte</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Hora</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Días de la semana</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Variables</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Formato</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Emails</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col"
                          style="text-align: right;"
                        >
                          {#if selected_edit == -1}
                            <button
                              id="add_report_cron"
                              on:click={add_report_cron}
                              type="button"
                              class="btn btn-info mini"
                            >
                              <i class="material-icons md-90"
                                >add_circle_outline</i
                              >
                              Agregar
                            </button>
                          {/if}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each new_report_cron as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Nombre"
                              size="10"
                              maxlength="10"
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Hora"
                              size="10"
                              maxlength="10"
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Días"
                              size="10"
                              maxlength="10"
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Variables"
                              size="10"
                              maxlength="10"
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Formato"
                              size="10"
                              maxlength="10"
                            />
                          </td>
                          <td class="mdc-data-table__cell">
                            <input
                              type="text"
                              placeholder="Emails"
                              size="10"
                              maxlength="10"
                            />
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              id="save_new_report_cron"
                              on:click={() => {
                                save_new_report_cron(i);
                              }}
                              type="button"
                              class="btn btn-success mini">Guardar</button
                            >
                            <button
                              id="cancel_save_group"
                              on:click={() => {
                                new_report_cron = [];
                                new_report_cron_mode = false;
                              }}
                              type="button"
                              class="btn btn-danger mini">Cancelar</button
                            >
                          </td>
                        </tr>
                      {/each}
                      {#each $reports_cron as item, i}
                        <tr class="mdc-data-table__row">
                          <td class="mdc-data-table__cell">
                            {item.name}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#each item.time as time, i}
                              {time + ":00 "}
                            {/each}
                          </td>
                          <td class="mdc-data-table__cell">
                            {#each item.day as day, i}
                              {day_es(day)}
                            {/each}
                          </td>
                          <td class="mdc-data-table__cell">
                            {item.vars}
                          </td>
                          <td class="mdc-data-table__cell">
                            {item.format}
                          </td>
                          <td class="mdc-data-table__cell">
                            {item.emails}
                          </td>
                          <td
                            class="mdc-data-table__cell"
                            style="text-align: right;"
                          >
                            <button
                              style="outline: unset;"
                              on:click|preventDefault
                              class="mdc-icon-button material-icons"
                              >more_vert</button
                            >
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
              {#if selected == 7}
                <div class="mdc-data-table d-flex h-100" use:data_table_on>
                  <div
                    class="d-flex py-3"
                    style="border-bottom: 1px solid #e0e0e0;height: 40px;align-items: center;"
                  >
                    <i
                      class="material-icons mdc-list-item__graphic"
                      aria-hidden="true"
                      style="margin-left:16px;margin-right:0px">filter_list</i
                    >
                    <input
                      on:click={() => {
                        eco_f_ = "";
                      }}
                      bind:value={eco_f_}
                      list="lista_unidades"
                      placeholder="Unidad"
                      size="12"
                      on:change={(event) => {
                        eco_f = event.target.value;
                      }}
                      disabled={big_seaching ? "disabled" : ""}
                      class="mx-1"
                    />
                    <datalist id="lista_unidades">
                      {#each units_big_query as item, i}
                        <option value={item} />{/each}
                    </datalist>

                    <input
                      list="fecha"
                      placeholder="Desde"
                      size="8"
                      bind:value={fecha_v_}
                      on:click={() => {
                        fecha_v_ = "";
                      }}
                      on:change={(event) => {
                        fecha_v = "";
                        for (let w in fechas) {
                          if (fechas[w][1] == event.target.value) {
                            fecha_v = fechas[w][0];
                          }
                        }
                      }}
                      disabled={big_seaching ? "disabled" : ""}
                      class="mx-1"
                    />
                    <datalist id="fecha">
                      {#each fechas as item, i}
                        <option value={item[1]} />{/each}
                    </datalist>

                    <input
                      list="fecha"
                      placeholder="Hasta"
                      size="8"
                      bind:value={fecha_v_2}
                      on:click={() => {
                        fecha_v_2 = "";
                      }}
                      on:change={(event) => {
                        fecha_v2 = "";
                        for (let w in fechas) {
                          if (fechas[w][1] == event.target.value) {
                            fecha_v2 = fechas[w][0];
                          }
                        }
                      }}
                      disabled={big_seaching ? "disabled" : ""}
                      class="mx-1"
                    />

                    <select
                      name="filtros"
                      bind:value={big_filtro}
                      id="filtros"
                      on:change={(event) => {
                        change_filtro(event.target.value);
                      }}
                    >
                      <option value="0">Posicion full</option>
                      <option value="1">Paquete 2</option>
                      <option value="2">Paquete 2 ACC T1</option>
                      <option value="3">Paquete 4</option>
                      <option value="4">ECM ACC (OBDII)</option>
                      <option value="5">ECM JP1/2 (1708)</option>
                      <option value="6">ECM JP1/2 (1939)</option>
                      <option value="8">ECM EC JP1/2 (1708)</option>
                      <option value="9">ECM EX JP1/2 (1939)</option>
                      <option value="7">Estatus general</option>
                    </select>

                    {#if secciones_l.length > 1 && big_filtro != 7}
                      <div>
                        <span class="ml-2">Seccion</span>
                        <div class="btn-group mx-2" role="group">
                          {#each secciones_l as secc}
                            <button
                              on:click|preventDefault={() => filtro_secc(secc)}
                              type="button"
                              class="btn {f_secc == secc
                                ? 'btn-info'
                                : 'btn-outline-info'}">{secc + 1}</button
                            >
                          {/each}
                        </div>
                      </div>
                    {/if}
                    {#if paginas_l.length > 0 && big_filtro != 7}
                      <div>
                        <span class="ml-2">Páginas</span>
                        <div class="btn-group mx-2" role="group">
                          {#each paginas_l as pag_n}
                            {#if pag_n >= pag_min && pag_n < pag_max}
                              <button
                                on:click|preventDefault={() => filtro(pag_n)}
                                type="button"
                                class="btn {f_pag == pag_n
                                  ? 'btn-info'
                                  : 'btn-outline-info'}">{pag_n + 1}</button
                              >
                            {/if}
                          {/each}
                        </div>
                      </div>
                    {/if}
                  </div>
                  {#if big_seaching}
                    <div
                      class="d-flex flex-column justify-content-center flex-grow-1 align-items-center"
                    >
                      <div
                        class="spinner-border text-danger"
                        style="width: 6rem; height: 6rem;"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  {:else}
                    <div class="overflow-auto h-100">
                      <table class="mdc-data-table__table" id="big_query_table">
                        <thead>
                          {#if big_filtro == 0}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Vel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sat</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fix</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Net</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Hdop</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Input</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Carrier</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Rssi</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 1}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">eCode</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col" style="font-size:0.8rem">eIndex</th>-->
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Vel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fix</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Net</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Hdop</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Input</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Rssi</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 2}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">eCode</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Accs</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">vExt</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">vInt</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Out0</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">In0</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Temp1</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Temp2</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Temp3</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 3}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Vel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fix</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Rssi</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Text Ascii</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Text bin</th
                              >
                              <!--<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Original</th>-->
                            </tr>
                          {:else if big_filtro == 4}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Road speed</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">RPM</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pedal Acel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Odometer</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fuel Lvl</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Hours</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pedal Brake</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Engine Hours</th
                              >
                            </tr>
                          {:else if big_filtro == 5 || big_filtro == 6}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Map ID</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Odometer</th
                              >
                              {#if big_filtro == 6}
                                <th
                                  class="mdc-data-table__header-cell"
                                  role="columnheader"
                                  scope="col"
                                  style="font-size:0.8rem">Odometer Hi</th
                                >
                              {/if}
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">RPM</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Road Speed</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Total Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Fuel</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Idle Hours</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Engine Hours</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">VIN</th
                              >
                            </tr>
                          {:else if big_filtro == 8 || big_filtro == 9}
                            <tr class="mdc-data-table__header-row">
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">SRV</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Tipo</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">F</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Sec</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Ing</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Pos</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Map ID</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Batt Voltage</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem"
                                >Batt Voltage Switched</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Temp</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Level</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Level</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Coolant Pressure</th
                              >
                              <th
                                class="mdc-data-table__header-cell"
                                role="columnheader"
                                scope="col"
                                style="font-size:0.8rem">Oil Pressure</th
                              >
                            </tr>
                          {/if}
                        </thead>
                        <tbody class="mdc-data-table__content">
                          {#each unit_data as item, i}
                            {#if big_filtro != 7 && ((big_filtro != 5 && big_filtro != 6 && big_filtro != 8 && big_filtro != 9) || item[1].substring(20, 22) == "05")}
                              <tr class="mdc-data-table__row">
                                <td
                                  class="mdc-data-table__cell"
                                  style="font-size:0.8rem"
                                >
                                  <a
                                    href={item[1]}
                                    on:click|preventDefault={() => {
                                      copy_full(item[1]);
                                    }}
                                  >
                                    {moment(new Date(item[0] * 1000)).format(
                                      "DD/MM/YY HH:mm:ss"
                                    )}
                                  </a>
                                </td>
                                <td
                                  class="mdc-data-table__cell"
                                  style="font-size:0.8rem"
                                >
                                  {item[1].substring(20, 22)}
                                </td>
                                {#if big_filtro == 0}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--vel-->
                                      {Math.round(
                                        parseInt(
                                          item[1].substring(66, 74),
                                          16
                                        ) / 27.7778
                                      )}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Sat-->
                                      {parseInt(item[1].substring(78, 80), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Fix-->
                                      <i
                                        tooltip={hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "No"
                                          : "Si"}
                                        class="material-icons md-90 {hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == '1' ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          '1'
                                          ? 'red'
                                          : ''}"
                                        >{hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "gps_off"
                                          : "gps_fixed"}</i
                                      >
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Net-->
                                      {hex2bin(item[1].substring(90, 92))[0] +
                                        hex2bin(item[1].substring(90, 92))[1] ==
                                      "00"
                                        ? "2G"
                                        : hex2bin(
                                            item[1].substring(90, 92)
                                          )[0] +
                                            hex2bin(
                                              item[1].substring(90, 92)
                                            )[1] ==
                                          "01"
                                        ? "3G"
                                        : hex2bin(
                                            item[1].substring(90, 92)
                                          )[0] +
                                            hex2bin(
                                              item[1].substring(90, 92)
                                            )[1] ==
                                          "10"
                                        ? "4G"
                                        : "?"}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--hdop-->
                                      {parseInt(item[1].substring(92, 94), 16) /
                                        10}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--inputs-->
                                      {hex2bin(item[1].substring(94, 96))}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--carrier-->
                                      {#if parseInt(item[1].substring(82, 86), 16) == "20" || parseInt(item[1].substring(82, 86), 16) == 50 || parseInt(item[1].substring(82, 86), 16) == "90" || parseInt(item[1].substring(82, 86), 16) == "40"}
                                        <span
                                          tooltip={parseInt(
                                            item[1].substring(82, 86),
                                            16
                                          )}
                                          ><img
                                            width="20"
                                            src={parseInt(
                                              item[1].substring(82, 86),
                                              16
                                            ) == "20"
                                              ? "https://www.telcel.com/favicon.ico"
                                              : parseInt(
                                                  item[1].substring(82, 86),
                                                  16
                                                ) == "50" ||
                                                parseInt(
                                                  item[1].substring(82, 86),
                                                  16
                                                ) == "90" ||
                                                parseInt(
                                                  item[1].substring(82, 86),
                                                  16
                                                ) == "40"
                                              ? "https://www.att.com.mx/assets/img/ico/favicon.ico"
                                              : ""}
                                            alt=""
                                          /></span
                                        >
                                      {:else}
                                        {parseInt(
                                          item[1].substring(82, 86),
                                          16
                                        )}
                                      {/if}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--rssi-->
                                      -{65535 -
                                        parseInt(
                                          item[1].substring(86, 90),
                                          16
                                        ) +
                                        1}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                  {/if}
                                {:else if big_filtro == 1}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem;word-break: break-all;"
                                    >
                                      <!--EventC-->
                                      {#if item[1].substring(20, 22) == "02"}
                                        {typeof event_map[
                                          item[1]
                                            .substring(100, 102)
                                            .toUpperCase()
                                        ] == "undefined"
                                          ? item[1].substring(100, 102)
                                          : "(" +
                                            item[1].substring(100, 102) +
                                            ") " +
                                            event_map[
                                              item[1]
                                                .substring(100, 102)
                                                .toUpperCase()
                                            ].substr(0, 15)}
                                      {/if}
                                    </td>
                                    <!--EventI-->
                                    <!--<td class="mdc-data-table__cell" style="font-size:0.8rem"> 
                                                                            {#if item[1].substring(20,22)=="02"}
                                                                                {parseInt(item[1].substring(98,100),16)}
                                                                            {/if}
                                                                        </td> -->
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--vel-->
                                      {Math.round(
                                        parseInt(
                                          item[1].substring(66, 74),
                                          16
                                        ) / 27.7778
                                      )}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Fix-->
                                      <i
                                        tooltip={hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "No"
                                          : "Si"}
                                        class="material-icons md-90 {hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == '1' ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          '1'
                                          ? 'red'
                                          : ''}"
                                        >{hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "gps_off"
                                          : "gps_fixed"}</i
                                      >
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Net-->
                                      {hex2bin(item[1].substring(90, 92))[0] +
                                        hex2bin(item[1].substring(90, 92))[1] ==
                                      "00"
                                        ? "2G"
                                        : hex2bin(
                                            item[1].substring(90, 92)
                                          )[0] +
                                            hex2bin(
                                              item[1].substring(90, 92)
                                            )[1] ==
                                          "01"
                                        ? "3G"
                                        : hex2bin(
                                            item[1].substring(90, 92)
                                          )[0] +
                                            hex2bin(
                                              item[1].substring(90, 92)
                                            )[1] ==
                                          "10"
                                        ? "4G"
                                        : "?"}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--hdop-->
                                      {parseInt(item[1].substring(92, 94), 16) /
                                        10}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--inputs-->
                                      {hex2bin(item[1].substring(94, 96))}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--rssi-->
                                      -{65535 -
                                        parseInt(
                                          item[1].substring(86, 90),
                                          16
                                        ) +
                                        1}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                  {/if}
                                {:else if big_filtro == 2}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--F -->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--EventC-->
                                      {#if item[1].substring(20, 22) == "02"}
                                        {item[1].substring(100, 102)}
                                      {/if}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Acc count-->
                                      {#if item[1].substring(20, 22) == "02"}
                                        {item[1].substring(102, 104)}
                                      {/if}
                                    </td>
                                    {#if item[1].substring(20, 22) == "02"}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 0 V EXT-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(106, 114),
                                            16
                                          ) / 1000}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(122, 130),
                                            16
                                          ) / 1000}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 1 V Int-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(114, 122),
                                            16
                                          ) / 1000}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(130, 138),
                                            16
                                          ) / 1000}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 2 out0-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {hex2bin(item[1].substring(124, 126))}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {hex2bin(item[1].substring(140, 142))}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc 2 in0-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {hex2bin(item[1].substring(128, 130))}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {hex2bin(item[1].substring(144, 146))}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc temp1-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(210, 218),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(210, 218),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        210,
                                                        218
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(210, 218),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(226, 234),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(226, 234),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        226,
                                                        234
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(226, 234),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc temp2-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(218, 226),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(218, 226),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        218,
                                                        226
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(218, 226),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(234, 242),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(234, 242),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        234,
                                                        242
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(234, 242),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--Acc temp3-->
                                        {#if item[1].substring(102, 104) == "10"}
                                          {parseInt(
                                            item[1].substring(226, 234),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(226, 234),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        226,
                                                        234
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(226, 234),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {:else if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            item[1].substring(242, 250),
                                            16
                                          ) != 15984
                                            ? parseInt(
                                                item[1].substring(242, 250),
                                                16
                                              ) > 2147483648
                                              ? Math.round(
                                                  (0xffffffff -
                                                    parseInt(
                                                      item[1].substring(
                                                        242,
                                                        250
                                                      ),
                                                      16
                                                    ) +
                                                    1) /
                                                    16
                                                ) * -1
                                              : Math.round(
                                                  parseInt(
                                                    item[1].substring(242, 250),
                                                    16
                                                  ) / 16
                                                )
                                            : "No"}
                                        {/if}
                                      </td>
                                    {:else}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 0 V EXT-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 1 V Int-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 2 out0-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc 2 in0-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc temp1-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc temp2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--Acc temp3-->
                                    {/if}
                                  {/if}
                                {:else if big_filtro == 3}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--vel-->
                                      {Math.round(
                                        parseInt(
                                          item[1].substring(66, 74),
                                          16
                                        ) / 27.7778
                                      )}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Fix-->
                                      <i
                                        tooltip={hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "No"
                                          : "Si"}
                                        class="material-icons md-90 {hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == '1' ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          '1'
                                          ? 'red'
                                          : ''}"
                                        >{hex2bin(
                                          item[1].substring(80, 82)
                                        )[4] == "1" ||
                                        hex2bin(item[1].substring(80, 82))[5] ==
                                          "1"
                                          ? "gps_off"
                                          : "gps_fixed"}</i
                                      >
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--rssi-->
                                      -{65535 -
                                        parseInt(
                                          item[1].substring(86, 90),
                                          16
                                        ) +
                                        1}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem;word-break: break-all;"
                                    >
                                      <!--Text Ascii-->
                                      {#if item[1].substring(20, 22) == "04"}
                                        {hex2a(item[1].substring(106))}
                                      {/if}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem;word-break: break-all;"
                                    >
                                      <!--Text bin-->
                                      {#if item[1].substring(20, 22) == "04"}
                                        {item[1].substring(106)}
                                      {/if}
                                    </td>
                                  {/if}
                                {:else if big_filtro == 4}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                    {#if item[1].substring(20, 22) == "02"}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--road_speed_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(274, 282),
                                              16
                                            ) * 0.036
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--rpm_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(282, 290),
                                              16
                                            ) / 10
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--pedal_acel_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(290, 298),
                                              16
                                            ) / 100
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--odometer_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(298, 306),
                                              16
                                            ) / 1000
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--fuel_level_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(306, 314),
                                              16
                                            ) / 100
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--engine_coolant_temp_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(314, 322),
                                              16
                                            ) / 16
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--eng_oil_temp_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {parseInt(
                                            parseInt(
                                              item[1].substring(322, 330),
                                              16
                                            ) / 16
                                          )}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_fuel_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(330, 338), 16) / 1000) != 2147483}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(330, 338),
                                                16
                                              ) / 1000
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_idle_hours_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(338, 346), 16) * 0.05) != 107374182}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(338, 346),
                                                16
                                              ) * 0.05
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_idle_fuel_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(346, 354), 16) * 0.5) != 1073741824}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(346, 354),
                                                16
                                              ) * 0.5
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--pedal_brake_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(362, 370), 16)) < 0x80000000}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(362, 370),
                                                16
                                              )
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      >
                                        <!--total_engine_hours_obd2-->
                                        {#if item[1].substring(102, 104) == "62"}
                                          {#if parseInt(parseInt(item[1].substring(370, 378), 16) * 0.05) != 107374182}
                                            {parseInt(
                                              parseInt(
                                                item[1].substring(370, 378),
                                                16
                                              ) * 0.05
                                            )}
                                          {:else}
                                            Err
                                          {/if}
                                        {/if}
                                      </td>
                                    {:else}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--road_speed_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--rpm_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--pedal_acel_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--odometer_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--fuel_level_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--engine_coolant_temp_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--eng_oil_temp_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_fuel_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_idle_hours_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_idle_fuel_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--pedal_brake_obd2-->
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <!--total_engine_hours_obd2-->
                                    {/if}
                                  {/if}
                                {:else if big_filtro == 5 || big_filtro == 6 || big_filtro == 8 || big_filtro == 9}
                                  {#if item[1].substring(20, 22) == "02" || item[1].substring(20, 22) == "04" || item[1].substring(20, 22) == "05"}
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--utime-->
                                      {moment(
                                        new Date(
                                          parseInt(
                                            item[1].substring(26, 34),
                                            16
                                          ) * 1000
                                        )
                                      ).format("DD/MM/YY HH:mm:ss")}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--sec-->
                                      {parseInt(item[1].substring(22, 26), 16)}
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--Ing-->
                                      <img
                                        width="30"
                                        src="img/{hex2bin(
                                          item[1].substring(94, 96)
                                        )[7] == '1'
                                          ? 'switch-on.svg'
                                          : 'switch-off.svg'}"
                                        alt=""
                                      />
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!--pos-->
                                      <a
                                        target="_blank"
                                        href="https://www.google.com.mx/maps/search/{parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}"
                                      >
                                        {parseInt(
                                          (parseInt(
                                            item[1].substring(42, 50),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(42, 50),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(42, 50),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000},{parseInt(
                                          (parseInt(
                                            item[1].substring(50, 58),
                                            16
                                          ) > 0x80000000
                                            ? ((0xffffffff -
                                                parseInt(
                                                  item[1].substring(50, 58),
                                                  16
                                                ) +
                                                1) *
                                                -1) /
                                              10000000
                                            : parseInt(
                                                item[1].substring(50, 58),
                                                16
                                              ) / 10000000) * 10000
                                        ) / 10000}
                                      </a>
                                    </td>
                                    <td
                                      class="mdc-data-table__cell"
                                      style="font-size:0.8rem"
                                    >
                                      <!-- MapID-->
                                      {#if item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8"}
                                        {#if item[1].substring(106, 108) == "5a"}
                                          <a
                                            on:click|preventDefault={() => {
                                              get_matrix_JP1(
                                                item[1].substring(106)
                                              );
                                            }}
                                            href="/"
                                            >{item[1].substring(106, 108)}</a
                                          >
                                        {:else}
                                          {item[1].substring(106, 108)}
                                        {/if}
                                      {/if}
                                    </td>
                                    {#if item[1].substring(20, 22) == "05"}
                                      {#if item[1].substring(98, 102) == "008e" || item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8"}
                                        {#if big_filtro == 5 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[0]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[3]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[4]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[5]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[6]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[7]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[9]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- vin-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[16]}
                                          </td>
                                        {:else if big_filtro == 6 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[0 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer hi-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[1 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[4 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[5 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[6 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[7 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[8 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[10 + 17]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem;word-break: break-all;"
                                          >
                                            <!-- vin-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[17 + 17]}
                                          </td>
                                        {:else if big_filtro == 8 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--Batt Voltage-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage_switched-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[2]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[10]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[11]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[12]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[13]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[14]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[15]}
                                          </td>
                                        {:else if big_filtro == 9 && item[1].substring(98, 102) == "008e" && item[1].substring(106, 108) == "05" && item[1].substring(114, 116) == "00"}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--Batt Voltage-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[1 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage_switched-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[2 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[10 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_temp-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[11 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[12 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[13 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[14 + 18]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5(
                                              item[1].substring(106),
                                              parseInt(
                                                item[1].substring(102, 106),
                                                16
                                              )
                                            )[15 + 18]}
                                          </td>
                                        {/if}
                                        {#if big_filtro == 5 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[0]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[2]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[3]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[4]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[5]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[6]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- vin-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[7]}
                                          </td>
                                        {:else if big_filtro == 6 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[0]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- odometer Hi-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- rpm-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[2]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- road speed-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[3]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[4]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle fuel-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[5]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total idle hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[6]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- total engine hours-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[7]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- vin-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[8]}
                                          </td>
                                        {:else if big_filtro == 8 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[8]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- batt_voltage_switched-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[9]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- coolant_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[10]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- oil_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[11]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[12]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[13]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[14]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1708"
                                            )[15]}
                                          </td>
                                        {:else if big_filtro == 9 && (item[1].substring(98, 102) == "0082" || item[1].substring(98, 102) == "00c8")}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--batt_voltage-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[8 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- batt_voltage_switched-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[9 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- coolant_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[10 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!-- oil_temp-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[11 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[12 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_level-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[13 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--coolant_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[14 + 1]}
                                          </td>
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          >
                                            <!--oil_pressure-->
                                            {get_5_JP1(
                                              item[1].substring(106),
                                              "1939"
                                            )[15 + 1]}
                                          </td>
                                        {/if}
                                      {:else}
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                        {#if big_filtro == 6}
                                          <td
                                            class="mdc-data-table__cell"
                                            style="font-size:0.8rem"
                                          />
                                        {/if}
                                      {/if}
                                    {:else}
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem"
                                      />
                                      {#if big_filtro == 6}
                                        <td
                                          class="mdc-data-table__cell"
                                          style="font-size:0.8rem"
                                        />
                                      {/if}
                                    {/if}
                                  {/if}
                                {/if}
                              </tr>
                            {/if}
                          {/each}
                        </tbody>
                      </table>
                      {#if big_filtro == 7}
                        <div
                          class="col-md h-100 w-100"
                          style="padding:0"
                          use:initMap
                        >
                          <div class="row h-50 no-gutters w-100">
                            <div class="col-md-6 h-100 w-100">
                              <div class="h-100 w-100" id="map" />
                            </div>
                            <div class="col-md-6 h-100 d-flex overflow-auto">
                              <div
                                class="mdc-data-table w-100 h-100"
                                style="display:flex"
                                use:data_table_on
                              >
                                <table
                                  class="mdc-data-table__table h-100"
                                  id="travels_table"
                                >
                                  <thead>
                                    <tr
                                      class="mdc-data-table__header-row"
                                      style="height: 1px;"
                                    >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Datos PULS</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col"
                                      />
                                    </tr>
                                  </thead>
                                  <tbody class="mdc-data-table__content">
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Reporte
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong
                                          >{puls_info.lastIdtime != ""
                                            ? moment(
                                                puls_info.lastIdtime
                                              ).format("DD-MM-YYYY HH:mm")
                                            : ""}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Script
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong
                                          >{puls_info.scriptVersion}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Configuración
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong
                                          >{puls_info.configVersion}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Firmware
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong>{puls_info.firmware}</strong>
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Hardware
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong>{puls_info.firmware2}</strong>
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        SIM IMSI
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        {#if puls_info.imsi.substring(0, 3) == "310" || puls_info.imsi.substring(0, 3) == "334"}
                                          <img
                                            src={puls_info.imsi.substring(
                                              0,
                                              3
                                            ) == "310"
                                              ? "img/usa-flag.png"
                                              : "img/mex-flag.png"}
                                            alt="flag"
                                            width="20"
                                          />
                                          <img
                                            src={puls_info.imsi.substring(
                                              3,
                                              6
                                            ) == "410"
                                              ? "img/att.ico"
                                              : "img/telcel.ico"}
                                            alt="flag"
                                            width="20"
                                          />
                                        {/if}
                                        <strong>{puls_info.imsi}</strong>
                                        <strong
                                          >{puls_info.imsi.substring(3, 6) ==
                                          "410"
                                            ? "(AT&T)"
                                            : puls_info.imsi.substring(3, 6) ==
                                              "020"
                                            ? "(Telcel)"
                                            : ""}</strong
                                        >
                                      </td>
                                    </tr>
                                    <tr
                                      class="mdc-data-table__row"
                                      style="height: 1px;"
                                    >
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        ICCID
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        <strong>{puls_info.iccid}</strong>
                                      </td>
                                    </tr>
                                    <tr class="mdc-data-table__row flex-grow-1">
                                      <td
                                        class="mdc-data-table__cell"
                                        style="font-size:0.8rem;"
                                      >
                                        Descripción
                                      </td>
                                      <td
                                        class="mdc-data-table__cell"
                                        style="word-break: break-all;font-size:0.8rem;"
                                      >
                                        {puls_info.partInfo}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="row h-50 no-gutters overflow-auto">
                            <div
                              class="col-md-12 h-100  d-flex flex-column border border-dark"
                            >
                              <div
                                class="mdc-data-table"
                                style="display:flex"
                                use:data_table_on
                              >
                                <table
                                  class="mdc-data-table__table"
                                  id="travels_table"
                                >
                                  <thead>
                                    <tr class="mdc-data-table__header-row">
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Cliente</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Equipo</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Servicio</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Fecha</th
                                      >
                                      <th
                                        style="display:none;"
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">PARTE</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Instalada</th
                                      >
                                      <th
                                        class="mdc-data-table__header-cell"
                                        role="columnheader"
                                        scope="col">Retirada</th
                                      >
                                    </tr>
                                  </thead>
                                  {#if !cas_info_runing}
                                    <tbody class="mdc-data-table__content">
                                      {#each cas_info as item, i}
                                        <tr
                                          class="mdc-data-table__row"
                                          style="height:1px"
                                        >
                                          <td class="mdc-data-table__cell">
                                            {#if typeof item[7] != "undefined"}
                                              <a
                                                href="http://www.casomnitracs.mx/contenido/ajax_detalle_servicio_pdf.php?{item[7]}"
                                                target="_blank">{item[0]}</a
                                              >
                                            {:else}
                                              {item[0]}
                                            {/if}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[1]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[2]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {moment(
                                              new Date(item[3] * 1000)
                                            ).format("DD/MM/YYYY")}
                                          </td>
                                          <td
                                            style="display:none;"
                                            class="mdc-data-table__cell"
                                          >
                                            {item[4]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[5]}
                                          </td>
                                          <td class="mdc-data-table__cell">
                                            {item[6]}
                                          </td>
                                        </tr>
                                      {/each}
                                    </tbody>
                                  {/if}
                                </table>
                              </div>
                              {#if cas_info_runing}
                                <div
                                  class="d-flex flex-column justify-content-center  flex-grow-1 align-items-center"
                                >
                                  <div
                                    class="spinner-border text-danger"
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  <div class="m-3">
                                    Descargando datos de CAS...
                                  </div>
                                </div>
                              {/if}
                            </div>
                          </div>
                        </div>
                      {/if}
                    </div>
                  {/if}
                </div>
              {/if}
              {#if selected == 8}
                <div
                  class="mdc-data-table"
                  style="display:flex"
                  use:data_table_on
                >
                  <table class="mdc-data-table__table" id="travels_table">
                    <thead>
                      <tr class="mdc-data-table__header-row">
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">PC ID</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Status</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">IP</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Pais</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">ISP</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Pantalla</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Version</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Usuario</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Grupo</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Primera conexion</th
                        >
                        <th
                          class="mdc-data-table__header-cell"
                          role="columnheader"
                          scope="col">Ultima conexion</th
                        >
                      </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                      {#each sessions as item, i}
                        {#if (item.status == 1 && f_sesion == 1) || f_sesion == 0}
                          <tr class="mdc-data-table__row">
                            <td class="mdc-data-table__cell">
                              {item.finger}
                            </td>
                            <td class="mdc-data-table__cell">
                              <i
                                tooltip={item.status == 0
                                  ? "Offline"
                                  : "Online"}
                                class="material-icons md-90 {item.status == 0
                                  ? 'red'
                                  : 'green'}">brightness_1</i
                              >
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.ip}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.ip_data.pais}
                            </td>
                            <td
                              class="mdc-data-table__cell"
                              style="word-break: break-all;"
                            >
                              {item.ip_data.isp}
                            </td>
                            <td class="mdc-data-table__cell">
                              {typeof item.screen_size != "undefined"
                                ? item.screen_size != null
                                  ? item.screen_size
                                  : ""
                                : ""}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.version}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.user_name}
                            </td>
                            <td class="mdc-data-table__cell">
                              {item.group}
                            </td>
                            <td class="mdc-data-table__cell">
                              {moment(
                                new Date(parseInt(item.first * 1000))
                              ).format("DD/MM/YY HH:mm")}
                            </td>
                            <td class="mdc-data-table__cell">
                              {moment(
                                new Date(parseInt(item.last * 1000))
                              ).format("DD/MM/YY HH:mm")}
                            </td>
                          </tr>
                        {/if}
                      {/each}
                    </tbody>
                  </table>
                </div>
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  .mdc-data-table__row {
    height: 45px;
  }
  .mdc-data-table__header-row {
    height: 45px;
  }
  .mdc-chip__text:focus {
    outline: unset;
  }
  .mdc-chip__primary-action:focus {
    outline: unset;
  }
  .mdc-icon-button {
    width: 24px;
    height: 24px;
    padding: 0px;
  }
  .mdc-data-table__table {
    white-space: normal;
  }

  .btn {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }

  .btn.big {
    font-size: 1rem;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }

  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
