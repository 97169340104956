<script>
  export let units_travel_db, socket_query, vins_db, ecm_db;
  import { onMount } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
  } from "./stores.js";

  let key,
    nicks_,
    nicks = {};
  let parametros = new URLSearchParams(window.location.search);
  key = parametros.get("key");
  nicks_ = parametros.get("nicks");

  if (key != null) {
    //embed_dtc_worker.postMessage({type:"save",key:"embedded_key",message:key});
    /*
        socket_query.emit('embedded_key',key,(data)=> {
            if(data=="error"){
                alert("Login incorrecto");
            }
        });
        */
  }
  var chart1 = {
    type: "line",
    data: {
      datasets: [],
    },
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              offsetGridLines: false,
            },
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM D",
                week: "[Week] W",
              },
              isoWeekday: true,
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            ticks: {
              beginAtZero: false,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Kilómetros",
            },
            offset: true,
          },
          {
            type: "linear",
            display: false,
            position: "right",
            id: "y-axis-2",
            ticks: {
              beginAtZero: false,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Kilómetros",
            },
            offset: true,
          },
        ],
      },
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Total Odometer",
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
  };

  var chart2 = {
    type: "line",
    data: {
      datasets: [],
    },
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              offsetGridLines: false,
            },
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM D",
                week: "[Week] W",
              },
              isoWeekday: true,
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Litros",
            },
            offset: true,
          },
        ],
      },
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Total Fuel",
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
  };

  var chart3 = {
    type: "line",
    data: {
      datasets: [],
    },
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              offsetGridLines: false,
            },
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM D",
                week: "[Week] W",
              },
              isoWeekday: true,
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Litros",
            },
            offset: true,
          },
        ],
      },
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Total Idle Fuel",
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
  };

  var chart4 = {
    type: "line",
    data: {
      datasets: [],
    },
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              offsetGridLines: false,
            },
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM D",
                week: "[Week] W",
              },
              isoWeekday: true,
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            ticks: {
              beginAtZero: false,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Horas",
            },
            offset: true,
          },
          {
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-2",
            ticks: {
              beginAtZero: false,
              callback: function (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Horas Ocioso",
            },
            offset: true,
          },
        ],
      },
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Total engine hours",
      },
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    },
  };

  let chart_1, chart_2, chart_3, chart_4;
  onMount(() => {
    chart_1 = new Chart(
      document.getElementById("chart1").getContext("2d"),
      chart1
    );
    chart_2 = new Chart(
      document.getElementById("chart2").getContext("2d"),
      chart2
    );
    chart_3 = new Chart(
      document.getElementById("chart3").getContext("2d"),
      chart3
    );
    chart_4 = new Chart(
      document.getElementById("chart4").getContext("2d"),
      chart4
    );
  });

  let units_list_p;
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  let selected = -1;
  let motor = {
    "X15, Signature ":
      "https://latin-america.internationalcamiones.com/-/media/navistar/latin-america/spotlight/engines/smaller_isx_updated.jpg?h=420&w=630&la=es-PA&hash=75A88B7FEF6C10235030915FEC8BB32C5BF89840",
    "Detroit Series 60":
      "https://static.turbosquid.com/Preview/001293/699/OA/_Z.jpg",
    "Detroit Diesel DD15":
      "https://demanddetroit.com/images/calculator/dd15.png",
  };
  let vin_data = {
    vin: "3HSDJAPT4JN427637",
    make: "INTERNATIONAL",
    model: "LF687",
    year: "2018",
    series: "ProStar+",
    engine: "X15, Signature ",
  };
  vin_data = { vin: "", make: "", model: "", year: "", series: "", engine: "" };
  let ecm_d = {};

  async function maping(unit_s) {
    selected = unit_s;
    let vin_data_;
    try {
      vin_data_ = JSON.parse(await vins_db.getItem(unit_s));
    } catch (e) {
      console.log("Error leyendo vins", e);
    }
    vin_data = !vin_data_
      ? { vin: "", make: "", model: "", year: "", series: "", engine: "" }
      : vin_data_;

    let ecm_d_;
    try {
      ecm_d_ = JSON.parse(await ecm_db.getItem(unit_s));
    } catch (e) {
      console.log("Error leyendo ecm", e);
    }
    ecm_d = !ecm_d_
      ? {
          odometer_1708: [],
          odometer_1939: [],
          odometer_hi_1939: [],
          total_fuel_1708: [],
          total_fuel_1939: [],
          total_idle_fuel_1708: [],
          total_idle_fuel_1939: [],
          total_idle_hours_1708: [],
          total_idle_hours_1939: [],
          total_engine_hours_1708: [],
          total_engine_hours_1939: [],
          odometer_obd2: [],
          total_fuel_obd2: [],
          total_idle_hours_obd2: [],
          total_idle_fuel_obd2: [],
          total_engine_hours_obd2: [],
        }
      : ecm_d_;

    if (typeof ecm_d.odometer_obd2 == "undefined") {
      ecm_d.odometer_obd2 = [];
      ecm_d.total_fuel_obd2 = [];
      ecm_d.total_idle_hours_obd2 = [];
      ecm_d.total_idle_fuel_obd2 = [];
      ecm_d.total_engine_hours_obd2 = [];
      ecm_d.last_status = [0, 0, 0];
    }

    let ecm_d_out = {};
    let ecm_d_out_day = {};
    let ecm_d_out_week = {};
    let day_ini = "";
    let week_ini = "";
    let time_base, data_base;
    let time_inicio = moment().subtract(f_time, "days").format("X");
    for (let y in ecm_d) {
      ecm_d_out[y] = [];
      day_ini = "";
      ecm_d_out_day[y] = [];
      ecm_d_out_week[y] = [];
      for (let x in ecm_d[y]) {
        if (x == 0) {
          time_base = ecm_d[y][x][1];
          data_base = ecm_d[y][x][0];
          if (time_inicio < time_base * 60)
            ecm_d_out[y].push({
              x: moment(time_base * 60 * 1000).toDate(),
              y: data_base,
            });

          // Day
          if (
            day_ini != moment(time_base * 60 * 1000).format("DDD") &&
            time_inicio < time_base * 60
          ) {
            ecm_d_out_day[y].push({
              x: moment(time_base * 60 * 1000)
                .startOf("day")
                .toDate(),
              y: data_base,
            });
            day_ini = moment(time_base * 60 * 1000).format("DDD");
          } else if (time_inicio < time_base * 60)
            ecm_d_out_day[y][ecm_d_out_day[y].length - 1] = {
              x: moment(time_base * 60 * 1000)
                .startOf("day")
                .toDate(),
              y: data_base,
            };

          // Week
          if (
            week_ini != moment(time_base * 60 * 1000).format("W") &&
            time_inicio < time_base * 60
          ) {
            ecm_d_out_week[y].push({
              x: moment(time_base * 60 * 1000)
                .startOf("isoWeek")
                .toDate(),
              y: data_base,
            });
            week_ini = moment(time_base * 60 * 1000).format("W");
          } else if (time_inicio < time_base * 60)
            ecm_d_out_week[y][ecm_d_out_week[y].length - 1] = {
              x: moment(time_base * 60 * 1000)
                .startOf("isoWeek")
                .toDate(),
              y: data_base,
            };
        } else {
          if (time_inicio < (ecm_d[y][x][1] + time_base) * 60)
            ecm_d_out[y].push({
              x: moment((ecm_d[y][x][1] + time_base) * 60 * 1000).toDate(),
              y: ecm_d[y][x][0] + data_base,
            });

          //Day
          if (
            day_ini !=
              moment((ecm_d[y][x][1] + time_base) * 60 * 1000).format("DDD") &&
            time_inicio < (ecm_d[y][x][1] + time_base) * 60
          ) {
            ecm_d_out_day[y].push({
              x: moment((ecm_d[y][x][1] + time_base) * 60 * 1000)
                .startOf("day")
                .toDate(),
              y: ecm_d[y][x][0] + data_base,
            });
            day_ini = moment((ecm_d[y][x][1] + time_base) * 60 * 1000).format(
              "DDD"
            );
          } else if (time_inicio < (ecm_d[y][x][1] + time_base) * 60)
            ecm_d_out_day[y][ecm_d_out_day[y].length - 1] = {
              x: moment((ecm_d[y][x][1] + time_base) * 60 * 1000)
                .startOf("day")
                .toDate(),
              y: ecm_d[y][x][0] + data_base,
            };

          //Week
          if (
            week_ini !=
              moment((ecm_d[y][x][1] + time_base) * 60 * 1000).format("W") &&
            time_inicio < (ecm_d[y][x][1] + time_base) * 60
          ) {
            ecm_d_out_week[y].push({
              x: moment((ecm_d[y][x][1] + time_base) * 60 * 1000)
                .startOf("isoWeek")
                .toDate(),
              y: ecm_d[y][x][0] + data_base,
            });
            week_ini = moment((ecm_d[y][x][1] + time_base) * 60 * 1000).format(
              "W"
            );
          } else if (time_inicio < (ecm_d[y][x][1] + time_base) * 60)
            ecm_d_out_week[y][ecm_d_out_week[y].length - 1] = {
              x: moment((ecm_d[y][x][1] + time_base) * 60 * 1000)
                .startOf("isoWeek")
                .toDate(),
              y: ecm_d[y][x][0] + data_base,
            };
        }
      }
    }
    if (f_dias == 1) ecm_d_out = ecm_d_out_day;
    if (f_semana == 1) ecm_d_out = ecm_d_out_week;

    // Post-Calculados
    ecm_d_out["rend_1708"] = [];
    ecm_d_out["rend_1939"] = [];
    ecm_d_out["delta_1708"] = [];
    ecm_d_out["delta_1939"] = [];
    ecm_d_out["delta_fuel_1708"] = [];
    ecm_d_out["delta_fuel_1939"] = [];
    ecm_d_out["delta_idle_fuel_1708"] = [];
    ecm_d_out["delta_idle_fuel_1939"] = [];
    ecm_d_out["delta_engine_hours_1708"] = [];
    ecm_d_out["delta_engine_hours_1939"] = [];
    ecm_d_out["delta_idle_hours_1708"] = [];
    ecm_d_out["delta_idle_hours_1939"] = [];
    if (f_calculados == 1) {
      for (let z in ecm_d_out.odometer_1708) { // rendimiento 1708
        if (z > 0) {
          for (let w in ecm_d_out.total_fuel_1708) {
            if (
              ecm_d_out.odometer_1708[z].x.getTime() ==
              ecm_d_out.total_fuel_1708[w].x.getTime()
            ) {
              for (let q in ecm_d_out.total_fuel_1708) {
                if (
                  ecm_d_out.odometer_1708[z - 1].x.getTime() ==
                  ecm_d_out.total_fuel_1708[q].x.getTime()
                ) {
                  ecm_d_out.delta_1708.push({
                    x: ecm_d_out.odometer_1708[z].x,
                    y:
                      ecm_d_out.odometer_1708[z].y -
                      ecm_d_out.odometer_1708[z - 1].y,
                  });

                  ecm_d_out.rend_1708.push({
                    x: ecm_d_out.odometer_1708[z].x,
                    y:
                      Math.round(
                        ((ecm_d_out.odometer_1708[z].y -
                          ecm_d_out.odometer_1708[z - 1].y) /
                          (ecm_d_out.total_fuel_1708[w].y -
                            ecm_d_out.total_fuel_1708[q].y)) *
                          100
                      ) / 100,
                  });
                  break;
                }
              }
            }
          }
        }
      }
      for (let z in ecm_d_out.odometer_1939) { // rendimiento 1939
        if (z > 0) {
          for (let w in ecm_d_out.total_fuel_1939) {
            if (
              ecm_d_out.odometer_1939[z].x.getTime() ==
              ecm_d_out.total_fuel_1939[w].x.getTime()
            ) {
              for (let q in ecm_d_out.total_fuel_1939) {
                if (
                  ecm_d_out.odometer_1939[z - 1].x.getTime() ==
                  ecm_d_out.total_fuel_1939[q].x.getTime()
                ) {
                  ecm_d_out.delta_1939.push({
                    x: ecm_d_out.odometer_1939[z].x,
                    y:
                      ecm_d_out.odometer_1939[z].y -
                      ecm_d_out.odometer_1939[z - 1].y,
                  });

                  ecm_d_out.rend_1939.push({
                    x: ecm_d_out.odometer_1939[z].x,
                    y:
                      Math.round(
                        ((ecm_d_out.odometer_1939[z].y -
                          ecm_d_out.odometer_1939[z - 1].y) /
                          (ecm_d_out.total_fuel_1939[w].y -
                            ecm_d_out.total_fuel_1939[q].y)) *
                          100
                      ) / 100,
                  });
                  break;
                }
              }
            }
          }
        }
      }
      for (let z in ecm_d_out.total_fuel_1708) {
        if (z > 0) {
          ecm_d_out.delta_fuel_1708.push({
            x: ecm_d_out.total_fuel_1708[z].x,
            y:
              ecm_d_out.total_fuel_1708[z].y -
              ecm_d_out.total_fuel_1708[z - 1].y,
          });
        }
      }
      for (let z in ecm_d_out.total_fuel_1939) {
        if (z > 0) {
          ecm_d_out.delta_fuel_1939.push({
            x: ecm_d_out.total_fuel_1939[z].x,
            y:
              ecm_d_out.total_fuel_1939[z].y -
              ecm_d_out.total_fuel_1939[z - 1].y,
          });
        }
      }
      for (let z in ecm_d_out.total_idle_fuel_1708) {
        if (z > 0) {
          ecm_d_out.delta_idle_fuel_1708.push({
            x: ecm_d_out.total_idle_fuel_1708[z].x,
            y:
              ecm_d_out.total_idle_fuel_1708[z].y -
              ecm_d_out.total_idle_fuel_1708[z - 1].y,
          });
        }
      }
      for (let z in ecm_d_out.total_idle_fuel_1939) {
        if (z > 0) {
          ecm_d_out.delta_idle_fuel_1939.push({
            x: ecm_d_out.total_idle_fuel_1939[z].x,
            y:
              ecm_d_out.total_idle_fuel_1939[z].y -
              ecm_d_out.total_idle_fuel_1939[z - 1].y,
          });
        }
      }
      for (let z in ecm_d_out.total_engine_hours_1708) {
        if (z > 0) {
          ecm_d_out.delta_engine_hours_1708.push({
            x: ecm_d_out.total_engine_hours_1708[z].x,
            y:
              ecm_d_out.total_engine_hours_1708[z].y -
              ecm_d_out.total_engine_hours_1708[z - 1].y,
          });
        }
      }
      for (let z in ecm_d_out.total_engine_hours_1939) {
        if (z > 0) {
          ecm_d_out.delta_engine_hours_1939.push({
            x: ecm_d_out.total_engine_hours_1939[z].x,
            y:
              ecm_d_out.total_engine_hours_1939[z].y -
              ecm_d_out.total_engine_hours_1939[z - 1].y,
          });
        }
      }
      for (let z in ecm_d_out.total_idle_hours_1708) {
        if (z > 0) {
          ecm_d_out.delta_idle_hours_1708.push({
            x: ecm_d_out.total_idle_hours_1708[z].x,
            y:
              ecm_d_out.total_idle_hours_1708[z].y -
              ecm_d_out.total_idle_hours_1708[z - 1].y,
          });
        }
      }
      for (let z in ecm_d_out.total_idle_hours_1939) {
        if (z > 0) {
          ecm_d_out.delta_idle_hours_1939.push({
            x: ecm_d_out.total_idle_hours_1939[z].x,
            y:
              ecm_d_out.total_idle_hours_1939[z].y -
              ecm_d_out.total_idle_hours_1939[z - 1].y,
          });
        }
      }
    }

    chart_1.data.datasets = [];
    chart_2.data.datasets = [];
    chart_3.data.datasets = [];
    chart_4.data.datasets = [];

    if (f_calculados == 0) {
      if (ecm_d_out.odometer_1708.length > 0 && f_j1708 == 1)
        chart_1.data.datasets.push({
          label: "Odometro (1708)",
          data: ecm_d_out.odometer_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.odometer_1939.length > 0 && f_j1939 == 1)
        chart_1.data.datasets.push({
          label: "Odometro (1939)",
          data: ecm_d_out.odometer_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.odometer_hi_1939.length > 0 && f_j1939 == 1)
        chart_1.data.datasets.push({
          label: "Odometro (1939-Hi)",
          data: ecm_d_out.odometer_hi_1939,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgb(75, 192, 192)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.odometer_obd2.length > 0 && f_obd2 == 1)
        chart_1.data.datasets.push({
          label: "Odometro (OBD2)",
          data: ecm_d_out.odometer_obd2,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgb(255, 205, 86)",
          fill: false,
          pointRadius: 0,
        });

      if (ecm_d_out.total_fuel_1708.length > 0 && f_j1708 == 1)
        chart_2.data.datasets.push({
          label: "Combustible (1708)",
          data: ecm_d_out.total_fuel_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.total_fuel_1939.length > 0 && f_j1939 == 1)
        chart_2.data.datasets.push({
          label: "Combustible (1939)",
          data: ecm_d_out.total_fuel_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.total_fuel_obd2.length > 0 && f_obd2 == 1)
        chart_2.data.datasets.push({
          label: "Combustible (OBD2)",
          data: ecm_d_out.total_fuel_obd2,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgb(255, 205, 86)",
          fill: false,
          pointRadius: 0,
        });

      if (ecm_d_out.total_idle_fuel_1708.length > 0 && f_j1708 == 1)
        chart_3.data.datasets.push({
          label: "Combustible Ocioso (1708)",
          data: ecm_d_out.total_idle_fuel_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.total_idle_fuel_1939.length > 0 && f_j1939 == 1)
        chart_3.data.datasets.push({
          label: "Combustible Ocioso (1939)",
          data: ecm_d_out.total_idle_fuel_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.total_idle_fuel_obd2.length > 0 && f_obd2 == 1)
        chart_3.data.datasets.push({
          label: "Combustible Ocioso (OBD2)",
          data: ecm_d_out.total_idle_fuel_obd2,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgb(255, 205, 86)",
          fill: false,
          pointRadius: 0,
        });

      if (ecm_d_out.total_idle_hours_1708.length > 0 && f_j1708 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor Ocioso (1708)",
          type: "line",
          data: ecm_d_out.total_idle_hours_1708,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgb(75, 192, 192)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-2",
        });
      if (ecm_d_out.total_idle_hours_1939.length > 0 && f_j1939 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor Ocioso (1939)",
          type: "line",
          data: ecm_d_out.total_idle_hours_1939,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgb(255, 205, 86)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-2",
        });
      if (ecm_d_out.total_idle_hours_obd2.length > 0 && f_obd2 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor Ocioso (OBD2)",
          type: "line",
          data: ecm_d_out.total_idle_hours_obd2,
          borderColor: "rgb(153, 102, 255)",
          backgroundColor: "rgb(153, 102, 255)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-2",
        });

      if (ecm_d_out.total_engine_hours_1708.length > 0 && f_j1708 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor (1708)",
          data: ecm_d_out.total_engine_hours_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-1",
        });
      if (ecm_d_out.total_engine_hours_1939.length > 0 && f_j1939 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor (1939)",
          data: ecm_d_out.total_engine_hours_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-1",
        });
      if (ecm_d_out.total_engine_hours_obd2.length > 0 && f_obd2 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor (OBD2)",
          data: ecm_d_out.total_engine_hours_obd2,
          borderColor: "rgb(255, 159, 64)",
          backgroundColor: "rgb(255, 159, 64)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-1",
        });
    } else {
      if (ecm_d_out.delta_1708.length > 0 && f_j1708 == 1)
        chart_1.data.datasets.push({
          label: "Distancia (1708)",
          type: "line",
          data: ecm_d_out.delta_1708,
          borderColor: "rgb(153, 102, 255)",
          backgroundColor: "rgb(153, 102, 255)",
          fill: false,
          pointRadius: 3,
          borderWidth: 2,
          yAxisID: "y-axis-2",
          lineTension: 0,
          borderDash: [5, 5],
        });
      if (ecm_d_out.delta_1939.length > 0 && f_j1939 == 1)
        chart_1.data.datasets.push({
          label: "Distancia (1939)",
          type: "line",
          data: ecm_d_out.delta_1939,
          borderColor: "rgb(255, 159, 64)",
          backgroundColor: "rgb(255, 159, 64)",
          fill: false,
          pointRadius: 3,
          borderWidth: 2,
          yAxisID: "y-axis-2",
          lineTension: 0,
          borderDash: [5, 5],
        });
      if (ecm_d_out.rend_1708.length > 0 && f_j1708 == 1)
        chart_1.data.datasets.push({
          label: "Rendimiento (1708)",
          data: ecm_d_out.rend_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-1",
        });
      if (ecm_d_out.rend_1939.length > 0 && f_j1939 == 1)
        chart_1.data.datasets.push({
          label: "Rendimiento (1939)",
          data: ecm_d_out.rend_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-1",
        });

      if (ecm_d_out.delta_fuel_1708.length > 0 && f_j1708 == 1)
        chart_2.data.datasets.push({
          label: "Combustible (1708)",
          data: ecm_d_out.delta_fuel_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.delta_fuel_1939.length > 0 && f_j1939 == 1)
        chart_2.data.datasets.push({
          label: "Combustible (1939)",
          data: ecm_d_out.delta_fuel_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
        });

      if (ecm_d_out.delta_idle_fuel_1708.length > 0 && f_j1708 == 1)
        chart_3.data.datasets.push({
          label: "Combustible Ocioso (1708)",
          data: ecm_d_out.delta_idle_fuel_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
        });
      if (ecm_d_out.delta_idle_fuel_1939.length > 0 && f_j1939 == 1)
        chart_3.data.datasets.push({
          label: "Combustible Ocioso (1939)",
          data: ecm_d_out.delta_idle_fuel_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
        });

      if (ecm_d_out.delta_idle_hours_1708.length > 0 && f_j1708 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor Ocioso (1708)",
          type: "line",
          data: ecm_d_out.delta_idle_hours_1708,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgb(75, 192, 192)",
          fill: false,
          pointRadius: 3,
          borderWidth: 2,
          yAxisID: "y-axis-2",
          lineTension: 0,
          borderDash: [5, 5],
        });
      if (ecm_d_out.delta_idle_hours_1939.length > 0 && f_j1939 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor Ocioso (1939)",
          type: "line",
          data: ecm_d_out.delta_idle_hours_1939,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgb(255, 205, 86)",
          fill: false,
          pointRadius: 3,
          borderWidth: 2,
          yAxisID: "y-axis-2",
          lineTension: 0,
          borderDash: [5, 5],
        });

      if (ecm_d_out.delta_engine_hours_1708.length > 0 && f_j1708 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor (1708)",
          data: ecm_d_out.delta_engine_hours_1708,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgb(54, 162, 235)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-1",
        });
      if (ecm_d_out.delta_engine_hours_1939.length > 0 && f_j1939 == 1)
        chart_4.data.datasets.push({
          label: "Horas Motor (1939)",
          data: ecm_d_out.delta_engine_hours_1939,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          fill: false,
          pointRadius: 0,
          yAxisID: "y-axis-1",
        });
    }
    chart_1.update();
    chart_2.update();
    chart_3.update();
    chart_4.update();
  }
  let f_dias = 0,
    f_semana = 0,
    f_historico = 1,
    f_totales = 1,
    f_calculados = 0,
    f_j1939 = 1,
    f_j1708 = 1,
    f_obd2 = 0,
    f_t_week = 0,
    f_t_2week = 0,
    f_t_4week = 1,
    f_t_8week = 0,
    f_t_12week = 0;
  let f_time = 30; // 1 mes
  function filtro(fi) {
    if (fi == 0 || fi == 4) {
      f_dias = 1;
      f_semana = f_dias == 1 ? 0 : f_semana;
      f_historico = f_dias == 1 ? 0 : 1;
    }
    if (fi == 1) {
      f_semana = 1;
      f_dias = f_semana == 1 ? 0 : f_dias;
      f_historico = f_semana == 1 ? 0 : 1;
    }
    if (fi == 2) {
      f_historico = 1;
      f_dias = f_historico == 1 ? 0 : f_dias;
      f_semana = f_historico == 1 ? 0 : f_semana;
    }
    if (fi == 3) {
      f_totales = 1;
      f_calculados = f_totales == 1 ? 0 : 1;
    }
    if (fi == 4) {
      f_calculados = 1;
      f_totales = f_calculados == 1 ? 0 : 1;
    }
    if (fi == 5) {
      f_j1708 = f_j1708 == 1 ? 0 : 1;
      f_obd2 = f_j1708 == 1 ? 0 : f_obd2;
    }
    if (fi == 6) {
      f_j1939 = f_j1939 == 1 ? 0 : 1;
      f_obd2 = f_j1939 == 1 ? 0 : f_obd2;
    }
    if (fi == 7) {
      f_t_week = 1;
      f_t_2week = 0;
      f_t_4week = 0;
      f_t_8week = 0;
      f_t_12week = 0;
      f_time = 7;
    }
    if (fi == 8) {
      f_t_week = 0;
      f_t_2week = 1;
      f_t_4week = 0;
      f_t_8week = 0;
      f_t_12week = 0;
      f_time = 14;
    }
    if (fi == 9) {
      f_t_week = 0;
      f_t_2week = 0;
      f_t_4week = 1;
      f_t_8week = 0;
      f_t_12week = 0;
      f_time = 30;
    }
    if (fi == 10) {
      f_t_week = 0;
      f_t_2week = 0;
      f_t_4week = 0;
      f_t_8week = 1;
      f_t_12week = 0;
      f_time = 60;
    }
    if (fi == 11) {
      f_t_week = 0;
      f_t_2week = 0;
      f_t_4week = 0;
      f_t_8week = 0;
      f_t_12week = 1;
      f_time = 90;
    }
    if (fi == 12) {
      f_obd2 = f_obd2 == 1 ? 0 : 1;
      f_j1708 = f_obd2 == 1 ? 0 : f_j1708;
      f_j1939 = f_obd2 == 1 ? 0 : f_j1939;
    }

    if (f_calculados == 1) {
      chart_1.options.title.text = "Performance";
      chart_1.options.scales.yAxes[1].display = true;
      chart_1.options.scales.yAxes[0].scaleLabel.labelString = "Redimiento";

      chart_2.options.title.text = "Fuel";

      chart_3.options.title.text = "Idle fuel";

      chart_4.options.title.text = "Engine Hours";
    } else {
      chart_1.options.title.text = "Total Odometer";
      chart_1.options.scales.yAxes[1].display = false;
      chart_1.options.scales.yAxes[0].scaleLabel.labelString = "Kilómetros";

      chart_2.options.title.text = "Total Fuel";

      chart_3.options.title.text = "Total Idle Fuel";

      chart_4.options.title.text = "Total Engine Hours";
    }

    if (f_dias == 1 || f_semana == 1) {
      chart_1.config.type = "bar";
      chart_2.config.type = "bar";
      chart_3.config.type = "bar";
      chart_4.config.type = "bar";
    } else {
      chart_1.config.type = "line";
      chart_2.config.type = "line";
      chart_3.config.type = "line";
      chart_4.config.type = "line";
    }
    if (f_semana == 1) {
      chart_1.options.scales.xAxes[0].time.unit = "week";
      chart_2.options.scales.xAxes[0].time.unit = "week";
      chart_3.options.scales.xAxes[0].time.unit = "week";
      chart_4.options.scales.xAxes[0].time.unit = "week";
    } else {
      chart_1.options.scales.xAxes[0].time.unit = "day";
      chart_2.options.scales.xAxes[0].time.unit = "day";
      chart_3.options.scales.xAxes[0].time.unit = "day";
      chart_4.options.scales.xAxes[0].time.unit = "day";
    }
    maping(selected);
  }
</script>

<main role="main" class="container-fluid h-100 w-100 pl-0 pr-0">
  <div
    style="position: fixed; bottom: 5px; right: 0; color: #95A5A6;z-index:20000;"
  >
    powered by VectorGPS®
  </div>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="row h-50 no-gutters">
        <div class="col-12 h-100">
          <div class="card h-100 bg-light card-b">
            <div class="d-flex flex-column h-100">
              <div
                class="card-header  text-white b-0"
                style="background-color: rgb(128, 181, 57); font-weight: bold;"
              >
                <i class="material-icons md-85">commute</i> Equipment
              </div>
              <div class="card-body flex-grow-1 overflow-auto">
                <div class="list-group">
                  {#each units_list_p as item, i}
                    <a
                      id={i}
                      href="/{item}"
                      class:list-group-item-warning={selected == item}
                      class:active_={selected == item}
                      on:click|preventDefault={() => {
                        maping(item);
                      }}
                      class="list-group-item list-group-item-action"
                      >{typeof $nicks_list[item] != "undefined"
                        ? $nicks_list[item]
                        : item}</a
                    >
                  {/each}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="col-12 h-100">
          <div class="card h-100 bg-light">
            <div class="d-flex flex-column h-100">
              <div
                class="card-header  text-white b-0 "
                style="background-color: rgb(128, 181, 57); font-weight: bold;"
              >
                <i class="material-icons md-85">folder_open</i> Motor data
              </div>
              <div class="card-body flex-grow-1 overflow-auto ">
                {#if vin_data.vin != ""}
                  <table
                    class="w-100 table table-sm table-striped"
                    style="font-size:0.8rem;"
                  >
                    <tbody>
                      <tr style="background-color:rgba(255, 255, 255, 0.05);">
                        <td class="text-center" colspan="2"
                          ><img
                            style=" max-height: 150px;"
                            class="img-fluid"
                            src={typeof motor[vin_data.engine] != "undefined"
                              ? motor[vin_data.engine]
                              : "https://static.motor.es/fotos-diccionario/2019/12/ciclo-otto_1575798674.jpg"}
                            alt="motor"
                          /></td
                        >
                      </tr>
                      <tr>
                        <td>VIN</td><td class="text-right"
                          ><strong>{vin_data.vin}</strong></td
                        >
                      </tr>
                      <tr>
                        <td>Brand</td><td class="text-right"
                          ><strong
                            >{vin_data.make}{vin_data.series
                              ? " (" + vin_data.series + ")"
                              : ""}</strong
                          ></td
                        >
                      </tr>
                      <tr>
                        <td>Model</td><td class="text-right"
                          ><strong>{vin_data.model}</strong></td
                        >
                      </tr>
                      <tr>
                        <td>Year</td><td class="text-right"
                          ><strong>{vin_data.year}</strong></td
                        >
                      </tr>
                      <tr>
                        <td>Motor</td><td class="text-right"
                          ><strong>{vin_data.engine}</strong></td
                        >
                      </tr>
                    </tbody>
                  </table>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="d-flex flex-column h-100">
        <div
          class="d-flex flex-row-reverse align-items-center my-1 justify-content-center"
        >
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => filtro(0)}
              type="button"
              class="btn {f_dias == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Days</button
            >
            <button
              on:click|preventDefault={() => filtro(1)}
              type="button"
              class="btn {f_semana == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Weeks</button
            >
            <button
              on:click|preventDefault={() => filtro(2)}
              type="button"
              class="btn {f_historico == 1 ? 'btn-info' : 'btn-outline-info'}"
              disabled={f_calculados == 1 ? "disabled" : ""}>Historic</button
            >
          </div>
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => filtro(3)}
              type="button"
              class="btn {f_totales == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Totals</button
            >
            <button
              on:click|preventDefault={() => filtro(4)}
              type="button"
              class="btn {f_calculados == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Calculated</button
            >
          </div>
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => filtro(5)}
              type="button"
              class="btn {f_j1708 == 1 ? 'btn-info' : 'btn-outline-info'}"
              >J1708</button
            >
            <button
              on:click|preventDefault={() => filtro(6)}
              type="button"
              class="btn {f_j1939 == 1 ? 'btn-info' : 'btn-outline-info'}"
              >J1939</button
            >
            <button
              on:click|preventDefault={() => filtro(12)}
              type="button"
              class="btn {f_obd2 == 1 ? 'btn-info' : 'btn-outline-info'}"
              >OBD</button
            >
          </div>
          <div class="btn-group mx-2" role="group">
            <button
              on:click|preventDefault={() => filtro(7)}
              type="button"
              class="btn {f_t_week == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Last week</button
            >
            <button
              on:click|preventDefault={() => filtro(8)}
              type="button"
              class="btn {f_t_2week == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Last two weeks</button
            >
            <button
              on:click|preventDefault={() => filtro(9)}
              type="button"
              class="btn {f_t_4week == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Last month</button
            >
            <button
              on:click|preventDefault={() => filtro(10)}
              type="button"
              class="btn {f_t_8week == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Last two months</button
            >
            <button
              on:click|preventDefault={() => filtro(11)}
              type="button"
              class="btn {f_t_12week == 1 ? 'btn-info' : 'btn-outline-info'}"
              >Last three months</button
            >
          </div>
        </div>
        <div class="flex-grow-1 overflow-auto">
          <div class="row h-50 no-gutters">
            <div class="col-6 h-100">
              <div class="card h-100 p-2">
                <div class="card-body h-100 flex-grow-1">
                  <canvas id="chart1" />
                </div>
              </div>
            </div>
            <div class="col-6 h-100">
              <div class="card h-100 p-2">
                <div class="card-body h-100 flex-grow-1">
                  <canvas id="chart2" />
                </div>
              </div>
            </div>
          </div>
          <div class="row h-50 no-gutters">
            <div class="col-6 h-100">
              <div class="card h-100 p-2">
                <div class="card-body h-100">
                  <canvas id="chart3" />
                </div>
              </div>
            </div>
            <div class="col-6 h-100">
              <div class="card h-100 p-2">
                <div class="card-body h-100">
                  <canvas id="chart4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-b {
    border: 0px;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .badge.travel {
    font-size: 0.75rem;
    margin-bottom: 0.4em;
  }
  .btn {
    font-size: 0.75rem;
    padding: 0.1rem, 0.1rem;
    line-height: 0.5;
  }
  .form-control.mini {
    font-size: 0.75rem;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  .card-header:first-child {
    border-radius: 0;
  }
  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }

  .btn-info {
    color: #fff;
    background-color: #729ea5;
    border-color: #729ea5;
  }
</style>
