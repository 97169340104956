<script>
  export let chats_db;
  export let socket_query;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
  } from "./stores.js";

  let chat_list_change = false;
  let chats_window;
  afterUpdate(() => {
    if (chat_list_change) {
      chat_list_change = false;
      chats_window.scrollTop = chats_window.scrollHeight;
    }
  });

  let selected = -1;
  let chat_list = [];
  let chatId_selected = 0;
  async function maping(i, chatId) {
    selected = i;
    chatId_selected = chatId;
    let chat1 = await chats_db.getItem(chatId);
    chat_list = chat1 != null ? JSON.parse(chat1)["chats"] : [];
    chat_list_change = true;
  }

  socket_query.on("chats_", async function (data, data2) {
    if (data != null) {
      if (data == chatId_selected) {
        chat_list.push(JSON.parse(data2));
        chat_list = [...chat_list];
        chat_list_change = true;
      }
    }
  });
  let chat_to_send = "";
  function send_chat() {
    let chat_to_send_ = chat_to_send;
    chat_to_send = "";
    if (chatId_selected != 0) {
      if (chat_to_send_ != "") {
        socket_query.emit(
          "chat_send",
          chatId_selected,
          chat_to_send_,
          (data) => {
            //chat_to_send="";
            if (data == "ok") {
              console.log("chat OK");
            }
          }
        );
      }
    }
  }
</script>

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div class="card-header bg-dark text-white b-0">
            <i tooltip="Operador" class="material-icons md-90">person</i> Choferes
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each $drivers_list as item, i}
                <a
                  href="/{item[0]}"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    maping(i, $drivers[item[0]].chatId);
                  }}
                  class="list-group-item list-group-item-action"
                  >{typeof $drivers[item[0]].name != "undefined"
                    ? $drivers[item[0]].name
                    : item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="d-flex flex-column h-100">
        <div class="card-header  b-0">
          <i class="material-icons md-90">chat</i> Chat
        </div>
        <div
          class="card-body flex-grow-1 overflow-auto p-1"
          bind:this={chats_window}
        >
          {#each chat_list as item, i}
            {#if item.message.search("data:image/jpeg;base64") == -1 && item.message.search("data:audio/aac;base64") == -1}
              <div
                class="d-flex my-1 {item.type == 0
                  ? 'flex-row'
                  : 'flex-row-reverse'}"
              >
                <div
                  class=" card {item.type == 0
                    ? 'text-white bg-dark2'
                    : ' bg-secondary2'}"
                  style="border-radius:1rem;border:2px solid #009184"
                >
                  <div class="card-header b-0">
                    <i
                      class="material-icons md-12"
                      style="color:{item.type == 0 ? '#fff' : '#212121'}"
                      >{item.type == 1 ? "home" : "person_pin"}</i
                    >
                    {item.message}
                  </div>
                </div>
                <div style="font-size:0.7rem" class="d-flex align-items-end">
                  <strong
                    >{moment(new Date(item.date * 1000)).format(
                      "DD/MM/YY HH:mm"
                    )}</strong
                  >
                </div>
              </div>
            {:else if item.message.search("data:audio/aac;base64") != -1}
              <div
                class="d-flex my-1 {item.type == 0
                  ? 'flex-row'
                  : 'flex-row-reverse'}"
              >
                <div
                  class=" card {item.type == 0
                    ? 'text-white bg-dark2'
                    : ' bg-secondary2'}"
                  style="border-radius:1rem;border:2px solid #009184"
                >
                  <div class="card-header b-0">
                    <i
                      class="material-icons md-12"
                      style="color:{item.type == 0 ? '#fff' : '#212121'}"
                      >{item.type == 1 ? "home" : "person_pin"}</i
                    > <audio src={item.message} controls="controls" />
                  </div>
                </div>
                <div style="font-size:0.7rem" class="d-flex align-items-end">
                  <strong
                    >{moment(new Date(item.date * 1000)).format(
                      "DD/MM/YY HH:mm"
                    )}</strong
                  >
                </div>
              </div>
            {:else}
              <div
                class="d-flex my-1 {item.type == 0
                  ? 'flex-row'
                  : 'flex-row-reverse'}"
              >
                <div
                  class=" card {item.type == 0
                    ? 'text-white bg-dark2'
                    : ' bg-secondary2'}"
                  style="border-radius:1rem;border:2px solid #009184"
                >
                  <div class="card-header b-0">
                    <i
                      class="material-icons md-12"
                      style="color:{item.type == 0 ? '#fff' : '#212121'}"
                      >{item.type == 1 ? "home" : "person_pin"}</i
                    > <img src={item.message} alt="" height="300" />
                  </div>
                </div>
                <div style="font-size:0.7rem" class="d-flex align-items-end">
                  <strong
                    >{moment(new Date(item.date * 1000)).format(
                      "DD/MM/YY HH:mm"
                    )}</strong
                  >
                </div>
              </div>
            {/if}
          {/each}
        </div>
        <div class="d-flex mt-1">
          <form on:submit|preventDefault={send_chat} class="d-flex w-100">
            <input
              name="chat"
              class="w-100 mr-2"
              autocomplete="off"
              bind:value={chat_to_send}
              style="font-size:1.1rem"
            />
            <button
              type="submit"
              style="padding: 0.5rem 2rem; z-index:1000"
              class="btn btn-success"><i class="material-icons">send</i></button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
