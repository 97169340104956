<script>
  //https://programadorwebvalencia.com/javascript-recortar-y-previsualizar-imagen/
  export let chats_db;
  export let socket_query;
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    table_pos,
    live,
    drivers_list,
    drivers,
  } from "./stores.js";

  let selected = -1;
  let selected_new = -1;
  let driver_name = "-";
  let tel = "-";
  let chatId = "-";
  let chatType = "-";
  let usuario = "-";
  let inter;
  let aceleracion = 0,
    frenada = 0,
    exceso = 0;
  let travels_list = [];
  let driver_id;
  let photo_file = "img/profile.png";
  function maping(i) {
    add_mode = false;
    drivers_add = [];
    selected_new = -1;
    tel_edit_mode = false;
    selected = i;
    driver_id = $drivers_list[i][0];
    driver_name =
      typeof $drivers[$drivers_list[i][0]].name != "undefined"
        ? $drivers[$drivers_list[i][0]].name
        : "";
    tel =
      typeof $drivers[$drivers_list[i][0]].tel != "undefined"
        ? $drivers[$drivers_list[i][0]].tel
        : "";
    chatId =
      typeof $drivers[$drivers_list[i][0]].chatId != "undefined"
        ? $drivers[$drivers_list[i][0]].chatId
        : "";
    usuario =
      typeof $drivers[$drivers_list[i][0]].user != "undefined"
        ? $drivers[$drivers_list[i][0]].user
        : "";
    travels_list =
      typeof $drivers[$drivers_list[i][0]].travels_list != "undefined"
        ? $drivers[$drivers_list[i][0]].travels_list
        : [];
    chatType =
      chatId.search("\\+52") >= 0
        ? "Vector Messenger"
        : typeof $drivers[$drivers_list[i][0]].ot_account != "undefined"
        ? "OT Drive"
        : "Telegram APP";
    photo_file =
      typeof $drivers[$drivers_list[i][0]].photo != "undefined"
        ? $drivers[$drivers_list[i][0]].photo
        : "img/profile.png";
    score = 0;
    try {
      clearInterval(inter);
    } catch (e) {
      console.log(e);
    }
    inter = setInterval(() => {
      score < $drivers[$drivers_list[i][0]].score
        ? (score = score + 1)
        : clearInterval(inter);
    }, 10);
    chart_1.data.datasets[0].data = $drivers[$drivers_list[i][0]].travels;
    chart_1.update();
    aceleracion = $drivers[$drivers_list[i][0]].indicators[0];
    frenada = $drivers[$drivers_list[i][0]].indicators[1];
    exceso = $drivers[$drivers_list[i][0]].indicators[2];
    //bar1.animate([{width: "0%"},{width: "100%"}],{ duration: 1000});
  }
  function maping_new(i) {
    selected_new = i;
  }
  let score = 0;

  var chart1 = {
    type: "bar",
    data: {
      labels: [
        "Octubre",
        "Noviembre",
        "Diciembre",
        "Enero",
        "Febrero",
        "Marzo",
      ],
      datasets: [
        {
          label: "Numero de viajes",
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: "#ce93d8",
          borderColor: "#df78ef",
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        xAxes: [
          {
            gridLines: {
              offsetGridLines: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: { beginAtZero: true },
          },
        ],
      },
      title: {
        display: true,
        fontSize: 12,
        padding: 3,
        text: "Viajes completados",
      },
      maintainAspectRatio: false,
    },
  };

  let chart_1, bar1;
  onMount(() => {
    chart_1 = new Chart(
      document.getElementById("chart1").getContext("2d"),
      chart1
    );
  });

  $: {
    $drivers_list;
    if (selected != -1) {
      maping(selected);
    }
  }
  let drivers_add = [];
  let add_mode = false;
  function add_driver() {
    selected = -1;
    if (drivers_add.length == 0) {
      drivers_add.push([]);
      drivers_add = [...drivers_add];
      add_mode = true;

      driver_name = "";
      tel = "";
      tel_new = "";
      chatId = "";
      chatId_new = "";
      chatType = "";
      usuario = "";
      usuario_new = "";
      photo_file = "img/profile.png";
      tel_edit_mode = true;

      selected_new = 0;
    }
  }
  let tel_edit_mode = false;
  let tel_new, chatId_new, usuario_new;
  let saved_ok = false,
    saved_type,
    saved_text;
  function edit_tel() {
    if (selected != -1) {
      tel_new = tel;
      chatId_new = chatId;
      usuario_new = usuario;
      tel_edit_mode = true;
    }
  }
  function save_driver() {
    if (selected != -1) {
      socket_query.emit(
        "save_driver",
        driver_id,
        tel_new,
        chatId_new,
        usuario_new,
        (data) => {
          if (data == "ok") {
            saved_type = "alert-success";
            saved_text = "Chofer guardado";
            saved_ok = true;
            tel_edit_mode = false;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    }
    if (selected_new != -1 && selected == -1) {
      if (driver_name == null) {
        return 0;
      }
      socket_query.emit(
        "new_driver",
        driver_name,
        tel_new,
        chatId_new,
        usuario_new,
        (data) => {
          if (data == "ok") {
            saved_type = "alert-success";
            saved_text = "Chofer guardado";
            saved_ok = true;
            tel_edit_mode = false;
            drivers_add = [];
            add_mode = false;
            selected_new = -1;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    }
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-2 h-100">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div
            class="card-header bg-dark text-white b-0"
            style="padding-right: 0.2rem;"
          >
            <div class="d-flex">
              <div class="flex-grow-1 ">
                <i tooltip="Operador" class="material-icons md-90">person</i> Choferes
              </div>
              <div class="d-flex justify-content-end">
                {#if !add_mode}
                  <button
                    id="add_driver"
                    on:click={add_driver}
                    type="button"
                    class="btn btn-info mini"
                  >
                    <i class="material-icons md-90">add_circle_outline</i
                    >Agregar
                  </button>
                {/if}
              </div>
            </div>
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each drivers_add as item, i}
                <a
                  href="/{item[0]}"
                  class:list-group-item-warning={selected_new == i}
                  class:active_={selected_new == i}
                  on:click|preventDefault={() => {
                    maping_new(i);
                  }}
                  class="list-group-item list-group-item-action">Nuevo chofer</a
                >
              {/each}
              {#each $drivers_list as item, i}
                <a
                  href="/{item[0]}"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={() => {
                    maping(i);
                  }}
                  class="list-group-item list-group-item-action"
                  >{typeof $drivers[item[0]].name != "undefined"
                    ? $drivers[item[0]].name
                    : item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 h-100">
      <div class="d-flex flex-column h-100">
        <div class="card-body flex-grow-1 overflow-auto">
          <div class="row h-50 no-gutters">
            <div class="col-2 h-100">
              <div class="card h-100 bg-light" style="font-size:0.8rem;">
                <div class="d-flex flex-column h-100 overflow-auto">
                  {#if selected != -1 || selected_new != -1}
                    <div
                      class="card-header b-0 bg-dark text-white text-center "
                    >
                      <h5>{driver_name}</h5>
                    </div>
                    <div class="d-flex flex-grow-1 align-items-center ">
                      <img class="img-fluid" src={photo_file} alt="profile" />
                    </div>
                  {/if}
                </div>
              </div>
            </div>
            <div class="col-10 h-100">
              <div class="card h-100" style="font-size:0.8rem;">
                <div class="d-flex flex-column h-100">
                  <div class="card-header b-0 bg-dark text-white">
                    <strong>Viajes grafica</strong>
                  </div>
                  <div class="d-flex flex-grow-1 overflow-auto">
                    <canvas id="chart1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row h-50 no-gutters">
            <div class="col-2 h-100">
              <div class="card h-100" style="font-size:0.8vw;">
                <div
                  class="d-flex flex-column h-100"
                  style="overflow-y:hidden;overflow-x: hidden"
                >
                  <div class="card-header b-0 bg-dark text-white">
                    <strong>Score</strong>
                  </div>
                  <div
                    class=" d-flex flex-grow-1 align-items-center align-self-center "
                  >
                    <div
                      class="c100 p{score} big {score > 85
                        ? 'green'
                        : score > 50
                        ? ''
                        : 'orange'}"
                    >
                      <span>{score}</span>
                      <div class="slice">
                        <div class="bar" />
                        <div class="fill" />
                      </div>
                    </div>
                  </div>
                  <div class=" d-flex flex-grow-1  ">
                    <div class="table-responsive">
                      <table class="table table-sm" style="margin-bottom: 0rem">
                        <thead>
                          <tr>
                            <th class="text-center" scope="col"
                              >Aceleración brusca</th
                            >
                            <th class="text-center" scope="col"
                              >Frenada brusca</th
                            >
                            <th class="text-center" scope="col"
                              >Exceso de velocidad</th
                            >
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center"
                              ><span
                                class="badge badge-{aceleracion > 2
                                  ? 'danger'
                                  : aceleracion > 0
                                  ? 'warning'
                                  : 'secondary'}">{aceleracion}</span
                              ></td
                            >
                            <td class="text-center"
                              ><span
                                class="badge badge-{frenada > 2
                                  ? 'danger'
                                  : frenada > 0
                                  ? 'warning'
                                  : 'secondary'}">{frenada}</span
                              ></td
                            >
                            <td class="text-center"
                              ><span
                                class="badge badge-{exceso > 2
                                  ? 'danger'
                                  : exceso > 0
                                  ? 'warning'
                                  : 'secondary'}">{exceso}</span
                              ></td
                            >
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 h-100">
              <div class="card h-100" style="font-size:1vw;">
                <div class="d-flex flex-column h-100 overflow-auto">
                  <div class="card-header b-0 bg-dark text-white">
                    <strong>Datos Generales</strong>
                  </div>
                  <div class=" d-flex flex-grow-1 pt-0">
                    <div class="table-responsive">
                      <table
                        class="table table-sm table-striped "
                        style="margin-bottom: 0rem"
                      >
                        <tbody>
                          <tr>
                            <th scope="row" class="text-center">Nombre</th>
                            {#if tel_edit_mode && selected_new != -1}
                              <td class="text-center" style="color:#2196f3">
                                <input
                                  type="text"
                                  size="10"
                                  maxlength="50"
                                  bind:value={driver_name}
                                />
                              </td>
                            {:else}
                              <td class="text-center">{driver_name}</td>
                            {/if}
                          </tr>
                          <tr>
                            <th scope="row" class="text-center">Teléfono</th>
                            {#if !tel_edit_mode}
                              <td
                                class="text-center"
                                style="color:#2196f3"
                                on:click={edit_tel}>{tel}</td
                              >
                            {:else}
                              <td class="text-center" style="color:#2196f3">
                                <input
                                  type="text"
                                  size="10"
                                  maxlength="10"
                                  bind:value={tel_new}
                                />
                              </td>
                            {/if}
                          </tr>
                          <tr>
                            <th scope="row" class="text-center">ChatId</th>
                            {#if !tel_edit_mode}
                              <td
                                class="text-center"
                                style="color:#2196f3"
                                on:click={edit_tel}>{chatId}</td
                              >
                            {:else}
                              <td class="text-center" style="color:#2196f3">
                                <input
                                  type="text"
                                  size="10"
                                  maxlength="10"
                                  bind:value={chatId_new}
                                />
                              </td>
                            {/if}
                          </tr>
                          <tr>
                            <th scope="row" class="text-center">Usuario</th>
                            {#if !tel_edit_mode}
                              <td
                                class="text-center"
                                style="color:#2196f3"
                                on:click={edit_tel}>{usuario}</td
                              >
                            {:else}
                              <td class="text-center" style="color:#2196f3">
                                <input
                                  type="text"
                                  size="10"
                                  maxlength="10"
                                  bind:value={usuario_new}
                                />
                              </td>
                            {/if}
                          </tr>
                          <tr>
                            <th scope="row" class="text-center">Contraseña</th>
                            {#if !tel_edit_mode}
                              <td class="text-center" on:click={edit_tel}
                                >{usuario != "-" ? "•••••••••" : "-"}
                              </td>
                            {:else}
                              <td class="text-center" style="color:#2196f3">
                                <input
                                  type="text"
                                  value="•••••••••"
                                  size="10"
                                  maxlength="10"
                                />
                              </td>
                            {/if}
                          </tr>
                          <tr>
                            <th scope="row" class="text-center">Chat type</th>
                            {#if !tel_edit_mode}
                              <td class="text-center">{chatType} </td>
                            {:else}
                              <td class="text-center" style="color:#2196f3">
                                <select name="chat_type" bind:value={chatType}>
                                  <option value="Telegram APP"
                                    >Telegram APP</option
                                  >
                                  <option value="OT Drive">OT Drive</option>
                                  <option value="Vector Messenger"
                                    >Vector Messenger</option
                                  >
                                </select>
                              </td>
                            {/if}
                          </tr>
                          {#if tel_edit_mode}
                            <tr>
                              <td style="text-align: right;">
                                <button
                                  id="save_destino"
                                  on:click={save_driver}
                                  type="button"
                                  class="btn btn-success mini">Guardar</button
                                >
                              </td>
                              <td style="text-align: right;">
                                <button
                                  id="cancel_save_destino"
                                  on:click={() => {
                                    add_mode = false;
                                    drivers_add = [];
                                    selected_new = -1;
                                    tel_edit_mode = false;
                                  }}
                                  type="button"
                                  class="btn btn-danger mini">Cancelar</button
                                >
                              </td>
                            </tr>
                          {/if}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7 h-100">
              <div class="card h-100" style="font-size:0.8rem;">
                <div class="d-flex flex-column h-100 overflow-auto">
                  <div class="card-header b-0 bg-dark text-white">
                    <strong>Lista de viajes</strong>
                  </div>
                  <div class=" d-flex flex-grow-1 pt-0">
                    <table
                      class="table table-sm table-striped "
                      style="margin-bottom: 0rem"
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Origen</th>
                          <th scope="col">Destino</th>
                          <th scope="col">Ruta</th>
                          <th scope="col">Duración</th>
                          <th scope="col">Paradas</th>
                          <th scope="col">Distancia</th>
                        </tr>
                      </thead><tbody>
                        {#each travels_list as item, i}
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                            <td>{item[2]}</td>
                            <td>{item[3]}</td>
                            <td>{item[4]}</td>
                            <td>{item[5]}</td>
                            <td>{item[6]}</td>
                          </tr>
                        {/each}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-b {
    border: 0px;
  }
  .bg-dark2 {
    background-color: #009688;
  }
  .bg-secondary2 {
    background-color: #b2dfdb;
  }
  .c100.big {
    font-size: 11vw;
  }
  .c100 {
    margin: 0;
  }
  .c100 > span {
    width: 2.5em;
    line-height: 2.5em;
    font-size: 0.4em;
  }
  .badge {
    font-size: 1.5rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }

  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }

  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1.1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
