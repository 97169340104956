<script>
  import Central from "./Central.svelte";
  import Login from "./Login.svelte";
  import Mapa from "./Mapa.svelte";
  import Mapa_travel from "./Mapa_travel.svelte";
  import Embed_dtc from "./Embed_dtc.svelte";
  import Embed_ecm from "./Embed_ecm.svelte";
  import Embed_mensajes from "./Embed_mensajes.svelte";
  import Embed_recorridos from "./Embed_recorridos.svelte";
  import Embed_monitor from "./Embed_monitor.svelte";

  import {
    table_pos,
    units_list,
    nicks_list,
    geos,
    geos_class,
    geos_class_list,
    menu,
    travels,
    route_list,
    live,
    drivers_list,
    drivers,
    route_list_d,
    travel_crtl,
    zones_list,
    zones,
    geos_joined,
    geos_class_join,
    map_ready,
    travels_list,
    unit_groups,
    geos_full,
    geo_class_full,
    zone_full,
    unit_config,
    unit_types,
    reports_cron,
    portal_version,
    route_list_nosig,
    route_list_nosig_d,
  } from "./stores.js";
  //const serverdb =  (window.location.hostname=="vector.omnitracs.online"||window.location.hostname=="vector1.omnitracs.online")  ? 'https://panel-wss-s1.omnitracs.online' :'https://panel-wss.omnitracs.online';
  const serverdb = "https://aws-r1.omnitracs.online";
  localforage.setDriver(localforage.INDEXEDDB);
  //const base_db = localforage.createInstance({name: "base_1_db"});
  //const units_db = localforage.createInstance({name: "units_1_db"});
  //const geos_db = localforage.createInstance({name: "geos_1_db"});
  //const geos_class_db = localforage.createInstance({name: "geos__class_1_db"});
  const units_travel_db = localforage.createInstance({
    name: "units_travel_1_db",
  });
  const routes_db = localforage.createInstance({ name: "routes_1_db" });
  const monitor_db = localforage.createInstance({ name: "monitor_1_db" });
  const chats_db = localforage.createInstance({ name: "chats_1_db" });
  const vins_db = localforage.createInstance({ name: "vins_1_db" });
  const ecm_db = localforage.createInstance({ name: "ecm_1_db" });
  const dtc_db = localforage.createInstance({ name: "dtc_1_db" });
  const travel_db = localforage.createInstance({ name: "travel_1_db" });

  const unit_list_db = localforage.createInstance({ name: "unit_list_1_db" });
  const nick_list_db = localforage.createInstance({ name: "nick_list_1_db" });
  const login_db = localforage.createInstance({ name: "login_1_db" });
  const travel_crtl_db = localforage.createInstance({
    name: "travel_crtl_1_db",
  });
  const geo__db = localforage.createInstance({ name: "geo__1_db" });
  const geo_class_list_db = localforage.createInstance({
    name: "geo_class_list_1_db",
  });
  const geos_class__db = localforage.createInstance({
    name: "geos_class__1_db",
  });
  const geo_list_wks_db = localforage.createInstance({
    name: "geo_list_wks_1_db",
  });

  const protocol_db = localforage.createInstance({ name: "protocol_1_db" });

  const socket_query = io(serverdb, {
    transports: ["websocket"],
    query: { type: "query" },
  });

  var google_lic = document.getElementById("google_lic").getAttribute("value");
  var script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=" +
    google_lic +
    "&callback=initMap";
  script.defer = true;
  script.async = true;
  window.initMap = function () {
    map_ready.set(1);
  };
  if (window.location.pathname != "/embed_dtc.html") {
    // All embed APPs
    document.head.appendChild(script);
  }

  let geos_worker,
    travel_worker,
    monitor_worker,
    trips_worker,
    routes_worker,
    unit_worker,
    embed_dtc_worker;
  let user_level = 0;
  if (window.location.pathname != "/") {
    window.document.body.classList.add("general");
  }
  if (window.location.pathname == "/") {
    // Mega Wokers
    let live_p = {};
    let table_p = [];
    unit_worker = new Worker("js/unit_worker.js");
    unit_worker.onmessage = function (data) {
      //console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "units_list") {
          table_p = [];
          for (var x in data.data.data) {
            table_p[x] = { modid: data.data.data[x] };
          }
          units_list.set([...data.data.data]);
        }
        if (data.data.name == "nicks_list") nicks_list.set(data.data.data);
        if (data.data.name == "live") {
          live_p[data.data.modid] = data.data.data;
          live.set(live_p);
          for (var u in table_p) {
            if (table_p[u]["modid"] == data.data.modid) {
              table_p[u] = data.data.data;
            }
          }
          table_p.sort(compare);
          table_pos.set([...table_p]);
        }
        //if(data.data.name=="table_pos") table_pos.set([...data.data.data]);
      }
    };
    function compare(a, b) {
      return b.last_comm - a.last_comm;
    }
    trips_worker = new Worker("js/trips_worker.js");
    trips_worker.onmessage = function (data) {
      console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "drivers_list") drivers_list.set(data.data.data);
        if (data.data.name == "drivers") drivers.set(data.data.data);
        if (data.data.name == "unit_groups") unit_groups.set(data.data.data);
        if (data.data.name == "travel_crtl") travel_crtl.set(data.data.data);
        if (data.data.name == "travels_list") travels_list.set(data.data.data);
        if (data.data.name == "unit_config") unit_config.set(data.data.data);
        if (data.data.name == "unit_types") unit_types.set(data.data.data);
        if (data.data.name == "reports_cron") reports_cron.set(data.data.data);
      }
    };

    routes_worker = new Worker("js/routes_worker.js");
    routes_worker.onmessage = function (data) {
      console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "route_list") route_list.set([...data.data.data]);
        if (data.data.name == "route_list_d") route_list_d.set(data.data.data);
        if (data.data.name == "route_list_nosig")
          route_list_nosig.set([...data.data.data]);
        if (data.data.name == "route_list_nosig_d")
          route_list_nosig_d.set(data.data.data);
      }
    };
    let travels_p = {};
    monitor_worker = new Worker("js/monitor_worker.js");
    monitor_worker.onmessage = function (data) {
      if (data.data.type == "global") {
        if (data.data.name == "travels") {
          travels_p[data.data.modid] = data.data.data;
          travels.set(travels_p);
        }
      }
    };
    travel_worker = new Worker("js/travel_worker.js");
    travel_worker.onmessage = function (data) {
      console.log(data.data);
      if (data.data.type == "global") {
      }
    };
    geos_worker = new Worker("js/geos_worker.js");
    geos_worker.onmessage = function (data) {
      if (data.data.type == "global") {
        if (data.data.name == "geos") geos.set([...data.data.data]);
        if (data.data.name == "geos_class_list")
          geos_class_list.set([...data.data.data]);
        if (data.data.name == "geos_class") geos_class.set(data.data.data);
        if (data.data.name == "zones_list") zones_list.set([...data.data.data]);
        if (data.data.name == "zones") zones.set(data.data.data);
        if (data.data.name == "geos_joined") geos_joined.set(data.data.data);
        if (data.data.name == "geos_class_join")
          geos_class_join.set(data.data.data);
        if (data.data.name == "geos_full") geos_full.set(data.data.data);
        if (data.data.name == "geo_class_full")
          geo_class_full.set(data.data.data);
        if (data.data.name == "zone_full") zone_full.set(data.data.data);
      }
    };

    socket_query.on("hello", async function (data) {
      var id = await login_db.getItem("key");
      if (!id) {
        id = new Date().valueOf().toString();
        await login_db.setItem("key", id);
      }

      var login_ = await login_db.getItem("login_");
      if (!login_) {
        login_ = "";
        await login_db.setItem("login_", login_);
      } else {
        if (login_ != "") {
          socket_query.emit(
            "hello",
            login_,
            id,
            $portal_version,
            window.screen.width + "x" + window.screen.height
          );
        }
      }
      //var menu_base = await base_db.getItem('menu_base');
      //if(!menu_base){menu_base = 0;await base_db.setItem('menu_base', menu_base);}
      console.log("id", id);
    });

    socket_query.on("user_info", async function (data) {
      console.log("user_info", data);
      if (data != null) {
        user_level = JSON.parse(data)["level"];
        //menu.set(await base_db.getItem('menu_base'));
        if ($menu == 0) {
          menu.set(1);
          login_db.setItem("menu_base", 1);
          window.document.body.classList.add("central2");
        }
        socket_query.emit("portal_notification");
      } else {
        login_db.setItem("login_", "");
        menu.set(0);
        login_db.setItem("menu_base", 0);
      }
    });

    socket_query.on("redirection_mode", async function (data) {
      window.location.replace(data);
    });

    login_db.getItem("menu_base", async function (err, data) {
      if (data) {
        menu.set(data);
        window.document.body.classList.add("central2");
      }
      if (!data) {
        let menu_base = 0;
        await login_db.setItem("menu_base", menu_base);
      }
    });

    travel_crtl_db.getItem("travel_crtl", function (err, data) {
      if (data != null) {
        travel_crtl.set(JSON.parse(data));
      }
    });
    geo__db.getItem("geo_", async function (err, data) {
      if (data != null) {
        geos.set(data);
      }
    });
    geo_class_list_db.getItem("geo_class_list", function (err, data) {
      if (data != null) {
        geos_class_list.set(JSON.parse(data));
      }
    });
    geos_class__db.getItem("geos_class_", function (err, data) {
      if (data != null) {
        geos_class.set(data);
      }
    });
    /*
    base_db.getItem('nick_list',function(err, data){
      if(data!=null)
      {nicks_list.set(JSON.parse(data));}
    });
    base_db.getItem('route_list',function(err, data){
      if(data!=null)
      {route_list.set(data);}
    });
    base_db.getItem('geos_joined',function(err, data){
      if(data!=null)
      {geos_joined.set(data);}
    });
    base_db.getItem('geos_class_join',function(err, data){
      if(data!=null)
      {geos_class_join.set(data);}
    });
    */
  }
  
  if (window.location.pathname == "/embed_dtc.html") {
    embed_dtc_worker = new Worker("js/embed_dtc_worker.js");
    embed_dtc_worker.onmessage = function (data) {
      console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "units_list") {
          units_list.set([...data.data.data]);
        }
      }
    };
  }
  if (window.location.pathname == "/embed_ecm.html") {
    let live_p = {};
    let table_p = [];
    unit_worker = new Worker("js/unit_worker.js");
    unit_worker.onmessage = function (data) {
      //console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "units_list") {
          table_p = [];
          for (var x in data.data.data) {
            table_p[x] = { modid: data.data.data[x] };
          }
          units_list.set([...data.data.data]);
        }
        if (data.data.name == "nicks_list") nicks_list.set(data.data.data);
        if (data.data.name == "live") {
          live_p[data.data.modid] = data.data.data;
          live.set(live_p);
          for (var u in table_p) {
            if (table_p[u]["modid"] == data.data.modid) {
              table_p[u] = data.data.data;
            }
          }
          table_p.sort(compare);
          table_pos.set([...table_p]);
        }
        //if(data.data.name=="table_pos") table_pos.set([...data.data.data]);
      }
    };
    function compare(a, b) {
      return b.last_comm - a.last_comm;
    }
  }
  if (window.location.pathname == "/embed_mensajes.html") {
    socket_query.on("hello", async function (data) {
      var id = await login_db.getItem("key");
      if (!id) {
        id = new Date().valueOf().toString();
        await login_db.setItem("key", id);
      }

      var login_ = await login_db.getItem("login_");
      if (!login_) {
        login_ = "";
        await login_db.setItem("login_", login_);
      } else {
        if (login_ != "") {
          socket_query.emit(
            "hello",
            login_,
            id,
            $portal_version,
            window.screen.width + "x" + window.screen.height
          );
        }
      }
      //var menu_base = await base_db.getItem('menu_base');
      //if(!menu_base){menu_base = 0;await base_db.setItem('menu_base', menu_base);}
      console.log("id", id);
    });

    socket_query.on("user_info", async function (data) {
      console.log("user_info", data);
      if (data != null) {
        user_level = JSON.parse(data)["level"];
        //menu.set(await base_db.getItem('menu_base'));
        if ($menu == 0) {
          menu.set(1);
          login_db.setItem("menu_base", 1);
          window.document.body.classList.add("central2");
        }
        socket_query.emit("portal_notification");
      } else {
        login_db.setItem("login_", "");
        menu.set(0);
        login_db.setItem("menu_base", 0);
      }
    });

    trips_worker = new Worker("js/embed_trips_worker.js");
    trips_worker.onmessage = function (data) {
      console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "drivers_list") drivers_list.set(data.data.data);
        if (data.data.name == "drivers") drivers.set(data.data.data);
        if (data.data.name == "unit_groups") unit_groups.set(data.data.data);
        if (data.data.name == "travel_crtl") travel_crtl.set(data.data.data);
        if (data.data.name == "travels_list") travels_list.set(data.data.data);
        if (data.data.name == "unit_config") unit_config.set(data.data.data);
        if (data.data.name == "unit_types") unit_types.set(data.data.data);
        if (data.data.name == "reports_cron") reports_cron.set(data.data.data);
      }
    };
  }
  if (window.location.pathname == "/embed_recorridos.html") {
    let live_p = {};
    let table_p = [];
    unit_worker = new Worker("js/unit_worker.js");
    unit_worker.onmessage = function (data) {
      //console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "units_list") {
          table_p = [];
          for (var x in data.data.data) {
            table_p[x] = { modid: data.data.data[x] };
          }
          units_list.set([...data.data.data]);
        }
        if (data.data.name == "nicks_list") nicks_list.set(data.data.data);
        if (data.data.name == "live") {
          live_p[data.data.modid] = data.data.data;
          live.set(live_p);
          for (var u in table_p) {
            if (table_p[u]["modid"] == data.data.modid) {
              table_p[u] = data.data.data;
            }
          }
          table_p.sort(compare);
          table_pos.set([...table_p]);
        }
        //if(data.data.name=="table_pos") table_pos.set([...data.data.data]);
      }
    };
    function compare(a, b) {
      return b.last_comm - a.last_comm;
    }
  }
  if (window.location.pathname == "/embed_monitor.html") {
    let live_p = {};
    let table_p = [];
    unit_worker = new Worker("js/unit_worker.js");
    unit_worker.onmessage = function (data) {
      //console.log(data.data);
      if (data.data.type == "global") {
        if (data.data.name == "units_list") {
          table_p = [];
          for (var x in data.data.data) {
            table_p[x] = { modid: data.data.data[x] };
          }
          units_list.set([...data.data.data]);
        }
        if (data.data.name == "nicks_list") nicks_list.set(data.data.data);
        if (data.data.name == "live") {
          live_p[data.data.modid] = data.data.data;
          live.set(live_p);
          for (var u in table_p) {
            if (table_p[u]["modid"] == data.data.modid) {
              table_p[u] = data.data.data;
            }
          }
          table_p.sort(compare);
          table_pos.set([...table_p]);
        }
        //if(data.data.name=="table_pos") table_pos.set([...data.data.data]);
      }
    };
    function compare(a, b) {
      return b.last_comm - a.last_comm;
    }
    let travels_p = {};
    monitor_worker = new Worker("js/monitor_worker.js");
    monitor_worker.onmessage = function (data) {
      if (data.data.type == "global") {
        if (data.data.name == "travels") {
          travels_p[data.data.modid] = data.data.data;
          travels.set(travels_p);
        }
      }
    };
  }

  if (window.location.pathname != "/") {
    window.document.body.classList.add("central2");
  }
</script>

{#if window.location.pathname == "/mapa.html"}
  <Mapa />
{:else if window.location.pathname == "/mapa_travel.html"}
  <Mapa_travel {socket_query} />
{:else if window.location.pathname == "/embed_dtc.html"}
  <Embed_dtc {socket_query} {embed_dtc_worker} {dtc_db} />
{:else if window.location.pathname == "/embed_ecm.html"}
  <Embed_ecm {units_travel_db} {socket_query} {vins_db} {ecm_db} />
{:else if window.location.pathname == "/embed_mensajes.html"}
  <Embed_mensajes {socket_query} {chats_db} />
{:else if window.location.pathname == "/embed_recorridos.html"}
  <Embed_recorridos {socket_query} {units_travel_db} />
{:else if window.location.pathname == "/embed_monitor.html"}
  <Embed_monitor {socket_query} {routes_db} {units_travel_db} />
{:else if $menu == 0}
  <Login
    {unit_worker}
    {routes_worker}
    {trips_worker}
    {travel_worker}
    {geos_worker}
    {socket_query}
    {monitor_worker}
    {login_db}
  />
{:else}
  <Central
    {dtc_db}
    {ecm_db}
    {vins_db}
    {user_level}
    {socket_query}
    {units_travel_db}
    {login_db}
    {routes_db}
    {chats_db}
    {travel_db}
    {monitor_worker}
    {unit_worker}
    {geos_worker}
    {travel_worker}
    {trips_worker}
    {routes_worker}
    {protocol_db}
  />
{/if}

<style>
  :global(html) {
    height: 100%;
  }
  :global(body) {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    background-image: url("../img/fondo.png");
    background-size: cover;
    background-attachment: fixed;
  }
  :global(body.central) {
    line-height: 1;
    height: 100%;
    width: 100%;
    min-height: 3rem;
    padding-top: 2.5rem;
    background-color: #f5f5f5;
    padding-bottom: 0rem;
    font-size: 0.85rem;
  }
  :global(body.central2) {
    display: flex;
    line-height: 1;
    height: 100%;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: normal;
    text-align: left;
    font-size: 0.85rem;
    background-image: none;
  }
  :global(body.general) {
    line-height: 1;
    height: 100%;
    width: 100%;
    min-height: 3rem;
    padding-top: 0rem;
    background-color: #f5f5f5;
    padding-bottom: 0rem;
    font-size: 0.85rem;
    background-image: none;
  }
</style>
