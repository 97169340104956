<script>
  export let units_travel_db, socket_query, ecm_db, routes_db;
  import { onMount } from "svelte";
  import {
    units_list,
    nicks_list,
    geos,
    geos_class,
    travels,
    route_list,
  } from "./stores.js";
  import { fade } from "svelte/transition";

  var map, chart_1, chart_2, chart_3;

  onMount(() => {
    chart_1 = new Chart(
      document.getElementById("chart1").getContext("2d"),
      chart1
    );
    chart_2 = new Chart(
      document.getElementById("chart2").getContext("2d"),
      chart2
    );
    chart_3 = new Chart(
      document.getElementById("chart3").getContext("2d"),
      chart3
    );
    function initMap() {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 24.458489, lng: -102.217231 },
        zoom: 5,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
        ],
      });
    }
    initMap();
  });

  var chart1 = {
    type: "line",
    data: {
      datasets: [
        {
          label: "Recorrido 1",
          data: [
            { y: 2, t: 1579908586980 },
            { y: 1, t: 1579909586980 },
            { y: 0, t: 1579910586980 },
            { y: 0, t: 1579912586980 },
            { y: 0, t: 1579913586980 },
            { y: 1, t: 1579914586980 },
            { y: 2, t: 1579915586980 },
            { y: 2, t: 1579916586980 },
          ],
          fill: true,
          borderColor: "#2196f3",
          backgroundColor: "#e3f2fd",
          steppedLine: true,
        },
        {
          label: "Recorrido 2",
          data: [
            { y: 2, t: 1579916586980 },
            { y: 2, t: 1579917586980 },
            { y: 1, t: 1579918586980 },
            { y: 0, t: 1579919586980 },
            { y: 0, t: 1579920586980 },
            { y: 0, t: 1579921586980 },
            { y: 1, t: 1579922586980 },
            { y: 2, t: 1579923586980 },
            { y: 2, t: 1579924586980 },
          ],
          fill: true,
          borderColor: "#e91e63",
          backgroundColor: "#ffebee",
          steppedLine: true,
        },
      ],
    },
    options: {
      onClick: function (d) {
        let e = chart_1.getElementAtEvent(d);
        if (typeof e[0] != "undefined") {
          let lat =
            chart_1.data.datasets[e[0]["_datasetIndex"]]["data"][
              e[0]["_index"]
            ]["pos"][0];
          let lng =
            chart_1.data.datasets[e[0]["_datasetIndex"]]["data"][
              e[0]["_index"]
            ]["pos"][1];
          let n_event =
            chart_1.data.datasets[e[0]["_datasetIndex"]]["data"][
              e[0]["_index"]
            ]["n_event"];
          map.panTo({ lat: lat, lng: lng });
          map.setZoom(16);
          lista_eventos.scrollTop = Math.round(
            (lista_eventos.scrollHeight / events.length - 1) * n_event
          );
          selected_event = n_event;
        }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            let c =
              tooltipItem.yLabel == 0
                ? "Drive"
                : tooltipItem.yLabel == 1
                ? "Ilde"
                : "Off";
            return c;
          },
        },
      },
      title: {
        display: false,
        text: "Viaje",
      },
      legend: {
        display: false,
      },
      layout: {
        padding: {
          top: 30,
          right: 20,
        },
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              callback: function (value, index, values) {
                return value == 0 ? "Drive" : value == 1 ? "Idle" : "Off";
              },
            },
          },
        ],
        xAxes: [
          {
            type: "time",
            time: {
              displayFormats: {
                minute: "HH:mm",
              },
            },
          },
        ],
      },
    },
  };

  var chart2 = {
    type: "pie",
    data: {
      labels: ["On", "Off", "Idle"],
      datasets: [
        {
          data: [10, 10, 20],
          backgroundColor: ["#90caf9", "#ff6090", "#9a67ea"],
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.labels[tooltipItem.index];
            var val =
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label + ": " + time_code(val);
          },
        },
      },
    },
  };

  var chart3 = {
    type: "horizontalBar",
    data: {
      labels: ["Tiempo"],
      datasets: [
        {
          label: "On",
          backgroundColor: "#90caf9",
          data: [10],
        },
        {
          label: "Off",
          backgroundColor: "#ff6090",
          data: [10],
        },
        {
          label: "Idle",
          backgroundColor: "#9a67ea",
          data: [20],
        },
        {
          label: "Off",
          backgroundColor: "#90caf9",
          data: [2],
        },
      ],
    },
    options: {
      events: [],
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        mode: "index",
        intersect: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
      legend: {
        display: false,
      },
    },
  };

  let units_list_p;
  $: {
    units_list_p = $units_list;
    $nicks_list;
    units_list_p.sort(units_order);
  }
  function units_order(a, b) {
    if ($nicks_list[b] < $nicks_list[a]) {
      return 1;
    } else {
      return -1;
    }
    return 0;
  }
  let selected = -1;
  let selected_event = -1;
  let recorridos = [],
    recorridos_ok = 0;
  //let events = [{"type":0,"date":"02:47","time":time_code(38),"distance":40,"name":"Patio Zemog Tuxtepec"},{"type":1,"date":"02:48","time":"1h 30min","name":"Compañía Cervecera del Trópico"},{"type":2,"date":"02:49","time":"33min"},{"type":3,"date":"02:50","vel":82}];
  let events = [];
  let viajes_list;
  let ecm = {};
  async function maping(event) {
    let unit_s = event.toElement.pathname.split("/")[1];
    selected = event.target.id;
    let rec_p = [];
    try {
      rec_p = JSON.parse(await units_travel_db.getItem(unit_s));
    } catch (e) {
      console.log("Error leyendo travel", e);
    }
    
    let viaje_on = false;
    let viaje_ = {};
    recorridos = [];
    recorridos_ok = 0;
    for (var x in rec_p) {
      if (typeof rec_p[x]["pos"] != "undefined") {
        let geo_check_ini = geo_search(
          rec_p[x]["pos_start"][0] / 10000,
          rec_p[x]["pos_start"][1] / 10000
        );
        let geo_check_fin = geo_search(
          (rec_p[x]["pos_start"][0] -
            rec_p[x]["pos"][rec_p[x]["pos"].length - 1][0]) /
            10000,
          (rec_p[x]["pos_start"][1] -
            rec_p[x]["pos"][rec_p[x]["pos"].length - 1][1]) /
            10000
        );
        rec_p[x]["name_from"] = geo_check_ini[0];
        rec_p[x]["name_to"] = geo_check_fin[0];
        rec_p[x]["id_from"] = geo_check_ini[2];
        rec_p[x]["id_to"] = geo_check_fin[2];
        rec_p[x]["type_from"] = geo_check_ini[1];
        rec_p[x]["type_to"] = geo_check_fin[1];
        rec_p[x]["fecha_inicio"] = moment(
          new Date(rec_p[x]["date_start"] * 1000)
        ).format("DD/MM/YY HH:mm");
        rec_p[x]["fecha_inicio_"] = rec_p[x]["date_start"];
        rec_p[x]["fecha_fin"] = moment(
          new Date(
            (rec_p[x]["date_start"] +
              rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3] * 60) *
              1000
          )
        ).format("DD/MM/YY HH:mm");
        rec_p[x]["fecha_fin_"] =
          rec_p[x]["date_start"] +
          rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3] * 60;
        if (geo_check_ini[1] == "2") {
          if (!viaje_on) {
            viaje_ = {};
            viaje_on = true; // Incia Viaje
            viaje_["fecha_inicio"] = moment(
              new Date(rec_p[x]["date_start"] * 1000)
            ).format("DD/MM/YY HH:mm");
            viaje_["fecha_inicio_"] = rec_p[x]["date_start"];
            viaje_["from"] = geo_check_ini[0];
            viaje_["pos_"] = [];
            viaje_["duracion"] = 0;
            viaje_["recorridos"] = 1;
            viaje_["table_"] = [];
          }
        }
        if (geo_check_fin[1] == "2") {
          if (viaje_on) {
            viaje_on = false; // Termina Viaje
            viaje_["pos_"].push(rec_p[x]);
            viaje_["to"] = geo_check_fin[0];
            viaje_["fecha_fin_"] =
              rec_p[x]["date_start"] +
              rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3] * 60;
            viaje_["fecha_fin"] = moment(
              new Date(viaje_["fecha_fin_"] * 1000)
            ).format("DD/MM/YY HH:mm");
            viaje_["duracion"] =
              viaje_["duracion"] +
              rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3];
            viaje_["distance"] = 0;
            if (viaje_["from"] == viaje_["to"]) {
              viaje_["travel_type"] = "loop";
            } else {
              viaje_["travel_type"] = "";
            }
            viaje_["table_"].push([
              geo_check_ini[0],
              geo_check_fin[0],
              rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3],
            ]);
            recorridos.push(viaje_);
            viaje_ = {};
          }
        }
        if (viaje_on) {
          viaje_["pos_"].push(rec_p[x]);
          viaje_["duracion"] =
            viaje_["duracion"] + rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3];
          viaje_["recorridos"] = viaje_["recorridos"] + 1;
          viaje_["table_"].push([
            geo_check_ini[0],
            geo_check_fin[0],
            rec_p[x]["pos"][rec_p[x]["pos"].length - 1][3],
          ]);
        }
      }
    }
    //ECM
    let ecm_;
    try {
      ecm_ = JSON.parse(await ecm_db.getItem(unit_s));
    } catch (e) {
      console.log("Error leyendo ECM", e);
    }
    ecm = !ecm_ ? {} : ecm_;

    let e = { target: { id: "trip_0" } };
    if (recorridos.length > 0) {
      recorridos.sort(trips_order);
      recorridos_ok = 1;
      trip_map(e);
    }
    viajes_list.scrollTop = 0;
  }
  function trips_order(a, b) {
    return b.fecha_inicio_ - a.fecha_inicio_;
  }
  function geo_search(lat, lng) {
    let found = false;
    for (let x in $geos) {
      for (let y in $geos[x][1]) {
        let lat_ = $geos[x][1][y][0];
        let lng_ = $geos[x][1][y][1];
        if (get_km(lat, lng, lat_, lng_) <= 0.13) {
          if (typeof $geos_class[$geos[x][0]] == "undefined") {
            return [
              "Geocerca_automatica-" + $geos[x][0].split("-")[0],
              1,
              $geos[x][0],
            ];
          } else {
            return [
              $geos_class[$geos[x][0]][0],
              $geos_class[$geos[x][0]][1],
              $geos[x][0],
            ];
          }
          found = true;
          break;
        }
      }
      if (found) {
        break;
      }
    }
    return ["Geocerca_automatica_", 1, ""];
  }
  function get_km(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = (lat2 - lat1) * (Math.PI / 180);
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  function time_code(time) {
    if (time < 60) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("m[min]");
    } else if (time < 60 * 24) {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("H[h] m[min]");
    } else {
      return moment
        .utc()
        .subtract(1, "years")
        .startOf("years")
        .add({ minutes: time })
        .format("D[d] H[h] m[min]");
    }
  }
  let t_selected = 0;
  let recorrido = [];
  let marks_timeout = [];
  let coordenada = [];
  let c_coordenadas = [];
  let mark_pause = [];
  let colors_line = [
    "#2196f3",
    "#e91e63",
    "#03a9f4",
    "#9c27b0",
    "#00bcd4",
    "#673ab7",
    "#009688",
    "#3f51b5",
    "#4caf50",
    "#ff5722",
    "#8bc34a",
    "#ff9800",
    "#cddc39",
    "#ffc107",
    "#607d8b",
    "#ffeb3b",
    "#9e9e9e",
    "#212121",
    "#f44336",
  ];
  let color_sombra = [
    "#e3f2fd",
    "#fce4ec",
    "#e1f5fe",
    "#f3e5f5",
    "#e0f7fa",
    "#ede7f6",
    "#e0f2f1",
    "#e8eaf6",
    "#e8f5e9",
    "#fbe9e7",
    "#f1f8e9",
    "#fff3e0",
    "#f9fbe7",
    "#fff8e1",
    "#eceff1",
    "#fffde7",
    "#fafafa",
    "#fafafa",
    "#ffebee",
  ];
  let m_inicio = [],
    m_fin = [],
    m_pause = [];
  let lista_eventos;
  let viaje_detail;
  function trip_map(event) {
    selected_event = -1;
    route_stat = false;
    geo_status = "Desconocida";
    t_selected = event.target.id.split("_")[1];
    if (typeof t_selected == "undefined") {
      return 0;
    }
    events = [];
    let date_0, date_0_, time_0, distance_0, name_0, id_0, type_0;
    let date_1, date_1_, time_1, name_1, id_1, type_1;
    let color_c = 0;
    c_coordenadas = [];
    mark_pause = [];
    let total_dist_rec = 0;
    let bounds = new google.maps.LatLngBounds();
    for (let x in recorridos[t_selected]["pos_"]) {
      let last_lat = recorridos[t_selected]["pos_"][x]["pos_start"][0] / 10000;
      let last_lng = recorridos[t_selected]["pos_"][x]["pos_start"][1] / 10000;
      let total_dist = 0;
      for (let y in recorridos[t_selected]["pos_"][x]["pos"]) {
        let lat =
          (recorridos[t_selected]["pos_"][x]["pos_start"][0] -
            recorridos[t_selected]["pos_"][x]["pos"][y][0]) /
          10000;
        let lng =
          (recorridos[t_selected]["pos_"][x]["pos_start"][1] -
            recorridos[t_selected]["pos_"][x]["pos"][y][1]) /
          10000;
        total_dist = total_dist + get_km(last_lat, last_lng, lat, lng);
        last_lat = lat;
        last_lng = lng;
      }

      date_0 = recorridos[t_selected]["pos_"][x]["fecha_inicio"];
      date_0_ = recorridos[t_selected]["pos_"][x]["fecha_inicio_"];
      time_0 =
        recorridos[t_selected]["pos_"][x]["pos"][
          recorridos[t_selected]["pos_"][x]["pos"].length - 1
        ][3];
      distance_0 = Math.round(total_dist);
      total_dist_rec = total_dist_rec + distance_0;
      name_0 = recorridos[t_selected]["pos_"][x]["name_from"];
      id_0 = recorridos[t_selected]["pos_"][x]["id_from"];
      type_0 = recorridos[t_selected]["pos_"][x]["type_from"];
      events.push({
        type: 0,
        date: date_0,
        date_: date_0_,
        time: time_code(time_0),
        time_: time_0,
        distance: distance_0,
        name: name_0,
        id: id_0,
        geo_type: type_0,
        color: colors_line[color_c],
        pos: [
          recorridos[t_selected]["pos_"][x]["pos_start"][0] / 10000,
          recorridos[t_selected]["pos_"][x]["pos_start"][1] / 10000,
        ],
      });

      coordenada = [];
      for (let y in recorridos[t_selected]["pos_"][x]["pos"]) {
        let time_stop =
          recorridos[t_selected]["pos_"][x]["pos"][y][4] -
          recorridos[t_selected]["pos_"][x]["pos"][y][3];
        let date_2 =
          recorridos[t_selected]["pos_"][x]["date_start"] +
          recorridos[t_selected]["pos_"][x]["pos"][y][3] * 60;

        let lat_map =
          (recorridos[t_selected]["pos_"][x]["pos_start"][0] -
            recorridos[t_selected]["pos_"][x]["pos"][y][0]) /
          10000;
        let lng_map =
          (recorridos[t_selected]["pos_"][x]["pos_start"][1] -
            recorridos[t_selected]["pos_"][x]["pos"][y][1]) /
          10000;

        if (time_stop > 4) {
          events.push({
            type: 2,
            date: moment(new Date(date_2 * 1000)).format("DD/MM/YY HH:mm"),
            date_: date_2,
            time: time_code(time_stop),
            time_: time_stop,
            color: colors_line[color_c],
            pos: [lat_map, lng_map],
          });
          mark_pause.push({ lat: lat_map, lng: lng_map });
        }
        coordenada.push({ lat: lat_map, lng: lng_map });
        bounds.extend({ lat: lat_map, lng: lng_map });
      }
      c_coordenadas.push({
        color: colors_line[c_coordenadas.length],
        cors: coordenada,
      });

      date_1 = recorridos[t_selected]["pos_"][x]["fecha_fin"];
      date_1_ = recorridos[t_selected]["pos_"][x]["fecha_fin_"];
      time_1 = 0;
      name_1 = recorridos[t_selected]["pos_"][x]["name_to"];
      id_1 = recorridos[t_selected]["pos_"][x]["id_to"];
      type_1 = recorridos[t_selected]["pos_"][x]["type_to"];
      if (x < recorridos[t_selected]["pos_"].length - 1) {
        time_1 = Math.round(
          (recorridos[t_selected]["pos_"][parseInt(x) + 1]["fecha_inicio_"] -
            recorridos[t_selected]["pos_"][x]["fecha_fin_"]) /
            60
        );
      }
      events.push({
        type: 1,
        date: date_1,
        date_: date_1_,
        time: time_code(time_1),
        time_: time_1,
        name: name_1,
        id: id_1,
        geo_type: type_1,
        color: colors_line[color_c],
        pos: [last_lat, last_lng],
      });

      color_c++;
    }
    recorridos[t_selected]["distance"] = total_dist_rec;

    let chart__ = [[]];
    let chart_c = 0;
    let total_ilde = 0;
    let total_drive = 0;
    let total_off = 0;
    chart3.data.datasets = [];
    for (let x in events) {
      if (events[x]["type"] == 0 || events[x]["type"] == 1) {
        chart__[chart_c].push({
          y: events[x]["type"] == 1 ? 2 : events[x]["type"],
          t: events[x]["date_"] * 1000,
          pos: events[x]["pos"],
          n_event: x,
        });
      }
      if (events[x]["type"] == 2) {
        if (
          chart__[chart_c][chart__[chart_c].length - 1]["t"] ==
          events[x]["date_"] * 1000
        ) {
          chart__[chart_c].pop();
        }
        chart__[chart_c].push({
          y: 1,
          t: events[x]["date_"] * 1000,
          pos: events[x]["pos"],
          n_event: x,
        });
        chart__[chart_c].push({
          y: 0,
          t: (events[x]["date_"] + events[x]["time_"] * 60) * 1000,
          pos: events[x]["pos"],
          n_event: x,
        });
      }
      if (events[x]["type"] == 1) {
        chart_c++;
        chart__.push([
          {
            y: events[x]["type"] == 1 ? 2 : events[x]["type"],
            t: events[x]["date_"] * 1000,
            pos: events[x]["pos"],
            n_event: x,
          },
        ]);
      }
      if (events[x]["type"] == 0) {
        total_drive = total_drive + events[x]["time_"];
        chart3.data.datasets.push({
          label: "On",
          backgroundColor: "#90caf9",
          borderColor: "#5d99c6",
          borderWidth: 3,
          data: [events[x]["time_"]],
        });
      }
      if (events[x]["type"] == 1) {
        total_off = total_off + events[x]["time_"];
        chart3.data.datasets.push({
          label: "Off",
          backgroundColor: "#ff6090",
          borderColor: "#b0003a",
          borderWidth: 3,
          data: [events[x]["time_"]],
        });
      }
      if (events[x]["type"] == 2) {
        total_ilde = total_ilde + events[x]["time_"];
        chart3.data.datasets.push({
          label: "Idle",
          backgroundColor: "#9a67ea",
          borderColor: "#320b86",
          borderWidth: 3,
          data: [events[x]["time_"]],
        });
      }
    }
    chart_1.data.datasets = [];
    for (let x in chart__) {
      chart_1.data.datasets.push({
        label: "Recorrido " + x,
        fill: true,
        borderColor: colors_line[x],
        backgroundColor: color_sombra[x],
        steppedLine: true,
        data: chart__[x],
      });
    }
    chart_1.update();

    chart_2.data.datasets[0].data[0] = total_drive;
    chart_2.data.datasets[0].data[1] = total_off;
    chart_2.data.datasets[0].data[2] = total_ilde;
    chart_2.update();

    chart_3.update();

    for (let w in marks_timeout) {
      clearTimeout(marks_timeout[w]);
    }
    marks_timeout = [];
    for (let w in recorrido) {
      if (typeof recorrido[w] != "undefined") {
        recorrido[w].setMap(null);
      }
    }
    recorrido = [];
    for (let w in m_inicio) {
      if (typeof m_inicio[w] != "undefined") {
        m_inicio[w].setMap(null);
      }
    }
    m_inicio = [];
    for (let w in m_fin) {
      if (typeof m_fin[w] != "undefined") {
        m_fin[w].setMap(null);
      }
    }
    m_fin = [];
    for (let w in m_pause) {
      if (typeof m_pause[w] != "undefined") {
        m_pause[w].setMap(null);
      }
    }
    m_pause = [];

    let time_mark = 500;
    marks_timeout.push(
      setTimeout(
        function () {
          m_inicio[0] = new google.maps.Marker({
            position: c_coordenadas[0]["cors"][0],
            animation: google.maps.Animation.DROP,
            map: map,
            icon: "img/dd-start.png",
            zIndex: 2,
          });
        },
        time_mark,
        c_coordenadas
      )
    );
    time_mark = time_mark + 150;

    for (let z in c_coordenadas) {
      recorrido[z] = new google.maps.Polyline({
        path: c_coordenadas[z]["cors"],
        geodesic: true,
        strokeColor: c_coordenadas[z]["color"],
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: c_coordenadas[z]["color"],
            },
            offset: "100px",
            repeat: "100px",
          },
        ],
      });
      marks_timeout.push(
        setTimeout(
          function () {
            m_fin[z] = new google.maps.Marker({
              position:
                c_coordenadas[z]["cors"][c_coordenadas[z]["cors"].length - 1],
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/dd-end.png",
              zIndex: 1,
            });
          },
          time_mark,
          z
        )
      );
      time_mark = time_mark + 150;
    }
    for (let z in mark_pause) {
      marks_timeout.push(
        setTimeout(
          function () {
            m_pause[z] = new google.maps.Marker({
              position: mark_pause[z],
              animation: google.maps.Animation.DROP,
              map: map,
              icon: "img/dd-pause.png",
              zIndex: 0,
            });
          },
          time_mark,
          z
        )
      );
      time_mark = time_mark + 150;
    }
    map.fitBounds(bounds);
    lista_eventos.scrollTop = 0;
    // ECM
    let ecm_time_ini = recorridos[t_selected]["pos_"][0]["fecha_inicio_"];
    let ecm_time_fin =
      recorridos[t_selected]["pos_"][recorridos[t_selected]["pos_"].length - 1][
        "fecha_fin_"
      ];
    let fuel_ini = 0,
      fuel_fin = 0;
    let time_base,
      data_base,
      date_now = 0,
      last_data = 0,
      last_ = 0;
    if (typeof ecm.total_fuel_1708 != "undefined") {
      if (ecm.total_fuel_1708.length > 0) {
        for (let x in ecm.total_fuel_1708) {
          if (x == 0) {
            data_base = ecm.total_fuel_1708[x][0];
            time_base = ecm.total_fuel_1708[x][1] * 60;
            date_now = time_base;
            last_data = data_base;
          } else {
            date_now = time_base + ecm.total_fuel_1708[x][1] * 60;
            last_data = data_base + ecm.total_fuel_1708[x][0];
          }
          if (date_now > ecm_time_fin && fuel_ini != 0) {
            fuel_fin = last_data;
            break;
          }
          if (date_now > ecm_time_ini && fuel_ini == 0) {
            if (date_now - ecm_time_ini < 5 * 60) {
              fuel_ini = last_data;
            } else if (x > 0) {
              fuel_ini = last_;
            }
          } else last_ = last_data;
        }
      }
    }
    recorridos[t_selected]["fuel_1708"] =
      fuel_fin - fuel_ini > 0 ? fuel_fin - fuel_ini : 0;
    //console.log("fuel_1708",fuel_ini,fuel_fin);

    (fuel_ini = 0), (fuel_fin = 0);
    last_ = 0;
    if (typeof ecm.total_fuel_1939 != "undefined") {
      if (ecm.total_fuel_1939.length > 0) {
        for (let x in ecm.total_fuel_1939) {
          if (x == 0) {
            data_base = ecm.total_fuel_1939[x][0];
            time_base = ecm.total_fuel_1939[x][1] * 60;
            date_now = time_base;
            last_data = data_base;
          } else {
            date_now = time_base + ecm.total_fuel_1939[x][1] * 60;
            last_data = data_base + ecm.total_fuel_1939[x][0];
          }
          if (date_now > ecm_time_fin && fuel_ini != 0) {
            fuel_fin = last_data;
            break;
          }
          if (date_now > ecm_time_ini && fuel_ini == 0) {
            if (date_now - ecm_time_ini < 5 * 60) {
              fuel_ini = last_data;
            } else if (x > 0) {
              fuel_ini = last_;
            }
          } else last_ = last_data;
        }
      }
    }
    recorridos[t_selected]["fuel_1939"] =
      fuel_fin - fuel_ini > 0 ? fuel_fin - fuel_ini : 0;
    //console.log("fuel_1939",fuel_ini,fuel_fin);

    get_ruta(c_coordenadas);
  }
  function event_select(e) {
    if (e.target.id.split("_")[0] == "events") {
      selected_event = e.target.id.split("_")[1];
      map.panTo({
        lat: events[selected_event]["pos"][0],
        lng: events[selected_event]["pos"][1],
      });
      map.setZoom(16);
    }
  }
  let route_stat = false;
  let saved_ok = false;
  let saved_type, saved_text, route_name;
  let geo_status = "Desconocida";
  function route_travel(e) {
    route_stat = true;
  }
  async function route_save(e) {
    //recorridos[t_selected]["pos_"]= await routes_api(route_selected_);
    let z, out;
    geo_status = route_name.value;
    for (let x in recorridos[t_selected]["pos_"]) {
      if (x == 0) {
        z = {
          pos_start: recorridos[t_selected]["pos_"][x]["pos_start"],
          pos: [],
          stops: [],
        };
        for (let w in recorridos[t_selected]["pos_"][x]["pos"]) {
          z["pos"].push([
            recorridos[t_selected]["pos_"][x]["pos"][w][0],
            recorridos[t_selected]["pos_"][x]["pos"][w][1],
          ]);
        }
      } else {
        for (let w in recorridos[t_selected]["pos_"][x]["pos"]) {
          out = [
            recorridos[t_selected]["pos_"][x]["pos"][w][0],
            recorridos[t_selected]["pos_"][x]["pos"][w][1],
          ];
          out[0] = recorridos[t_selected]["pos_"][x]["pos_start"][0] - out[0];
          out[1] = recorridos[t_selected]["pos_"][x]["pos_start"][1] - out[1];
          out[0] = z["pos_start"][0] - out[0];
          out[1] = z["pos_start"][1] - out[1];
          z["pos"].push(out);
        }
      }
    }
    for (let x in events) {
      if (events[x]["type"] == 0 && x != 0 && events[x]["geo_type"] == 3) {
        z["stops"].push(events[x]["pos"]);
      }
    }
    socket_query.emit("route_save", route_name.value, z, 0, (data) => {
      if (data == "ok") {
        route_stat = false;
        saved_type = "alert-success";
        saved_text = "Ruta Guardada";
        saved_ok = true;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }

  let route_selected_ = [];
  async function get_ruta(ruta_ev) {
    let ruta_ev_ = [];
    let ruta_ev_f = [];
    for (let x in ruta_ev) {
      ruta_ev_ = ruta_ev_.concat(ruta_ev[x].cors);
    }
    for (let x in ruta_ev_) {
      ruta_ev_f.push([ruta_ev_[x].lat, ruta_ev_[x].lng]);
    }
    route_selected_ = [...ruta_ev_f];
    //console.log(ruta_ev_f);
    //console.log(await routes_api(ruta_ev_f));

    for (let x in $route_list) {
      let geo_org = geo_search(
        $route_list[x][2][0] / 10000,
        $route_list[x][2][1] / 10000
      );
      let geo_dest = geo_search(
        $route_list[x][3][0] / 10000,
        $route_list[x][3][1] / 10000
      );
      if (
        recorridos[t_selected]["pos_"][0]["id_from"] == geo_org[2] &&
        recorridos[t_selected]["pos_"][
          recorridos[t_selected]["pos_"].length - 1
        ]["id_to"] == geo_dest[2]
      ) {
        //console.log($route_list[x]);
        //console.log(geo_org,geo_dest);
        let route;
        try {
          route = await routes_db.getItem($route_list[x][0]);
        } catch (e) {
          console.log("Error leyendo routa", e);
        }

        let lat_org = route["pos_start"][0];
        let lng_org = route["pos_start"][1];
        let coordenadas = [];
        for (let x in route["pos"]) {
          let lat = (lat_org - route["pos"][x][0]) / 10000;
          let lng = (lng_org - route["pos"][x][1]) / 10000;
          coordenadas.push([lat, lng]);
        }
        //console.log(coordenadas);
        let result = ruta_comp2(ruta_ev_f, coordenadas);
        //console.log("Ruta resultado ",result);
        if (result > 90) {
          geo_status = $route_list[x][1];
        }
      }
    }
    //console.log(recorridos[t_selected]);
  }
  var error = 0.00001;
  function ruta_comp2(ruta_ev, ruta_org) {
    let org_pos = 0;
    let x1,
      y1,
      x2,
      y2,
      x,
      y,
      m,
      b,
      p_rec,
      x_rec,
      y_rec,
      dentro_x,
      dentro_y,
      puntos_ev;

    puntos_ev = ruta_ev.length;
    for (var z = 0; z < ruta_ev.length; z++) {
      x = ruta_ev[z][0];
      y = ruta_ev[z][1];
      for (var w = org_pos; w < ruta_org.length - 1; w++) {
        x1 = ruta_org[w][0];
        y1 = ruta_org[w][1];
        x2 = ruta_org[w + 1][0];
        y2 = ruta_org[w + 1][1];
        m = (y2 - y1) / (x2 - x1);
        if (isFinite(m)) {
          b = y1 - m * x1;
          p_rec = Math.abs(m * x - y + b) / Math.sqrt(Math.pow(m, 2) + 1);
          x_rec = (x + m * y - b * m) / (Math.pow(m, 2) + 1);
          y_rec = (Math.pow(m, 2) * y + m * x + b) / (Math.pow(m, 2) + 1);
        } else {
          p_rec = Math.abs(x1 - x);
          x_rec = x1;
          y_rec = y;
        }
        dentro_x =
          x1 > x2
            ? x_rec <= x1 + error && x_rec >= x2 - error
              ? true
              : false
            : x1 < x2
            ? x_rec >= x1 - error && x_rec <= x2 + error
              ? true
              : false
            : true;
        dentro_y =
          y1 > y2
            ? y_rec <= y1 + error && y_rec >= y2 - error
              ? true
              : false
            : y1 < y2
            ? y_rec >= y1 - error && y_rec <= y2 + error
              ? true
              : false
            : true;
        p_rec = ((p_rec * Math.PI) / 180) * 6371;
        if (dentro_x && dentro_y) {
          // Verifica que el punto este dentreo del rango de x1 x2 y1 y2
          if (p_rec < 0.5) {
            // KM de separacion
            puntos_ev--;
            org_pos = w > 1 ? w - 2 : 0;
            break;
          }
        }
        // Algoritmo para fuera de angulo (Prototipo)
        let d1 = Math.sqrt(Math.pow(y2 - y, 2) + Math.pow(x2 - x, 2)); // Distancia a puntos.
        d1 = ((d1 * Math.PI) / 180) * 6371;
        if (d1 < 0.5) {
          // KM de separacion
          puntos_ev--;
          org_pos = w > 1 ? w - 2 : 0;
          break;
        }
        let d2 = Math.sqrt(Math.pow(y1 - y, 2) + Math.pow(x1 - x, 2)); // Distancia a puntos.
        d2 = ((d2 * Math.PI) / 180) * 6371;
        if (d2 < 0.5) {
          // KM de separacion
          puntos_ev--;
          org_pos = w > 1 ? w - 2 : 0;
          break;
        }
      }
    }
    console.log(puntos_ev, ruta_ev.length);
    return ruta_ev.length > 2
      ? ((ruta_ev.length - puntos_ev) / ruta_ev.length) * 100
      : 0;
  }
  async function routes_api(ruta_ev_f) {
    let ruta_api = "";
    let counter_api = 0;
    let new_route = [];
    let rest_route, par_route;
    new_route.push([ruta_ev_f[0][0], ruta_ev_f[0][1]]);
    for (let x in ruta_ev_f) {
      ruta_api = ruta_api + ruta_ev_f[x][0] + "," + ruta_ev_f[x][1] + "|";
      counter_api++;
      if (counter_api > 99) {
        ruta_api = ruta_api.slice(0, -1);
        rest_route = await fetch(
          "https://roads.googleapis.com/v1/snapToRoads?path=" +
            ruta_api +
            "&interpolate=false&key="
        ); //Colocar key si esta en uso
        par_route = await rest_route.json();
        console.log(par_route);
        if (typeof par_route.snappedPoints != "undefined") {
          for (let y in par_route.snappedPoints) {
            new_route.push([
              par_route.snappedPoints[y].location.latitude,
              par_route.snappedPoints[y].location.longitude,
            ]);
          }
        }
        ruta_api = "";
        counter_api = 0;
      }
    }
    if (ruta_api != "") {
      ruta_api = ruta_api.slice(0, -1);
      rest_route = await fetch(
        "https://roads.googleapis.com/v1/snapToRoads?path=" +
          ruta_api +
          "&interpolate=false&key="
      ); //Colocar key si esta en uso
      par_route = await rest_route.json();
      console.log(par_route);
      if (typeof par_route.snappedPoints != "undefined") {
        for (let y in par_route.snappedPoints) {
          new_route.push([
            par_route.snappedPoints[y].location.latitude,
            par_route.snappedPoints[y].location.longitude,
          ]);
        }
      }
    }
    new_route.push([
      ruta_ev_f[ruta_ev_f.length - 1][0],
      ruta_ev_f[ruta_ev_f.length - 1][1],
    ]);
    console.log(new_route);

    let out_vector = [];
    let lat_start = Math.round(new_route[0][0] * 10000);
    let lng_start = Math.round(new_route[0][1] * 10000);
    out_vector.push({ pos_start: [lat_start, lng_start], pos: [[0, 0]] });
    for (let x in new_route) {
      let lat_ = Math.round(new_route[x][0] * 10000);
      let lng_ = Math.round(new_route[x][1] * 10000);
      if (
        lat_start != lat_ &&
        lng_start != lng_ &&
        (out_vector[0].pos[out_vector[0].pos.length - 1][0] !=
          lat_start - lat_ ||
          out_vector[0].pos[out_vector[0].pos.length - 1][1] !=
            lng_start - lng_)
      ) {
        out_vector[0].pos.push([lat_start - lat_, lng_start - lng_]);
      }
    }
    return out_vector;
  }

  let unit_filter_mode = false;
  let filter_unit;
  function unit_filter() {
    unit_filter_mode = unit_filter_mode ? false : true;
    if (unit_filter_mode) filter_unit = "";
  }
  function focus(i) {
    i.focus();
  }
  function run_unit_filter(event) {
    for (let x in units_list_p) {
      if ($nicks_list[units_list_p[x]] == event.target.value) {
        let event_ = {
          target: { id: x },
          toElement: { pathname: "/" + units_list_p[x] },
        };
        selected = x;
        maping(event_);
        unit_filter_mode = false;
        filter_unit = "";
        let unidad_l = document.getElementById(x);
        if (unidad_l != null) {
          unidad_l.scrollIntoView();
        }
      }
    }
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-50 no-gutters">
    <div class="col-md-auto h-100" style="min-width:10rem">
      <div class="card h-100 bg-light card-b">
        <div class="d-flex flex-column h-100">
          <div class="card-header bg-dark text-white b-0">
            <div class="d-flex justify-content-between">
              <div>
                {#if !unit_filter_mode}
                  <i class="material-icons md-85">commute</i> Unidades
                {:else}
                  <input
                    list="lista_unidades"
                    placeholder="Unidades"
                    use:focus
                    bind:value={filter_unit}
                    on:change={run_unit_filter}
                  />
                  <datalist id="lista_unidades">
                    {#each units_list_p as item, i}
                      <option
                        value={typeof $nicks_list[item] != "undefined"
                          ? $nicks_list[item]
                          : item}
                      />{/each}
                  </datalist>
                {/if}
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-link p-0"
                  on:click={unit_filter}
                >
                  <i class="material-icons md-85" style="color:#fafafa"
                    >{unit_filter_mode ? "cancel" : "search"}</i
                  >
                </button>
              </div>
            </div>
          </div>
          <div class="card-body flex-grow-1 overflow-auto">
            <div class="list-group">
              {#each units_list_p as item, i}
                <a
                  id={i}
                  href="/{item}"
                  class:list-group-item-warning={selected == i}
                  class:active_={selected == i}
                  on:click|preventDefault={maping}
                  class="list-group-item list-group-item-action"
                  >{typeof $nicks_list[item] != "undefined"
                    ? $nicks_list[item]
                    : item}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md h-100 ">
      <div class="row h-100 no-gutters">
        <div class="col-md-7 h-100">
          <div class="h-100" id="map" />
        </div>
        <div class="col-md-5 h-100">
          <div class="card h-100 bg-light">
            <div class="d-flex flex-column h-100">
              <div class="card-header b-0">
                <i class="material-icons md-85">event_available</i> Eventos
                <span class="badge badge-pill badge-info">{events.length}</span>
              </div>
              <div
                class="card-body  flex-grow-1 overflow-auto"
                bind:this={lista_eventos}
              >
                <table
                  class="table table-sm table-striped"
                  style="font-size:0.75rem"
                >
                  <tbody on:click={event_select}>
                    {#each events as item, i}
                      <tr
                        ><td
                          id="events_{i}"
                          class:table-info={selected_event == i}
                          ><i
                            class="material-icons {item.type == 0
                              ? 'green'
                              : item.type == 1
                              ? 'gray'
                              : item.type == 2
                              ? 'yei'
                              : 'red'}"
                            >{item.type == 0
                              ? "play_circle_filled"
                              : item.type == 1
                              ? "stop"
                              : item.type == 2
                              ? "pause_circle_filled"
                              : "report"}</i
                          > <span class="blue">{item.date}: </span><i
                            class="material-icons md-18"
                            style="color:{item.color};">local_offer</i
                          >
                          <strong
                            >{item.type == 0
                              ? "Salió de"
                              : item.type == 1
                              ? "Llegó a"
                              : item.type == 2
                              ? "En espera"
                              : "Alerta de Velocidad"}
                            {@html typeof item.name != "undefined"
                              ? "<mark>" + item.name + "</mark>,"
                              : ""}</strong
                          >
                          {item.type == 0
                            ? "viajó por " +
                              item.time +
                              ", " +
                              item.distance +
                              "km"
                            : item.type == 1
                            ? "detenido por " + item.time
                            : item.type == 2
                            ? "por " + item.time
                            : item.vel + "km/hr"}.</td
                        ></tr
                      >
                    {/each}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row h-50 no-gutters">
    <div class="col-md-6 h-100 ">
      <div class="card h-100 bg-light">
        <div class="d-flex flex-column h-100">
          <div class="card-header b-0 ">
            <i class="material-icons md-85">map</i> Viajes
            <span class="badge badge-pill badge-success"
              >{recorridos.length}</span
            >
          </div>
          <div
            class="card-body flex-grow-1 overflow-auto"
            id="viajes_list"
            bind:this={viajes_list}
          >
            {#if recorridos_ok == 1}
              {#each recorridos as item, i}
                <div class="card m-3" style="font-size:0.8rem">
                  <p class="card-header b-0" id="trip_{i}" on:click={trip_map}>
                    {item.fecha_inicio} | {item.from}<i
                      class="material-icons  bblue">forward</i
                    >{item.fecha_fin} | {item.to}<i
                      class="material-icons orange"
                      tooltip="Viaje circular">{item.travel_type}</i
                    >
                  </p>
                  {#if t_selected == i}
                    <div class="card-body m-3" bind:this={viaje_detail}>
                      <div class="card-text">
                        <div>
                          <span class="badge travel badge-pill badge-primary"
                            >{item.recorridos} recorrido{item.recorridos > 1
                              ? "s"
                              : ""}</span
                          >
                          <span class="badge travel badge-pill badge-success"
                            >{time_code(item.duracion)} encendida</span
                          >
                          <span class="badge travel badge-pill badge-dark"
                            >{item.distance}km</span
                          >
                        </div>
                        {#if item.fuel_1708 != 0 && typeof item.fuel_1708 != "undefined"}
                          <div>
                            <span class="badge travel badge-pill badge-info"
                              >j1708</span
                            >
                            <span class="badge travel badge-pill badge-info"
                              >Consumo {item.fuel_1708} Litros</span
                            >
                            <span class="badge travel badge-pill badge-info"
                              >Rendimiento {Math.round(
                                (item.distance / item.fuel_1708) * 100
                              ) / 100}</span
                            >
                          </div>
                        {/if}
                        {#if item.fuel_1939 != 0 && typeof item.fuel_1939 != "undefined"}
                          <div>
                            <span class="badge travel badge-pill badge-dark"
                              >j1939</span
                            >
                            <span class="badge travel badge-pill badge-dark"
                              >Consumo {item.fuel_1939} Litros</span
                            >
                            <span class="badge travel badge-pill badge-dark"
                              >Rendimiento {Math.round(
                                (item.distance / item.fuel_1939) * 100
                              ) / 100}</span
                            >
                          </div>
                        {/if}
                        <div
                          class="d-flex justify-content-end align-items-center mt-2"
                        >
                          <strong class="mx-2">Ruta:</strong>
                          <span
                            on:click={(e) => {
                              if (geo_status == "Desconocida") {
                                route_travel(e);
                              }
                            }}
                            style={geo_status == "Desconocida"
                              ? "color:#2196f3"
                              : ""}
                            class:flex-grow-1={route_stat}
                          >
                            {#if !route_stat}
                              <strong>{geo_status}</strong>
                            {:else}
                              <input
                                type="text"
                                value="{item.from} a {item.to}"
                                class="form-control mini"
                                bind:this={route_name}
                              />
                            {/if}
                          </span>
                          {#if route_stat}
                            <button
                              on:click={route_save}
                              type="button"
                              class="btn btn-success mx-2">Guardar</button
                            >
                          {/if}
                        </div>
                      </div>
                    </div>
                  {/if}
                </div>
              {/each}
            {/if}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 h-100 ">
      <div class="row h-50 no-gutters">
        <div class="col-md-12 h-100 ">
          <div class="card h-100">
            <div class="card-body h-100 flex-grow-1 overflow-auto">
              <canvas id="chart1" />
            </div>
          </div>
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="col-md-3 h-100 ">
          <div class="card h-100">
            <div class="card-body h-100 flex-grow-1 overflow-auto">
              <canvas id="chart2" />
            </div>
          </div>
        </div>
        <div class="col-md-9 h-100 ">
          <div class="card h-100">
            <div class="card-body h-100 flex-grow-1 overflow-auto">
              <canvas id="chart3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .card-b {
    border: 0px;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .badge.travel {
    font-size: 0.75rem;
    margin-bottom: 0.4em;
  }
  .btn {
    font-size: 0.75rem;
  }
  .form-control.mini {
    font-size: 0.75rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }
  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  [tooltip] {
    position: relative;
  }
  [tooltip]:before {
    content: attr(tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    padding: 8px;
    transform: translateX(-50%) scale(0);
    transform-origin: top;
    background: #757575;
    color: white;
    border-radius: 2px;
    font-size: 0.6rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    z-index: 1;
  }
  [tooltip]:hover:before {
    transform: translateX(-50%) scale(1);
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
