<script>
  export let socket_query;
  import {
    geos,
    geos_class_list,
    geos_class,
    zones_list,
    zones,
    geos_joined,
    map_ready,
    geos_full,
    geo_class_full,
  } from "./stores.js";
  import { onMount, afterUpdate } from "svelte";

  afterUpdate(() => {
    count_geo();
    count_geo_();
  });

  var map = 0;
  let selected = -1;
  let selected_ = -1;
  //var geos = [];
  var circles = [];
  let mount_complete = false;

  function initMap() {
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 19.443406, lng: -99.190197 },
      zoom: 6,
    });
    map.addListener("center_changed", function () {
      geo_dinamic();
    });
    map.addListener("zoom_changed", function () {
      geo_dinamic();
    });
  }

  onMount(() => {
    mount_complete = true;
  });
  $: {
    if ($map_ready == 1 && mount_complete) {
      initMap();
      console.log("mapa cargado");
    }
  }

  var geo_name = "";
  var geo_name_org = "";
  var geo_key = "";
  var geo_data = "";
  let cir_selected = [];
  let nick_list = [];
  var geo_key_joined = "";
  async function maping(event) {
    new_nick_mode = false;
    geo_join_name = "";
    if (geo_new_mode) {
      new_geo();
    }
    if (zone_new_mode) {
      new_zone();
    }
    for (let x in circles) {
      circles[x].setMap(null);
    }
    circles = [];
    cir_selected = [];
    let source = event.path[1].id;
    if (source == "geo_wo_c") {
      selected = event.target.id;
      selected_ = -1;
    } else {
      selected_ = event.target.id;
      selected = -1;
    }
    let geo_s = event.toElement.pathname.split("/")[1];
    geo_key = geo_s;
    geo_key_joined = geo_s;
    //var geo = source=="geo_wo_c"? JSON.parse(await geos_db.getItem(geo_s)) : JSON.parse(await geos_class_db.getItem(geo_s)) ;geo_data=geo;

    //var geo = JSON.parse(await geos_db.getItem(geo_s));
    var geo = $geos_full[geo_s];
    geo_data = geo;
    //var geo_ = source=="geo_wo_c"? geo : JSON.parse(await geos_class_db.getItem(geo_s)) ;
    var geo_ = source == "geo_wo_c" ? geo : $geo_class_full[geo_s];
    let bounds = new google.maps.LatLngBounds();

    if (geo != null) {
      let anti_f = 10;
      let last_join = "";
      while (typeof geo.join != "undefined" || anti_f == 0) {
        last_join = geo.join;
        geo_s = geo.join;
        geo_key_joined = geo.join;
        //geo = JSON.parse(await geos_db.getItem(geo.join));
        geo = $geos_full[geo.join];
        anti_f--;
      }
      for (let y in geo["pos"]) {
        let lat = geo["pos"][y][0];
        let lng = geo["pos"][y][1];
        cir_selected.push([lat, lng]);

        bounds.extend({ lat: lat + 0.000898, lng: lng });
        bounds.extend({ lat: lat, lng: lng + 0.000898 });
        bounds.extend({ lat: lat - 0.000898, lng: lng });
        bounds.extend({ lat: lat, lng: lng - 0.000898 });

        circles.push(
          new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            center: { lat: lat, lng: lng },
            radius: 100,
            zIndex: 3,
          })
        );
        circles[circles.length - 1].addListener("click", async function () {
          console.log("click", geo_s);
        });
      }
    }
    map.fitBounds(bounds);
    //map.setZoom(17);
    geo_name = source == "geo_wo_c" ? event.target.text : geo_["name"];
    geo_name_org = event.target.text;
    geo_c_sel = source == "geo_wo_c" ? 1 : geo_["type"];
    geo_c_sel_org = source == "geo_wo_c" ? 1 : geo_["type"];

    nick_list = [];
    if (typeof $geos_class[geo_s] != "undefined") {
      if (geo_key != geo_s) {
        nick_list.push(geo_s);
      }
    }
    if (typeof $geos_joined[geo_s] != "undefined") {
      for (let x in $geos_joined[geo_s]) {
        if (typeof $geos_class[$geos_joined[geo_s][x]] != "undefined") {
          if (geo_key != $geos_joined[geo_s][x]) {
            nick_list.push($geos_joined[geo_s][x]);
          }
        }
      }
    }
    //console.log("geo nicks",nick_list)
  }

  async function maping_zones(event) {
    if (geo_new_mode) {
      new_geo();
    }
    if (zone_new_mode) {
      new_zone();
    }
    for (let x in circles) {
      circles[x].setMap(null);
    }
    circles = [];
    cir_selected = [];
    let source = event.path[1].id;
    selected_ = event.target.id;
    selected = -1;
    let geo_s = event.toElement.pathname.split("/")[1];
    geo_key = geo_s;
    var geo = { pos: $zones[geo_s][0] };
    geo_data = geo;
    let bounds = new google.maps.LatLngBounds();

    if (geo != null) {
      for (let y in geo["pos"]) {
        let lat = geo["pos"][y][0];
        let lng = geo["pos"][y][1];
        cir_selected.push([lat, lng]);

        let ventana = ($zones[geo_s][2] * 0.00001) / 1.11;
        bounds.extend({ lat: lat + ventana, lng: lng });
        bounds.extend({ lat: lat, lng: lng + ventana });
        bounds.extend({ lat: lat - ventana, lng: lng });
        bounds.extend({ lat: lat, lng: lng - ventana });

        circles.push(
          new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            center: { lat: lat, lng: lng },
            radius: $zones[geo_s][2],
            zIndex: 2,
          })
        );
        circles[circles.length - 1].addListener("click", async function () {
          console.log("click", geo_s);
        });
      }
    }
    map.fitBounds(bounds);
    geo_name = $zones[geo_s][1];
    geo_name_org = event.target.text;
    geo_c_sel = -1;
    geo_c_sel_org = -1;
  }

  let lista_geo;
  let geo_count = 0;
  function count_geo() {
    if (typeof lista_geo != "undefined") {
      geo_count = lista_geo.getElementsByClassName("g_list").length;
    }
  }

  let lista_geo_;
  let geo_count_ = 0;
  function count_geo_() {
    if (typeof lista_geo_ != "undefined") {
      geo_count_ = lista_geo_.getElementsByClassName("g2_list").length;
    }
  }
  let geo_c_sel = 1;
  let geo_c_sel_org = 1;
  let saved_ok = false;
  let saved_text = "inicio",
    saved_type = "alert-success";
  function save_geo() {
    if (geo_c_sel > 0) {
      socket_query.emit(
        "geo_save",
        geo_key,
        geo_name,
        geo_c_sel,
        geo_data,
        (data) => {
          if (data == "ok") {
            save_btn = "disabled";
            save_btn_c = "btn-secondary";
            saved_ok = true;
            saved_text = "Geocerca guardada";
            saved_type = "alert-success";
            geo_name_org = geo_name;
            geo_c_sel_org = geo_c_sel;
            setTimeout(function () {
              saved_ok = false;
            }, 3000);
          }
        }
      );
    } else {
      socket_query.emit("zone_save", geo_key, geo_name, (data) => {
        if (data == "ok") {
          save_btn = "disabled";
          save_btn_c = "btn-secondary";
          saved_ok = true;
          saved_text = "Geo-zona guardada";
          saved_type = "alert-success";
          geo_name_org = geo_name;
          geo_c_sel_org = geo_c_sel;
          setTimeout(function () {
            saved_ok = false;
          }, 3000);
        }
      });
    }
  }
  function edit_geo() {
    edit_mode = !edit_mode;
    for (let x in circles) {
      circles[x].setMap(null);
    }
    circles = [];

    if (edit_mode) {
      for (let x in cir_selected) {
        circles.push(
          new google.maps.Circle({
            strokeColor: "#bd00ff",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#bd00ff",
            fillOpacity: 0.35,
            map: map,
            center: { lat: cir_selected[x][0], lng: cir_selected[x][1] },
            radius: 100,
            zIndex: 3,
            draggable: true,
          })
        );
      }
    } else {
      geo_dinamic();
    }
  }
  function save_edit_geo() {
    let new_geo = [];
    for (let x in circles) {
      let s = circles[x].getCenter();
      new_geo.push([s.lat(), s.lng()]);
    }
    socket_query.emit("geo_update", geo_key_joined, new_geo, (data) => {
      if (data == "ok") {
        save_btn = "disabled";
        save_btn_c = "btn-secondary";
        saved_ok = true;
        saved_text = "Geocerca guardada";
        saved_type = "alert-success";
        setTimeout(function () {
          saved_ok = false;
        }, 3000);

        cir_selected = [...new_geo];
        edit_mode = !edit_mode;
        for (let x in circles) {
          circles[x].setMap(null);
        }
        circles = [];
        //geo_dinamic();
      }
    });
  }
  $: {
    $zones_list;
    $geos;
    count_geo();
    if (map != 0) {
      geo_dinamic();
    }
  }
  let save_btn = "disabled";
  let save_btn_c = "btn-secondary";
  $: {
    if (geo_name != geo_name_org || geo_c_sel != geo_c_sel_org) {
      save_btn = "";
      save_btn_c = "btn-success";
    } else {
      save_btn = "disabled";
      save_btn_c = "btn-secondary";
    }
    //saved_ok = false;
  }
  $: {
    $geos_class_list;
    count_geo_();
  }
  function geo_dinamic() {
    let event_;
    if (edit_mode) return 0;
    if (map.getZoom() > 12) {
      let window_map = map.getBounds().toJSON();
      for (let x in circles) {
        let c_lat = circles[x].getCenter().toJSON().lat;
        let c_lng = circles[x].getCenter().toJSON().lng;
        if (
          !(
            ((window_map.north > window_map.south &&
              c_lat > window_map.south &&
              c_lat < window_map.north) ||
              (window_map.north < window_map.south &&
                c_lat < window_map.south &&
                c_lat > window_map.north)) &&
            ((window_map.east > window_map.west &&
              c_lng > window_map.west &&
              c_lng < window_map.east) ||
              (window_map.east < window_map.west &&
                c_lng < window_map.east &&
                c_lng > window_map.west))
          )
        ) {
          circles[x].setMap(null);
          circles.splice(x, 1);
        }
      }
      //circles = [];

      for (let x in $geos) {
        for (let y in $geos[x][1]) {
          if (
            ((window_map.north > window_map.south &&
              $geos[x][1][y][0] > window_map.south &&
              $geos[x][1][y][0] < window_map.north) ||
              (window_map.north < window_map.south &&
                $geos[x][1][y][0] < window_map.south &&
                $geos[x][1][y][0] > window_map.north)) &&
            ((window_map.east > window_map.west &&
              $geos[x][1][y][1] > window_map.west &&
              $geos[x][1][y][1] < window_map.east) ||
              (window_map.east < window_map.west &&
                $geos[x][1][y][1] < window_map.east &&
                $geos[x][1][y][1] > window_map.west))
          ) {
            let c_found = false;
            for (let z in circles) {
              if (
                circles[z].getCenter().toJSON().lat == $geos[x][1][y][0] &&
                circles[z].getCenter().toJSON().lng == $geos[x][1][y][1]
              ) {
                c_found = true;
              }
            }
            if (!c_found) {
              let color_c = "#79b700";
              for (let z in cir_selected) {
                if (
                  cir_selected[z][0] == $geos[x][1][y][0] &&
                  cir_selected[z][1] == $geos[x][1][y][1]
                ) {
                  color_c = "#FF0000";
                }
              }
              circles.push(
                new google.maps.Circle({
                  strokeColor: color_c,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: color_c,
                  fillOpacity: 0.35,
                  map: map,
                  center: { lat: $geos[x][1][y][0], lng: $geos[x][1][y][1] },
                  radius: 100,
                  zIndex: 3,
                })
              );
              circles[circles.length - 1].addListener(
                "click",
                async function () {
                  //let g_class = JSON.parse(await geos_class_db.getItem($geos[x][0]));
                  if (typeof $geos_class[$geos[x][0]] == "undefined") {
                    if (typeof $geos_joined[$geos[x][0]] == "undefined") {
                      event_ = {
                        toElement: { pathname: "/" + $geos[x][0] },
                        path: [{}, { id: "geo_wo_c" }],
                        target: {
                          text:
                            "Geocerca_automatica-" + $geos[x][0].split("-")[0],
                        },
                      };
                    } else {
                      let found_g = false;
                      for (let h in $geos_joined[$geos[x][0]]) {
                        if (
                          typeof $geos_class[$geos_joined[$geos[x][0]][h]] !=
                          "undefined"
                        ) {
                          event_ = {
                            toElement: {
                              pathname: "/" + $geos_joined[$geos[x][0]][h],
                            },
                            path: [{}, { id: "geo_w_c" }],
                            target: {
                              text:
                                $geos_class[$geos_joined[$geos[x][0]][h]][0],
                            },
                          };
                          found_g = true;
                          break;
                        }
                      }
                      if (!found_g) {
                        event_ = {
                          toElement: { pathname: "/" + $geos[x][0] },
                          path: [{}, { id: "geo_wo_c" }],
                          target: {
                            text:
                              "Geocerca_automatica-" +
                              $geos[x][0].split("-")[0],
                          },
                        };
                      }
                    }
                  } else {
                    event_ = {
                      toElement: { pathname: "/" + $geos[x][0] },
                      path: [{}, { id: "geo_w_c" }],
                      target: { text: $geos_class[$geos[x][0]][0] },
                    };
                  }
                  maping(event_);
                }
              );
            }
          }
        }
      }

      for (let x in $zones_list) {
        for (let y in $zones_list[x][1]) {
          if (
            ((window_map.north > window_map.south &&
              $zones_list[x][1][y][0] > window_map.south &&
              $zones_list[x][1][y][0] < window_map.north) ||
              (window_map.north < window_map.south &&
                $zones_list[x][1][y][0] < window_map.south &&
                $zones_list[x][1][y][0] > window_map.north)) &&
            ((window_map.east > window_map.west &&
              $zones_list[x][1][y][1] > window_map.west &&
              $zones_list[x][1][y][1] < window_map.east) ||
              (window_map.east < window_map.west &&
                $zones_list[x][1][y][1] < window_map.east &&
                $zones_list[x][1][y][1] > window_map.west))
          ) {
            let c_found = false;
            for (let z in circles) {
              if (
                circles[z].getCenter().toJSON().lat ==
                  $zones_list[x][1][y][0] &&
                circles[z].getCenter().toJSON().lng == $zones_list[x][1][y][1]
              ) {
                c_found = true;
              }
            }
            if (!c_found) {
              let color_c = "#757575";
              for (let z in cir_selected) {
                if (
                  cir_selected[z][0] == $zones_list[x][1][y][0] &&
                  cir_selected[z][1] == $zones_list[x][1][y][1]
                ) {
                  color_c = "#FF0000";
                }
              }
              circles.push(
                new google.maps.Circle({
                  strokeColor: color_c,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: color_c,
                  fillOpacity: 0.35,
                  map: map,
                  center: {
                    lat: $zones_list[x][1][y][0],
                    lng: $zones_list[x][1][y][1],
                  },
                  radius: $zones_list[x][3],
                  zIndex: 2,
                })
              );
              circles[circles.length - 1].addListener(
                "click",
                async function () {
                  event_ = {
                    toElement: { pathname: "/" + $zones_list[x][0] },
                    path: [{}, { id: "geo_w_c" }],
                    target: { text: $zones_list[x][2] },
                  };
                  maping_zones(event_);
                }
              );
            }
          }
        }
      }
    } else {
      for (let x in circles) {
        circles[x].setMap(null);
      }
      circles = [];
    }
  }
  let geo_new_mode = false;
  let circles_new = [];
  function new_geo() {
    geo_new_mode = geo_new_mode ? false : true;
    map.setZoom(17);
    if (geo_new_mode) {
      selected = -1;
      selected_ = -1;
      circles_new.push(
        new google.maps.Circle({
          strokeColor: "#3f51b5",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#3f51b5",
          fillOpacity: 0.35,
          map: map,
          center: map.getCenter(),
          radius: 100,
          draggable: true,
          zIndex: 4,
        })
      );
      console.log("geo_new_mode on");
    } else {
      for (let x in circles_new) {
        circles_new[x].setMap(null);
      }
      circles_new = [];
      console.log("geo_new_mode off");
    }
  }
  function save_geo_new() {
    let pos_c = [
      circles_new[0].getCenter().lat(),
      circles_new[0].getCenter().lng(),
    ];
    socket_query.emit("geo_new_save", pos_c, (data) => {
      if (data == "ok") {
        new_geo();
        save_btn = "disabled";
        save_btn_c = "btn-secondary";
        saved_ok = true;
        saved_text = "Geocerca guardada";
        saved_type = "alert-success";
        geo_name_org = geo_name;
        geo_c_sel_org = geo_c_sel;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  let zone_new_mode = false;
  let geo_zone_name;
  function new_zone() {
    zone_new_mode = zone_new_mode ? false : true;
    if (zone_new_mode) {
      map.setZoom(17);
      selected = -1;
      selected_ = -1;
      circles_new.push(
        new google.maps.Circle({
          strokeColor: "#3f51b5",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#3f51b5",
          fillOpacity: 0.35,
          map: map,
          center: map.getCenter(),
          radius: 100,
          draggable: true,
          editable: true,
          zIndex: 4,
        })
      );
      geo_zone_name =
        "Geo-zona-" +
        (Math.floor(Math.random() * 100000 - 9999) + 9999).toString();
      console.log("zone_new_mode on");
    } else {
      //if(circles_new.length>0){map.setCenter(circles_new[0].getCenter())}
      for (let x in circles_new) {
        circles_new[x].setMap(null);
      }
      circles_new = [];
      console.log("zone_new_mode off");
    }
  }
  function save_geo_zone() {
    let pos_c = [
      circles_new[0].getCenter().lat(),
      circles_new[0].getCenter().lng(),
    ];
    let radio = circles_new[0].getRadius();
    socket_query.emit("geo_zone_save", pos_c, radio, geo_zone_name, (data) => {
      if (data == "ok") {
        new_zone();
        save_btn = "disabled";
        save_btn_c = "btn-secondary";
        saved_ok = true;
        saved_text = "Geo-zona guardada";
        saved_type = "alert-success";
        geo_name_org = geo_name;
        geo_c_sel_org = geo_c_sel;
        setTimeout(function () {
          saved_ok = false;
        }, 3000);
      }
    });
  }
  let f_geo_automaticas = true;
  let latlng_busca;
  function geo_busca() {
    if (typeof latlng_busca != "undefined") {
      let latlng_busca_ = latlng_busca;
      latlng_busca_ = latlng_busca_.replace(/ /g, "");
      latlng_busca_ = latlng_busca_.split(",");
      if (latlng_busca_.length == 2) {
        try {
          let lat = parseFloat(latlng_busca_[0]);
          let lng = parseFloat(latlng_busca_[1]);
          map.setCenter({ lat: lat, lng: lng });
          map.setZoom(17);
        } catch (error) {}
      }
    }
    latlng_busca = "";
  }
  function geo_check(geo) {
    if (typeof $geos_class[geo] != "undefined") {
      return false;
    } else {
      if (typeof $geos_joined[geo] != "undefined") {
        for (let z in $geos_joined[geo]) {
          if (typeof $geos_class[$geos_joined[geo][z]] != "undefined") {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    }
  }
  let new_nick_mode = false;
  let geo_join_name;
  function new_nick(id) {
    geo_join_name = "";
    new_nick_mode = true;
    console.log("new_nick", id);
  }
  function save_new_nick(id_join) {
    if (geo_join_name != "") {
      new_nick_mode = false;
      socket_query.emit("geo_join_new", id_join, (data) => {
        if (data) {
          socket_query.emit(
            "geo_save",
            data,
            geo_join_name,
            geo_c_sel,
            geo_data,
            (data) => {
              if (data == "ok") {
                save_btn = "disabled";
                save_btn_c = "btn-secondary";
                saved_ok = true;
                saved_text = "Nickname guardado";
                saved_type = "alert-success";
                setTimeout(function () {
                  saved_ok = false;
                }, 3000);
              }
            }
          );
        }
      });
    }
  }
  let lista_nombres = [];
  let lista_nombres_gps = [];
  let text_busca = "";
  $: {
    autocomplete_dir(text_busca);
  }
  async function autocomplete_dir(dir) {
    if (dir.length > 2 && !lista_nombres.includes(dir)) {
      let par = [];
      lista_nombres_gps = [];
      let dir_list = await fetch(
        "https://geoc.omnitracs.online/search?format=json&q=" + dir
      );
      dir_list = await dir_list.json();
      for (let x in dir_list) {
        par.push(
          dir_list[x].display_name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\,/g, "")
        );
        lista_nombres_gps.push([
          parseFloat(dir_list[0].lat),
          parseFloat(dir_list[0].lon),
        ]);
      }
      lista_nombres = [...par];
    } else {
      if (lista_nombres.includes(dir)) {
        let x_ = null;
        for (let x in lista_nombres) {
          if (lista_nombres[x] == dir) {
            x_ = parseInt(x);
          }
        }
        if (x_ != null) {
          map.setCenter({
            lat: lista_nombres_gps[x_][0],
            lng: lista_nombres_gps[x_][1],
          });
          map.setZoom(14);
        }
        text_busca = "";
        lista_nombres_gps = [];
      }
      lista_nombres = [];
    }
  }
  let edit_mode = false;
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<main
  role="main"
  class="container-fluid h-100 pl-0 pr-0 mdc-top-app-bar--dense-fixed-adjust mdc-typography"
>
  <div class="row h-100 no-gutters">
    <div class="col-md-auto h-100 ">
      <div class="row h-50 no-gutters">
        <div class="card h-100 bg-light card-0 w-100">
          <div class="card-header bg-dark text-white b-0">
            <i class="material-icons md-85">layers</i> Geocercas sin clasificar
            <span class="badge badge-pill badge-primary">{geo_count}</span>
          </div>
          <div class="card-body h-100 overflow-auto">
            <div class="list-group" bind:this={lista_geo} id="geo_wo_c">
              {#each $geos as item, i}
                {#if geo_check(item[0])}
                  <a
                    id={i}
                    href="/{item[0]}"
                    class:active_={selected == i}
                    on:click|preventDefault={maping}
                    class:list-group-item-warning={selected == i}
                    class="g_list list-group-item list-group-item-action"
                    >Geocerca_automatica-{item[0].split("-")[0]}</a
                  >
                {/if}
              {/each}
            </div>
          </div>
        </div>
      </div>
      <div class="row h-50 no-gutters">
        <div class="card h-100 bg-light card-0 w-100">
          <div class="card-header bg-dark text-white b-0">
            <i class="material-icons md-85">layers</i>
            {f_geo_automaticas ? "Geocercas clasificadas" : "Geo-Zonas"}
            <span class="badge badge-pill badge-success">{geo_count_}</span>
          </div>
          <div class="card-body h-100 overflow-auto">
            <div class="list-group" bind:this={lista_geo_} id="geo_w_c">
              {#if f_geo_automaticas}
                {#each $geos_class_list as item, i}
                  <a
                    id={i}
                    href="/{item[0]}"
                    class:active_={selected_ == i}
                    on:click|preventDefault={maping}
                    class:list-group-item-warning={selected_ == i}
                    class="g2_list list-group-item list-group-item-action"
                    >{item[1]}</a
                  >
                {/each}
              {:else}
                {#each $zones_list as item, i}
                  <a
                    id={i}
                    href="/{item[0]}"
                    class:active_={selected_ == i}
                    on:click|preventDefault={maping_zones}
                    class:list-group-item-warning={selected_ == i}
                    class="g2_list list-group-item list-group-item-action"
                    >{item[2]}</a
                  >
                {/each}
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md h-100">
      <div class="row h-70 no-gutters">
        <div class="h-100 w-100" id="map" />
      </div>
      <div class="row h-30 no-gutters">
        <div class="card w-100 h-100">
          <div class="d-flex flex-column h-100">
            <div class="card-header pt-1 pb-1">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <div class="btn-group" role="group">
                    <button
                      on:click|preventDefault={() => {
                        f_geo_automaticas = true;
                        selected = -1;
                        selected_ = -1;
                      }}
                      type="button"
                      class="btn {f_geo_automaticas
                        ? 'btn-info'
                        : 'btn-outline-info'} mini"
                      >Geocercas Automáticas</button
                    >
                    <button
                      on:click|preventDefault={() => {
                        f_geo_automaticas = false;
                        selected = -1;
                        selected_ = -1;
                      }}
                      type="button"
                      class="btn {!f_geo_automaticas
                        ? 'btn-info'
                        : 'btn-outline-info'} mini">Geo-Zonas</button
                    >
                  </div>
                  <input
                    type="text"
                    size="20"
                    class="ml-3 mr-3"
                    placeholder="ej. 19.3537, -99.1724"
                    bind:value={latlng_busca}
                  />
                  <button
                    on:click|preventDefault={geo_busca}
                    type="button"
                    class="btn btn-secondary mini">Buscar Coordenadas</button
                  >
                  <input
                    type="text"
                    list="lista_nombres"
                    size="30"
                    class="ml-1 mr-1"
                    placeholder="Busqueda por nombre"
                    bind:value={text_busca}
                  />
                  <datalist id="lista_nombres">
                    {#each lista_nombres as item, i}
                      <option value={item} />{/each}
                  </datalist>
                </div>
                <div class="d-flex">
                  {#if zone_new_mode}
                    <button
                      type="button"
                      on:click={save_geo_zone}
                      class="btn btn-success mini mr-2"
                    >
                      <i class="material-icons md-85">check_circle</i>
                      Guardar
                    </button>
                  {/if}
                  {#if !geo_new_mode}
                    <button
                      on:click={new_zone}
                      type="button"
                      class="btn btn-{zone_new_mode
                        ? 'warning'
                        : 'info'} mini mr-2"
                      ><i
                        class="material-icons md-85"
                        style="color:{zone_new_mode ? '' : '#f3e5f5'}"
                        >{zone_new_mode ? "not_interested" : "add_circle"}</i
                      >
                      {zone_new_mode ? "Cancelar" : "Crear Geo-Zona"}</button
                    >
                  {/if}
                  {#if geo_new_mode}
                    <button
                      type="button"
                      on:click={save_geo_new}
                      class="btn btn-success mini mr-2"
                    >
                      <i class="material-icons md-85">check_circle</i>
                      Guardar
                    </button>
                  {/if}
                  {#if !zone_new_mode}
                    <button
                      on:click={new_geo}
                      type="button"
                      class="btn btn-{geo_new_mode
                        ? 'warning'
                        : 'success'} mini"
                      ><i
                        class="material-icons md-85"
                        style="color:{geo_new_mode ? '' : '#f3e5f5'}"
                        >{geo_new_mode ? "not_interested" : "add_circle"}</i
                      >
                      {geo_new_mode ? "Cancelar" : "Crear Geocerca"}</button
                    >
                  {/if}
                </div>
              </div>
            </div>
            <div class="card-body p-2 h-100 flex-grow-1 overflow-auto">
              <div class="d-flex  flex-column">
                {#if selected != -1 || selected_ != -1}
                  <div>
                    <div class="d-inline-flex pt-2">
                      <label for="geo_name" class="col col-form-label"
                        >Nombre</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="geo_name"
                        placeholder=""
                        bind:value={geo_name}
                        size="30"
                      />
                    </div>
                    <div class="d-inline-flex pt-2">
                      {#if geo_c_sel > 0}
                        <label for="geo_class" class="col col-form-label"
                          >Clasificación</label
                        >
                        <div
                          class="btn-group btn-group-toggle ml-2"
                          id="geo_class"
                        >
                          {#if selected != -1}
                            <label
                              class="btn btn-secondary"
                              class:active={geo_c_sel == 1}
                            >
                              <input
                                type="radio"
                                bind:group={geo_c_sel}
                                value={1}
                                name="geo_c_sel"
                                autocomplete="off"
                              /> Sin Clasificar
                            </label>
                          {/if}
                          <label
                            class="btn btn-secondary"
                            class:active={geo_c_sel == 2}
                          >
                            <input
                              type="radio"
                              bind:group={geo_c_sel}
                              value={2}
                              name="geo_c_sel"
                              autocomplete="off"
                            /> Inicio/Fin de Viaje
                          </label>
                          <label
                            class="btn btn-secondary"
                            class:active={geo_c_sel == 3}
                          >
                            <input
                              type="radio"
                              bind:group={geo_c_sel}
                              value={3}
                              name="geo_c_sel"
                              autocomplete="off"
                            /> Parada Autorizada
                          </label>
                          <label
                            class="btn btn-secondary"
                            class:active={geo_c_sel == 4}
                          >
                            <input
                              type="radio"
                              bind:group={geo_c_sel}
                              value={4}
                              name="geo_c_sel"
                              autocomplete="off"
                            /> Parada No Autorizada
                          </label>
                        </div>
                      {/if}
                      <button
                        type="button"
                        on:click={save_geo}
                        class="btn ml-2 {save_btn_c}"
                        disabled={save_btn}>Guardar</button
                      >
                      {#if edit_mode}
                        <button
                          type="button"
                          on:click={save_edit_geo}
                          class="btn ml-2 btn-success">Guardar edición</button
                        >
                      {/if}
                      {#if selected_ != -1 && geo_c_sel > 0}
                        <button
                          type="button"
                          on:click={edit_geo}
                          class="btn ml-2 {!edit_mode
                            ? 'btn-warning'
                            : 'btn-danger'}"
                          >{!edit_mode ? "Editar" : "Cancelar"}</button
                        >
                      {/if}
                    </div>
                  </div>
                  <div>
                    <div class="d-inline-flex pt-0 align-items-center">
                      {#if selected == -1}
                        <span class="pr-3"><strong>Nicknames: </strong></span>
                      {/if}
                      {#each nick_list as item, i (item)}
                        {$geos_class[item][0]}{i == nick_list.length - 1 &&
                        !new_nick_mode
                          ? ""
                          : ", "}
                      {/each}
                      {#if geo_join_name != "" && !new_nick_mode}
                        {nick_list.length > 0 ? "," : ""} {geo_join_name}
                      {/if}
                      {#if new_nick_mode}
                        <input
                          class="ml-2"
                          type="text"
                          size="30"
                          bind:value={geo_join_name}
                        />
                      {/if}
                      {#if selected == -1}
                        {#if new_nick_mode}
                          <button
                            on:click={() => {
                              save_new_nick(geo_key_joined);
                            }}
                            id="new_nick"
                            type="button"
                            class="btn btn-link"
                          >
                            <i class="material-icons md-90 green">save</i> Guardar
                          </button>
                        {:else}
                          <button
                            on:click={() => {
                              new_nick(geo_key);
                            }}
                            id="new_nick"
                            type="button"
                            class="btn btn-link"
                          >
                            <i class="material-icons md-90 green"
                              >add_circle_outline</i
                            >
                          </button>
                        {/if}
                      {/if}
                    </div>
                  </div>
                {:else if zone_new_mode}
                  <div class="d-flex pt-2  ">
                    <label for="geo_name" class="col-auto col-form-label"
                      >Nombre</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="geo_name"
                      placeholder=""
                      bind:value={geo_zone_name}
                      size="30"
                    />
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
  .btn.mini {
    vertical-align: initial;
    padding: 1px 6px;
    font-size: 0.78rem;
  }
  .card-body {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
  }
  .card-0 {
    border: 0px;
  }
  .h-30 {
    height: 30%;
  }
  .h-70 {
    height: 70%;
  }
  .form-control {
    font-size: 0.85rem;
  }
  .btn {
    font-size: 0.85rem;
  }

  .card-header.b-0 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
  }
  .card-header:first-child {
    border-radius: 0;
  }

  .alert-top {
    position: fixed;
    top: 3rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.3rem;
  }
  .material-icons.md-90 {
    font-size: 1.5rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(24, 179, 45);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
  span.blue {
    color: rgb(17, 33, 255);
  }
</style>
