<script>
  export let socket_query;
  import { onMount } from "svelte";
  import { map_ready } from "./stores.js";
  //
  let map;
  let on_map = false;
  let iv, id, h;
  let parametros = new URLSearchParams(window.location.search);
  iv = parametros.get("iv");
  id = parametros.get("id");
  h = parametros.get("h");

  function initMap() {
    let bounds = new google.maps.LatLngBounds();
    bounds.extend({ lat: 32.534353, lng: -117.123783 });
    bounds.extend({ lat: 21.137926, lng: -86.740844 });
    bounds.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.addListener("mouseover", function () {
      on_map = true;
    });
    map.addListener("mouseout", function () {
      on_map = false;
    });
    map.fitBounds(bounds);

    console.log("iv", iv);
    console.log("id", id);
    console.log("h", h);
    if (iv != null && id != null && h != null) {
      socket_query.emit("mapa_travel", iv, id, h, (data) => {
        if (data == "error") {
          alert("El viaje no es válido o ha expirado.");
          window.close();
        }
      });
    }
  }
  $: {
    if ($map_ready == 1 && iv != null && id != null && h != null) {
      initMap();
      console.log("mapa cargado");
    }
  }

  socket_query.on("unit_", async function () {
    if (iv != null && id != null && h != null) {
      socket_query.emit("mapa_travel", iv, id, h, (data) => {
        if (data == "error") {
          alert("Viaje terminado");
          window.close();
        }
      });
    }
  });

  let m_inicio, m_fin, recorrido_ruta, m_inicio_ruta, m_fin_ruta, recorrido;
  let bounds_ini = false;
  socket_query.on("mapa_travel", async function (modid, monitor, routes, live) {
    let monitor_ = JSON.parse(monitor);
    let routes_ = JSON.parse(routes);
    let live_ = JSON.parse(live);

    let bounds = new google.maps.LatLngBounds();
    let zIndex = 0;

    if (typeof m_inicio != "undefined") {
      m_inicio.setMap(null);
    }
    if (typeof monitor_.travel[0].pos != "undefined") {
      m_inicio = new google.maps.Marker({
        position: {
          lat: monitor_.travel[0]["pos_start"][0] / 10000,
          lng: monitor_.travel[0]["pos_start"][1] / 10000,
        },
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/dd-start.png",
        zIndex: zIndex,
      });
    }

    let icon_status =
      live_.ingnition == 0
        ? "wht-blank"
        : live_.vel == 0
        ? "purple-blank"
        : "grn-blank";
    var image = {
      url: "img/" + icon_status + ".png",
      scaledSize: new google.maps.Size(40, 40),
      labelOrigin: new google.maps.Point(20, -10),
    };

    zIndex++;
    if (typeof m_fin != "undefined") {
      m_fin.setMap(null);
    }
    if (typeof live_ != "undefined") {
      m_fin = new google.maps.Marker({
        position: { lat: live_.lat, lng: live_.lon },
        map: map,
        animation: google.maps.Animation.DROP,
        icon: image,
        zIndex: zIndex,
        label: {
          text: modid,
          color: "#212121",
          fontSize: "13px",
          fontWeight: "bold",
        },
      });
    }

    let coordenada_ruta = [];
    if (routes_ != null) {
      let lat_org = routes_["pos_start"][0];
      let lng_org = routes_["pos_start"][1];
      for (let x in routes_["pos"]) {
        let lat = (lat_org - routes_["pos"][x][0]) / 10000;
        let lng = (lng_org - routes_["pos"][x][1]) / 10000;
        coordenada_ruta.push({ lat: lat, lng: lng });
      }
    }
    zIndex++;
    if (typeof recorrido_ruta != "undefined") {
      recorrido_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      recorrido_ruta = new google.maps.Polyline({
        path: coordenada_ruta,
        geodesic: true,
        strokeColor: "#2196f3",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_inicio_ruta != "undefined") {
      m_inicio_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      m_inicio_ruta = new google.maps.Marker({
        position: coordenada_ruta[0],
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerA.png",
        zIndex: zIndex,
      });
    }

    zIndex++;
    if (typeof m_fin_ruta != "undefined") {
      m_fin_ruta.setMap(null);
    }
    if (coordenada_ruta.length > 0) {
      m_fin_ruta = new google.maps.Marker({
        position: coordenada_ruta[coordenada_ruta.length - 1],
        map: map,
        animation: google.maps.Animation.DROP,
        icon: "img/markerB.png",
        zIndex: zIndex,
      });
    }

    let coordenada = [];
    for (let x in monitor_.travel) {
      if (typeof monitor_.travel[x].pos != "undefined") {
        for (let y in monitor_.travel[x].pos) {
          let lat =
            (monitor_.travel[x]["pos_start"][0] -
              monitor_.travel[x]["pos"][y][0]) /
            10000;
          let lng =
            (monitor_.travel[x]["pos_start"][1] -
              monitor_.travel[x]["pos"][y][1]) /
            10000;
          coordenada.push({ lat: lat, lng: lng });
          bounds.extend({ lat: lat, lng: lng });
        }
      }
    }
    zIndex++;
    coordenada.push({ lat: live_.lat, lng: live_.lon });
    if (typeof recorrido != "undefined") {
      recorrido.setMap(null);
    }
    if (coordenada.length > 0) {
      recorrido = new google.maps.Polyline({
        path: coordenada,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2.5,
        map: map,
        zIndex: zIndex,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: "#b71c1c",
            },
            offset: "100px",
            repeat: "100px",
          },
        ],
      });
    }

    if (!bounds_ini) {
      bounds_ini = true;
      if (coordenada.length > 1) {
        map.fitBounds(bounds);
        console.log("zoom1");
      } else {
        map.setZoom(16);
        map.panTo({ lat: live_.lat, lng: live_.lon });
        console.log("zoom2", { lat: live_.lat, lng: live_.lon });
      }
    } else {
      if (!on_map) {
        map.setZoom(16);
        map.panTo({ lat: live_.lat, lng: live_.lon });
      }
    }
  });
</script>

<main role="main" class="container-fluid h-100 w-100 pl-0 pr-0">
  <div class="row h-100 no-gutters">
    <div class="h-100 w-100" id="map" />
  </div>
</main>
