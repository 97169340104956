<script>
  import { onMount } from "svelte";
  import { portal_version } from "./stores.js";
  export let unit_worker;
  export let socket_query;
  export let monitor_worker;
  export let login_db;
  export let geos_worker;
  export let travel_worker;
  export let trips_worker;
  export let routes_worker;
  let usuario, password;
  usuario = "";
  password = "";
  async function set_session() {
    var id = await login_db.getItem("key");
    if (!id) {
      id = new Date().valueOf().toString();
      await login_db.setItem("key", id);
    }
    unit_worker.postMessage({
      type: "emit",
      key: "hello",
      message: usuario + ":" + sha1(password),
    });
    geos_worker.postMessage({
      type: "emit",
      key: "hello",
      message: usuario + ":" + sha1(password),
    });
    travel_worker.postMessage({
      type: "emit",
      key: "hello",
      message: usuario + ":" + sha1(password),
    });
    monitor_worker.postMessage({
      type: "emit",
      key: "hello",
      message: usuario + ":" + sha1(password),
    });
    trips_worker.postMessage({
      type: "emit",
      key: "hello",
      message: usuario + ":" + sha1(password),
    });
    routes_worker.postMessage({
      type: "emit",
      key: "hello",
      message: usuario + ":" + sha1(password),
    });
    socket_query.emit(
      "hello",
      usuario + ":" + sha1(password),
      id,
      $portal_version
    );
    login_db.setItem("login_", usuario + ":" + sha1(password));
    //grecaptcha.execute('6LfUU90UAAAAAM5IMp-BkQGzDuXs0WKadczH4wkG', {action: 'login'}).then(function(token) {console.log(token);socket.emit('token',token+":"+usuario+":"+sha1(password));});
    usuario = "";
    password = "";
  }
  onMount(() => {
    window.document.body.classList.remove("central2");
    const text_usuario = mdc.textField.MDCTextField.attachTo(
      document.querySelector("#usuario")
    );
    const text_password = mdc.textField.MDCTextField.attachTo(
      document.querySelector("#password")
    );
  });
</script>

<main role="main" class="mdc-typography" style="width:100%;" id="main">
  <img
    src="img/logo2.png"
    alt="logo"
    height="50"
    style="padding-bottom:10px;padding-top:10px;box-sizing:content-box;vertical-align:baseline"
  />
  <img
    src="img/logo.png"
    alt="logo"
    height="50"
    style="padding-bottom:10px;padding-top:10px;box-sizing:content-box;vertical-align:baseline"
  />
  <form on:submit|preventDefault={set_session}>
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell">
          <label
            class="mdc-text-field mdc-text-field--outlined mdc-text-field--label-floating"
            style="width:300px"
            id="usuario"
          >
            <input
              type="text"
              class="mdc-text-field__input "
              aria-labelledby="usuario"
              required
              bind:value={usuario}
            />
            <span
              class="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched"
            >
              <span class="mdc-notched-outline__leading" />
              <span class="mdc-notched-outline__notch" style="width: 56px;">
                <span
                  class="mdc-floating-label mdc-floating-label--required mdc-floating-label--float-above"
                  >Usuario</span
                >
              </span>
              <span class="mdc-notched-outline__trailing" />
            </span>
          </label>
        </div>
      </div>
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell">
          <label
            class="mdc-text-field mdc-text-field--outlined mdc-text-field--label-floating"
            style="width:300px"
            id="password"
          >
            <input
              type="password"
              class="mdc-text-field__input"
              aria-labelledby="usuario"
              required
              bind:value={password}
              autocomplete="on"
            />
            <span
              class="mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched"
            >
              <span class="mdc-notched-outline__leading" />
              <span class="mdc-notched-outline__notch" style="width: 76.25px;">
                <span
                  class="mdc-floating-label mdc-floating-label--required mdc-floating-label--float-above"
                  >Contraseña</span
                >
              </span>
              <span class="mdc-notched-outline__trailing" />
            </span>
          </label>
        </div>
      </div>
      <div
        class="mdc-layout-grid__inner"
        style="justify-content:flex-end;display:flex;width:300px;margin:auto"
      >
        <button class="mdc-button mdc-button--raised" type="submit">
          <div class="mdc-button__ripple" />
          <i class="material-icons mdc-button__icon" aria-hidden="true"
            >bookmark</i
          >
          <span class="mdc-button__label">Ingresar</span>
        </button>
      </div>
    </div>
  </form>
</main>

<style>
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
  .material-icons.md-1 {
    font-size: 1rem;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-85 {
    font-size: 1.75rem;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  i {
    vertical-align: middle;
    padding-bottom: 3px;
  }
</style>
